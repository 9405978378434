import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, InputGroup, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Dialog from '../../services/Dialogs'



const ModalMigrarEtiqueta = ( params : any ) => {

  const { show, handleClose, etiqueta } = params

  const dialog = new Dialog();

  const [ uuid_nuevo, setUUID ] = useState<string>('')



  const [ existe_etiqueta, setExisteEtiqueta ] = useState<boolean>(false)
  const [ etiqueta_migrar, setEtiquetaMigrar ] = useState<any>(null)

  const buscarEtiqueta = async()=>{

    try{
      let res = await RequestService.getEndPoint('etiqueta/existencia/'+uuid_nuevo)
      if(res.data){
        setEtiquetaMigrar(res.data)
      } 
      else toast.warning('No podemos encontrar la etiqueta de ese UUID. Verifica el UUID.')
    }
    catch(e){
      toast.warning('No podemos encontrar la etiqueta de ese UUID. Verifica el UUID.')
    }

  }

  
  const cambiarEtiqueta = async(e)=>{

    try{

        dialog.open({
          accept: 'Cambiar',
          message: '¿Estas seguro?',
          target: e.target,
          template: `<p>Este proceso cambiará todas las noticias y no podrá restaurarse</p>`
        })
        let dialogoR = await dialog.waitForUser()

        if(dialogoR){

            let res = await RequestService.update(`etiquetas/migrar_etiqueta/${etiqueta.uuid}/${etiqueta_migrar.uuid}`,{ })
            if(res.data){
              toast.success('Todas las noticias fueron cambiadas de etiqueta')
              handleClose()
            } 
            else toast.warning('No podemos encontrar la etiqueta de ese UUID. Verifica el UUID.*')

        }


    }
    catch(e:any){
     // console.log(JSON.stringify(e))
      toast.warning(e.response.data)
    }

  }



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Migrar Noticias de la Etiqueta</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <p className="nm"><b><small>Etiqueta a cambiar:</small></b></p>

            <h3>{etiqueta.etiqueta}</h3>

             <h6>Total de <b>{etiqueta.total_noticias} Noticia(s)</b></h6>

            <hr/>




              {
                etiqueta_migrar && (
                  <React.Fragment>

                      <p className="nm"><b><small>Todas la noticias se cambiarán por:</small></b></p>

                      <h3>{etiqueta_migrar.etiqueta}</h3>



                      <button type="button" onClick={cambiarEtiqueta} className="btn btn-primary">Cambiar Etiqueta</button>

                  </React.Fragment>
                )
              }

              {
                etiqueta_migrar === null && (

                    <div className="mt-2">
                        <label className="form-label d-block">UUID de la Etiqueta a la que se migrará</label>
                      <InputGroup className="mb-3">
                        <Form.Control onChange={(e:any)=>{setUUID(e.target.value)}} value={uuid_nuevo}
                          placeholder="UUID de la etiqueta"
                        />
                        <Button variant="outline-primary" id="button-addon2" onClick={(e)=>{buscarEtiqueta()}}>
                          <i className="fa fa-search" />
                        </Button>
                      </InputGroup>
                    </div>

                )
              }



        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalMigrarEtiqueta;