import React, { useState } from "react";
import RequestService from "../../services/request.service";

import moment from 'moment'
// import 'moment/locale/es'

import './Etiquetas.scss';

import { Modal, Button, Form } from 'react-bootstrap'

import { toast } from 'react-toastify'



import Pagination from "../../services/Pagination";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'


import { SERVER_ } from  '../../config';



import EditarReplica from '../Noticias/NuevaNoticia/editar_replica_noticia'

import ModalMigrarEtiqueta from './ModalMigrarEtiqueta'



const addDays = function (dateObjet: any, days:number) {
    const date = dateObjet;
    date.setDate(date.getDate() + days);
    return date;
};



const ModalNoticiasTema = (props) =>{

  const { etiqueta_modal, modalNoticiasTema, handleClose } = props


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ cargando_, setCargando_] = useState(false)

  const [ text_busqueda, setTextoBusqueda ] = useState('sefin')

  const [ noticias, setNoticias ] = useState<any>([])

  const [ pagina, setPagina ] = useState(0)
  const [ total_resultados, setTotalResulados ] = useState(0)

  const busquedaNoticias = async () =>{
    setPagina(0)
    cargarNoticias(0)
    setCurrentPage(1)
  }

  const cargarNoticias = async (page:number) =>{
      setCargando_(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let resultados = await RequestService.getEndPoint('etiquetas/noticias_relacionadas/'+etiqueta_modal.uuid+'?where='+text_busqueda+'&skip='+skip)
      // console.log(resultados.data)
      setNoticias(resultados.data.results)
      setTotalResulados(resultados.data.totalCount)
      setCounter(resultados.data.totalCount)
      setCargando_(false)
  }



  const [ noticia, setNoticiaSeleccion ] = useState<any>({ tags : [] })

  const [showEdit, setShowEdit] = useState(false);

  const seleccionaNoticiaModalEditar = (noticia:any)=>{
    var noti = {...noticia}
    noti.fecha_replica = moment(noti.fecha_replica).toDate();
    setNoticiaSeleccion(noti)
    setShowEdit(true)
  }


  const CerrarModalEdicion = async ()=>{
    // setCargandoBusquedaNoticia(true)
    cargarNoticias(currentPage-1)
    // // let response = await RequestService.getEndPoint('reportes/analistas/' + cargarNoticias( fecha ) )
    // await busquedaNoticias(0)
    // setCargandoBusquedaNoticia(false)
    // setResultados(response.data)
    setShowEdit(false)
  }


  return (

<>

      <Modal show={modalNoticiasTema} backdrop={'static'} size={'lg'} onShow={busquedaNoticias} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {etiqueta_modal.etiqueta}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          


                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

                          { !cargando_ && (

                            <>


                                  { total_resultados === 0 && (

                                    <>

                                      <div className="alert alert-secondary">
                                        Esta Etiqueta no tiene asociada ninguna Noticia
                                      </div>

                                    </>

                                    )
                                  }



                                  { total_resultados > 0 && (

                                    <>

                                        <table className="table table-sm">
                                          <thead>
                                            <tr>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>


                                            {
                                              noticias.map( (noticia, i) =>


                                                      <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                        

                                                        <td>
                                                          <p className="nm">{
                                                            // JSON.stringify(noticia)
                                                          }</p>
                                                        </td>
                                                        <td>
                                                          <p className="nm"><b>{noticia.titulo}</b></p>
                                                          <small>{noticia.detalle}</small>
                                                          <p onClick={()=>{seleccionaNoticiaModalEditar(noticia)}} className="nm pointer fw-lighter"><small><i className="fa fa-edit" /> Editar Noticia</small></p>
                                                        </td>
                                                      </tr>

                                              )
                                            }




                                          </tbody>
                                        </table>




                                  <Pagination
                                    itemsCount={allSessionsCount}
                                    itemsPerPage={sessionsPerPage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    cambiarPaginaEvento={cargarNoticias}
                                    alwaysShown={false}
                                  />


                                  </>

                                  )
                                }

                                </>
                           )
                        }







        </Modal.Body>
      </Modal>


                <Modal show={showEdit} backdrop={'static'} onHide={CerrarModalEdicion}>
                  <Modal.Header closeButton>
                    <Modal.Title>NOTICIA EN EDICIÓN</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                          <EditarReplica noticia={noticia} />

                  </Modal.Body>
                </Modal>


</>

  )
}


const EdicionEtiquetas = () =>{


  const dialog = new Dialog();

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ cargando_noticia, setCargandoNoticias] = useState(false)

  // const [startDate, setDate ] = useState(new Date())

  const skullEtiqueta = {
      uuid: "",
      etiqueta: "",
      idtendencia: 0,
      tendencia: "",
      idseccion: 0,
      seccion: ""
  }

  // const skullSeccion : Seccion = {
  //   seccion : "",
  //   etiquetas_usadas : [{...skullEtiqueta}]
  // }

  const [ etiquetas, setEtiquetas ] = useState<[any]>([{...skullEtiqueta}])
  const [ total_resultados, setTotalResulados ] = useState(0)
  // const [ secciones, setSecciones ] = useState<[Seccion]>([{...skullSeccion}])






  React.useEffect(() => {
    // cargarEtiquetas( moment(startDate).format('YYYY-MM-DD') )
    cargarEtiquetas(0)
  }, []);



  const [ text_busqueda, setTextoBusqueda ] = useState('')


  const [ pagina, setPagina ] = useState(0)

  const busquedaEtiquetas = async () =>{
    setPagina(0)
    cargarEtiquetas(0)
    setCurrentPage(1)
  }

  const cargarEtiquetas = async (page:number) =>{
      setCargandoNoticias(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let resultados = await RequestService.getEndPoint('busqueda_etiquetas?where='+text_busqueda+'&skip='+skip)
      // console.log(resultados.data)
      setEtiquetas(resultados.data.results)
      setTotalResulados(resultados.data.totalCount)
      setCounter(resultados.data.totalCount)
      // setPaginas(resultados.data.numberPages)
      setCargandoNoticias(false)
  }




  const [ etiqueta_edicion, setEtiquetaEdicion ] = useState<any>({...skullEtiqueta})

  const [show, setShow] = useState(false);

  const handleClose = async () =>{
    // cambiarDatosEtiqueta()
    setShow(false);
    await cargarEtiquetas(pagina)
  }
  const handleShow = (etiqueta) =>{
    setEtiquetaEdicion({...etiqueta})
    setShow(true);
  }

    const actualizarCampoEtiqueta = (e:any) => setEtiquetaEdicion({ ...etiqueta_edicion, etiqueta: e.target.value })
    const actualizarCampoTendencia = (value:number) => setEtiquetaEdicion({ ...etiqueta_edicion, idtendencia: value })
    const actualizarCampoSeccion = (value:number) => setEtiquetaEdicion({ ...etiqueta_edicion, idseccion: value })



    const cambiarDatosEtiqueta = async () =>{
      // console.log(etiqueta_edicion)
      try{
        var Enviar = {...etiqueta_edicion}
        delete Enviar.uuid
        await RequestService.update('actualizar-etiqueta/'+etiqueta_edicion.uuid,{ data : Enviar })
        toast.success('OK')
        
        // setShow(false);
      }
      catch(e){
        toast.error('Un error al guardar los cambios de la etiqueta')
      }
    }





  const eliminarEtiquetaPermanente = async (uuid_etiqueta:string) =>{
    try{
      if(await dialog.confirm('¿Estás seguro que quieres eliminar permanentemente?',{}) ){
          let resultados = await RequestService.delete('etiquetas/eliminar/'+uuid_etiqueta)
          cargarEtiquetas(currentPage===0?0:currentPage-1)
          toast.success('La etiqueta se elimino')
      }
    }
    catch(e){
      toast.error('Error al intentar eliminar la etiqueta.')
    }
  }

  const [ modalNoticiasTema, setModalNoticiasTema ] = useState<boolean>(false)
  const [ etiqueta_modal, setEtiquetaModal ] = useState<boolean>(false)
  const mostrarNoticiasDelTema = (etiqueta:any) =>{
      setEtiquetaModal(etiqueta)
      setModalNoticiasTema(true)
  }


  const [ tendencia, setTendencia ] = useState<number>(0)




  const [ show_ModalMigrarEtiqueta, setModalMigrarEtiqueta ] = useState<boolean>(false)
  const handleShow_ModalMigrarEtiqueta = (etiqueta:any) => {
    setEtiquetaModal(etiqueta)
    setModalMigrarEtiqueta(true)
  }
  const handleClose_ModalMigrarEtiqueta = () => {
    cargarEtiquetas(currentPage===0?0:currentPage-1)
    setModalMigrarEtiqueta(false)
  }

      return (
          <>

              <div className="container">
                  
                  <h2>Etiquetas</h2>

                  <div className="row">

                    <div className="col-md">


                              <div className="">
                                <label className="form-label">Busqueda de Etiquetas</label>
                                <div className="input-group mb-3">
                                  <input type="text" className="form-control" placeholder="" value={text_busqueda} onKeyPress={(e)=>{if(e.charCode == 13) busquedaEtiquetas() }} onChange={event => setTextoBusqueda(event.target.value)} />
                                  <button onClick={busquedaEtiquetas} className="btn btn-primary" type="button" id="button-addon2"><i className="fa fa-search" /></button>
                                </div>
                              </div>




                          {  cargando_noticia && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

                          { !cargando_noticia && (

                            <>





                                  { total_resultados === 0 && (

                                    <>

                                        Trata de buscar algo...


                                    </>

                                    )
                                  }




                                  { total_resultados > 0 && (

                                    <>

                                        <table className="table table-sm">
                                          <thead>
                                            <tr>
                                              <th scope="col">Etiqueta</th>
                                              <th scope="col">Tendencia</th>
                                              <th scope="col">Sección</th>
                                              <th scope="col">Noticias</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>


                                            {
                                              etiquetas.map( (etiqueta, i) =>


                                                      <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                        

                                                        <td>
                                                          <p className="nm">{etiqueta.etiqueta}</p>
                                                          <small>{etiqueta.uuid}</small>
                                                        </td>
                                                        <td className="v" style={{"width":"200px"}}>
                                                           { !etiqueta.idtendencia && ( <p className="nm text-center"><i className="fa fa-warning" /> </p> )  }
                                                           { /* etiqueta.idtendencia && ( <p className="nm text-center"><small className={'btn btn-primary h-'+etiqueta.idtendencia+' px-2'}>{etiqueta.tendencia}</small></p> )  */}
                                                           { etiqueta.idtendencia && ( <p className="nm text-center"><small>{etiqueta.tendencia}</small></p> )  }

                                                        </td>
                                                        <td className="v" style={{"width":"200px"}}>
                                                           { !etiqueta.idseccion && ( <p className="nm text-center"><i className="fa fa-warning" /> </p> )  }
                                                           { etiqueta.idseccion && ( <p className="nm text-center"><small >{etiqueta.seccion}</small></p> )  }
                                                        </td>
                                                        <td className="v">
                                                          {
                                                            etiqueta.total_noticias
                                                          }
                                                        </td>
                                                        <td className="v" style={{"width":"40px"}}>
                                                          <button type="button" onClick={()=>{mostrarNoticiasDelTema(etiqueta)}} className="btn btn-sm btn-info"><i className="fa fa-list" /></button>
                                                        </td>
                                                        <td className="v" style={{"width":"40px"}}>
                                                          <button type="button" onClick={()=>{handleShow_ModalMigrarEtiqueta(etiqueta)}} className="btn btn-sm btn-secondary"><i className="fa fa-exchange" /></button>
                                                        </td>
                                                        <td className="v" style={{"width":"40px"}}>
                                                          <button type="button" onClick={()=>{handleShow(etiqueta)}} className="btn btn-sm btn-warning"><i className="fa fa-edit" /></button>
                                                        </td>
                                                        <td className="v" style={{"width":"40px"}}>
                                                          <button type="button" onClick={()=>{eliminarEtiquetaPermanente(etiqueta.uuid)}} className="btn btn-sm btn-danger"><i className="fa fa-trash" /></button>
                                                        </td>
                                                      </tr>

                                              )
                                            }




                                          </tbody>
                                        </table>




        <Pagination
          itemsCount={allSessionsCount}
          itemsPerPage={sessionsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          cambiarPaginaEvento={cargarEtiquetas}
          alwaysShown={false}
        />






                                    </>

                                    )
                                  }





      <Modal show={show} onHide={handleClose} backdrop="static" >
        <Modal.Header closeButton>
          <Modal.Title>{etiqueta_edicion.uuid}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                          
                <div className="mb-3">
                  <label htmlFor="etiqueta" className="form-label">Etiqueta</label>
                  <input type="email" className="form-control" id="etiqueta" value={etiqueta_edicion.etiqueta} onChange={actualizarCampoEtiqueta} />
                </div>

                <div className="mb-3">
                    <label className="form-label d-block">Tendencia de la Etiqueta</label>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" id="positiva" type="radio" name="tendenciaDeLaEtiqueta" checked={etiqueta_edicion.idtendencia===1} onChange={()=>{ actualizarCampoTendencia(1) }} />
                        <label className="form-check-label" htmlFor="positiva">POSITIVA</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" id="negativa" type="radio" name="tendenciaDeLaEtiqueta" checked={etiqueta_edicion.idtendencia===2} onChange={()=>{ actualizarCampoTendencia(2) }} />
                        <label className="form-check-label" htmlFor="negativa">NEGATIVA</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" id="neutral" type="radio" name="tendenciaDeLaEtiqueta" checked={etiqueta_edicion.idtendencia===3} onChange={()=>{ actualizarCampoTendencia(3) }} />
                        <label className="form-check-label" htmlFor="neutral">NEUTRAL</label>
                    </div>
                </div>

                <div className="mb-3">
                    <label className="form-label d-block">Sección o Cátegoria</label>
                    <div className="form-check">
                        <input className="form-check-input" id="secretarias" type="radio" name="seccionOCategoria" checked={etiqueta_edicion.idseccion===1} onChange={()=>{ actualizarCampoSeccion(1) }} />
                        <label className="form-check-label" htmlFor="secretarias">SECRETARIAS</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" id="secretarias_f" type="radio" name="seccionOCategoria" checked={etiqueta_edicion.idseccion===7} onChange={()=>{ actualizarCampoSeccion(7) }} />
                        <label className="form-check-label" htmlFor="secretarias_f">SECRETARIAS FEDERALES</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" id="diputados" type="radio" name="seccionOCategoria" checked={etiqueta_edicion.idseccion===2} onChange={()=>{ actualizarCampoSeccion(2) }} />
                        <label className="form-check-label" htmlFor="diputados">DIPUTADOS</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" id="diputados_f" type="radio" name="seccionOCategoria" checked={etiqueta_edicion.idseccion===8} onChange={()=>{ actualizarCampoSeccion(8) }} />
                        <label className="form-check-label" htmlFor="diputados_f">DIPUTADOS FEDERALES</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" id="generales" type="radio" name="seccionOCategoria" checked={etiqueta_edicion.idseccion===3} onChange={()=>{ actualizarCampoSeccion(3) }} />
                        <label className="form-check-label" htmlFor="generales">GENERALES</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" id="municipios" type="radio" name="seccionOCategoria" checked={etiqueta_edicion.idseccion===4} onChange={()=>{ actualizarCampoSeccion(4) }} />
                        <label className="form-check-label" htmlFor="municipios">MUNICIPIOS</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" id="partidosPoliticos" type="radio" name="seccionOCategoria" checked={etiqueta_edicion.idseccion===5} onChange={()=>{ actualizarCampoSeccion(5) }} />
                        <label className="form-check-label" htmlFor="partidosPoliticos">PARTIDOS POLÍTICOS</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" id="tematicas" type="radio" name="seccionOCategoria" checked={etiqueta_edicion.idseccion===6} onChange={()=>{ actualizarCampoSeccion(6) }} />
                        <label className="form-check-label" htmlFor="tematicas">TEMÁTICAS</label>
                    </div>
                </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="success" onClick={cambiarDatosEtiqueta}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>



                            </>

                            )
                          }

                    </div>

                  </div>

              </div>









            <ModalNoticiasTema modalNoticiasTema={modalNoticiasTema} etiqueta_modal={etiqueta_modal} handleClose={()=>{setModalNoticiasTema(false)}} />




              {
                show_ModalMigrarEtiqueta && (
                  <ModalMigrarEtiqueta show={show_ModalMigrarEtiqueta} handleClose={handleClose_ModalMigrarEtiqueta} etiqueta={etiqueta_modal}  />
                  )

              }


          </>

      );
  

}

export default EdicionEtiquetas
