// import React, { useState } from "react";
// import RequestService from "../../services/request.service";

// import moment from 'moment'
// import 'moment/locale/es'
// // import { SERVER_ } from  '../../config';
// // import { VictoryPie, VictoryLabel, VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryStack, VictoryGroup } from "victory";

// import { Chart as ChartJS, ArcElement, CategoryScale, registerables } from 'chart.js';
// import { Pie, Bar } from 'react-chartjs-2';

// // ChartJS.register(ArcElement, Tooltip, Legend);

// import './Analiticas.scss';



// import DatePicker, { registerLocale } from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import es from 'date-fns/locale/es';


// import 'https://fonts.googleapis.com/css?family=Press+Start+2P'
// import "../../../../node_modules/nes.css/css/nes.css"
import "./Reporte.scss";

const Reporte = () =>{




      return (
          <>
            reporte

          </>

      );
  

}

export default Reporte