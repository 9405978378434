import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'


import { Link } from 'react-router-dom'

import './Replicas.scss';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import { Modal, Tabs, Tab } from 'react-bootstrap'


import moment from 'moment'

import { SERVER_ } from  '../../config';


import EditarReplica from './NuevaNoticia/editar_replica_noticia'

import ModalEditarReaccionesRedes from './ModalEditarReaccionesRedes'

// import MisReplicas from './MisReplicas';


import Button from 'react-bootstrap/Button'


import { confirm } from '../../widgets/confirm'

// const DetalleNoticia = ( objetos : any) => {
//   // console.log(objetos.detalles)
//   var st = objetos.detalles
//   for (var i = 0; i < objetos.etiquetas.length; ++i) {
//     st = st.replace("cambia_esto_"+i,'<a rel="noreferrer" target="_blank" href="">'+objetos.etiquetas[i].value+'</a>')
//   }
//   return (
//       <>
//       <small dangerouslySetInnerHTML={{ __html: st }} />
//       </>
//     )
// }

import AuthService from '../../features/auth/auth.service'


import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

const MyUploader = ( props ) => {
  
  const getUploadParams = ({ meta }) => { 

    return { url: SERVER_ + 'subiendo_multimedia/'+props.noticia.uuid_noticia, headers : { 'Authorization' : 'Bearer '+AuthService.getToken() } } 

  }
  
  const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }
  
  const handleSubmit = (files, allFiles) => {
    // console.log(files.map(f => f.meta))
    allFiles.forEach(f => f.remove())
  }

  const cargarAnexos = async () =>{
    var news = await RequestService.getEndPoint('noticia/multimedia/'+props.noticia.uuid_noticia)
    setAnexos(news.data)
  }

  const [ anexos, setAnexos ] = useState([])

  const handleSelect = async (key) => {
    // console.log('key--->',key, props)
    if (parseInt(key) === 2){
        cargarAnexos()
      }
    else{
      // noticia/multimedia/:uuid
    }
}

  const eliminarAnexo = async (uuid) =>{
    try{

        if (await confirm({
          confirmation: 'Estas seguro? Este proceso no se puede recuperar'
        })) {
          await RequestService.delete('eliminar-multimedia/'+uuid)
          cargarAnexos()
        }

    }
    catch(e){
      toast.error('Problemas en el cielo')
    }
  }

  return (

    <>

<div className="d-flex">
  <div className="flex-shrink-0">
    <img src={ SERVER_ + 'medio/logo/square/'+props.noticia.uuid_medio+'/'+props.noticia.uuid_medio} alt="props.noticia.uuid_medio" width="100px" />
  </div>
  <div className="flex-grow-1 ms-3">
    <h5>{props.noticia.titulo}</h5>
    <p>{props.noticia.detalle}</p>
  </div>
</div>

<br/>


<Tabs defaultActiveKey={1} id="uncontrolled-tab-example" className="mb-3" onSelect={handleSelect}>
  <Tab eventKey={1} title="Carga de Anexos">

    <Dropzone
    inputContent="Arrastra tus archivos o da click para buscar"
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      accept="image/*,audio/*,video/*"
    />

  </Tab>
  <Tab eventKey={2} title="Anexos Digitales">
        {
          (anexos.length === 0) && ( 
            <>
              <div className="alert alert-dismissible alert-warning">
                <h4 className="alert-heading">Ups!</h4>
                <p className="mb-0">No cuenta con anexos digitales</p>
              </div>
            </> 
            )
        }
        {
          (anexos.length > 0) && 
            ( <>

                  <div className="row row-cols-2">

                {anexos.map(
                  (anexo : any) => 

                    <div key={ Math.random().toString(36).substr(2, 9) } className="col-sm-6">

                      <div className="card" style={{'marginTop': '0'}}>

                                  { (["jpg","jpeg","png"].indexOf(anexo.tipo_archivo) >= 0) && ( 
                                    <>
                                      <img className="card-img-top" src={ SERVER_ + 'hemeroteca/'+anexo.uuid} alt={anexo.uuid} width="100%"  />
                                    </>
                                    )}


                                  { anexo.tipo_archivo === "mp4" && ( 
                                    <>
                                          <video controls style={{"width": "100%"}}>
                                              <source type={'video/'+anexo.tipo_archivo} src={ SERVER_ + 'hemeroteca/'+anexo.uuid} />
                                          </video>
                                    </>
                                    )}


                                  { (anexo.tipo_archivo === "mp3" || anexo.tipo_archivo === "ogg") && ( 
                                    <>
                                          <audio controls style={{"width": "100%"}}>
                                              <source type={'audio/'+anexo.tipo_archivo} src={ SERVER_ + 'hemeroteca/'+anexo.uuid} />
                                          </audio>
                                    </>
                                    )}


                        <div className="card-body ">
                          <button onClick={()=>{eliminarAnexo(anexo.uuid)}} className="btn btn-primary">
                            <i className="fa fa-trash"/>
                          </button>
                        </div>
                      </div>

                    </div>

                    )
                }
                  </div>


            </> )
        }
  </Tab>
</Tabs>




    </>
  )
}

const TablonaChaval = ( props : any ) => {

  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // const [ hash, setHash ] = useState( [ ...Array(4) ].map(() => (~~(Math.random() * 36)).toString(36)).join('') )


  const skullNoticia = {
  "uuid_noticia": "",
  "uuid_medio": "",
  "fecha_replica": "",
  "titulo": "",
  "detalle": "",
  "fecha_creacion": "",
  "idtipo_noticia": 0,
  "idmedio_red_social" : 0,
  // {
    "tipo_noticia": "",
  // },
  // "idmedio": {
  //   "uuid": "",
  //   "nombre": "",
  //   "detalles": "",
  //   "alcance": "",
  //   "importancia": 0,
  //   "credibilidad": 0,
  //   "idtipo_medio": 0,
  //   "idred_social": 1
  // },
  "idtendencia": 0,
  // {
  //   "id" : 0,
    "tendencia": "",
    "nombre_medio": "",
  // },

  "reacciones": {
    "url": "",
    "facebook_me_gusta": 0,
    "facebook_me_encanta": 0,
    "facebook_me_importa": 0,
    "facebook_me_divierte": 0,
    "facebook_me_sorprende": 0,
    "facebook_me_entristece": 0,
    "facebook_me_enoja": 0,
    "facebook_comentarios": 0,
    "facebook_compartidos": 0,
    "twitter_respuestas": 0,
    "twitter_me_gusta": 0,
    "twitter_retweet": 0,
    "youtube_comentarios": 0,
    "youtube_reproducciones": 0,
    "youtube_me_gusta": 0,
    "instagram_me_gusta": 0,
    "instagram_comentarios": 0,
    "idreplica": 0
  },
  "red_social": "",
  "tags": [],
  "multimedia": []
}

  const [ noticia, setNoticiaSeleccion ] = useState({...skullNoticia})

  const seleccionaNoticiaModal = (noticia:any)=>{
    let noti = {...noticia}
    noti.reacciones= {
    "url": "",
    "facebook_me_gusta": 0,
    "facebook_me_encanta": 0,
    "facebook_me_importa": 0,
    "facebook_me_divierte": 0,
    "facebook_me_sorprende": 0,
    "facebook_me_entristece": 0,
    "facebook_me_enoja": 0,
    "facebook_comentarios": 0,
    "facebook_compartidos": 0,
    "twitter_respuestas": 0,
    "twitter_me_gusta": 0,
    "twitter_retweet": 0,
    "youtube_comentarios": 0,
    "youtube_reproducciones": 0,
    "youtube_me_gusta": 0,
    "instagram_me_gusta": 0,
    "instagram_comentarios": 0,
    "idreplica": 0
  }
    setNoticiaSeleccion(noticia)
    setShow(true)
    console.log(noticia)
  }

  const CerrarModal = ()=>{
    setNoticiaSeleccion({...skullNoticia})
    setShow(false)
  }





  const seleccionaNoticiaModalEditar = (noticia:any)=>{
    var noti = {...noticia}
    delete noti.idnoticia
    noti.fecha_replica = moment(noti.fecha_replica).toDate();
    // noti.idred_social = noti.idred_social.id
    // if(noti.idred_social) noti.idred_social =noti.idred_social
    if(!noti.idmunicipio) noti.idmunicipio = 0;

    noti.idmedio_uuid = noti.uuid_medio
    noti.medio = noti.nombre_medio
    noti.idmedio = parseInt(noti.idmedio)
    noti.idtendencia = parseInt(noti.idtendencia)
    noti.idtipo_noticia = parseInt(noti.idtipo_noticia)
    noti.idtipo_medio = parseInt(noti.idtipo_medio)

    
    setNoticiaSeleccion(noti)
    setShowEdit(true)
    // console.log(noticia)
  }

  const CerrarModalEdicion = ()=>{
    setNoticiaSeleccion({...skullNoticia})
    props.cargarNoticiasMetodo()
    setShowEdit(false)
  }


  const eliminarNota = async ()=>{
    try{
        if (await confirm({
          confirmation: 'Estas seguro? Perderas toda la información de la Noticia'
        })) {
          // try{
              await RequestService.delete('eliminar-noticia/'+noticia.uuid_noticia)
              // cargarRegistros()
              // console.log(cargarNoticiasMetodo)
              props.cargarNoticiasMetodo()
              CerrarModalEdicion()
          // }
          // catch(e:any){
          //   var E = JSON.parse(JSON.stringify(e))
          //   console.log(e.response)
          //   if(E.status===400) toast.error(e.response.data)
          //   else toast.error('Error al intentar eliminar la noticia '+JSON.stringify(e))
          //   // setEnviandoNoticia(false)
          // }
        }
      }
      catch(E){
        toast.error('No podemos eliminar esta noticia.')
      }
  }

  const [ modal_multimedia, setModalMultimedia ] = useState(false)

  const abrirModalMultimedia = (noticia:any) => {
    setNoticiaSeleccion(noticia)
    setModalMultimedia(true)
  }




  const [ noticia_seleccionada, setNoticiaSeleccionada ] = useState<any>(null)
  const [ show_ModalEditarReaccionesRedes, setModalEditarReaccionesRedes ] = useState<boolean>(false)
  const handleShow_ModalEditarReaccionesRedes = (noticia:any) => {
    setNoticiaSeleccionada(noticia)
    setModalEditarReaccionesRedes(true)
  }
  const handleClose_ModalEditarReaccionesRedes = () => {
    props.cargarNoticiasMetodo()
    setModalEditarReaccionesRedes(false)
  }





  return (
            <>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Título</th>
                    <th scope="col">Detalles</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>

                {props.registros.map(
                  (registro : any, registro_key:number) => 

                        <tr key={ registro_key }>
                          <td className="text-center v" style={{'width':'20px'}}>
                            <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={ SERVER_ + 'medio/logo/square/'+registro.uuid_medio+'/'+registro.uuid_medio} alt={registro.nombre_medio} width="40px" />
                          </td>
                          <td  >
                            <p className="nm sammy-nowrap-1"><b><small>{registro.titulo}</small></b></p>
                            <button type="button" onClick={()=>{seleccionaNoticiaModalEditar(registro)}} className="btn btn-link"><i className="fa fa-edit" /></button>

                                      <a onClick={()=>{abrirModalMultimedia(registro)}}>
                                        <img src={ registro.multimedia.length > 0 ? '../assets/images/multimedia.png' : '../assets/images/sin_multimedia.png' } alt="Multimedia" width="10%" />
                                      </a>

                          </td>
                          <td >

                          {
                            // JSON.stringify(registro)
                          }

                          
                            <p className="nm sammy-nowrap-2" onClick={()=>{seleccionaNoticiaModal(registro)}} style={{'cursor':'pointer'}}>
                                {
                                  registro.idmunicipio && (
                                      <span><small>{registro.municipio}.- </small></span>
                                    )
                                }
                                {
                                  <small>{registro.detalle}</small> 


                                 //<DetalleNoticia detalles={registro.detalle_replica[0]} etiquetas={registro.detalle_replica[1]}  />
                                }
                            </p>

                            <ul className="list-inline nm">
                              { registro.tags.map( (tag) =>
                                    <li key={ Math.random().toString(36).substr(2, 9) } className="list-inline-item"><small><a rel="noreferrer" href="/#">{tag.idetiqueta.etiqueta}</a></small></li>
                               )}
                            </ul>

                            <p className="nm"><small>{registro.fecha_replica}</small> {registro.fake_new?<span className="badge bg-danger"><small>FAKE NEWS</small></span>:''} </p>


                            {
                              registro.idred_social && (
                              <>
                                {registro.idred_social === 1 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-facebook" rel="noreferrer" target="_blank" href={registro.reacciones.url} ><i className="fa fa-facebook"></i><span> 
                                      {registro.reacciones.facebook_me_gusta > 0 && ( <> 👍 {registro.reacciones.facebook_me_gusta} </> )}
                                      {registro.reacciones.facebook_me_encanta > 0 && ( <> ❤️ {registro.reacciones.facebook_me_encanta} </> )}
                                      {registro.reacciones.facebook_me_importa > 0 && ( <> 💕 {registro.reacciones.facebook_me_importa} </> )}
                                      {registro.reacciones.facebook_me_divierte > 0 && ( <> 😂 {registro.reacciones.facebook_me_divierte} </> )}
                                      {registro.reacciones.facebook_me_sorprende > 0 && ( <> 😮 {registro.reacciones.facebook_me_sorprende} </> )}
                                      {registro.reacciones.facebook_me_entristece > 0 && ( <> 😢 {registro.reacciones.facebook_me_entristece} </> )}
                                      {registro.reacciones.facebook_me_enoja > 0 && ( <> 😡 {registro.reacciones.facebook_me_enoja} </> )}
                                      {registro.reacciones.facebook_comentarios > 0 && ( <> 🗨️ {registro.reacciones.facebook_comentarios} </> )}
                                      {registro.reacciones.facebook_compartidos > 0 && ( <> 🔃 {registro.reacciones.facebook_compartidos} </> )}

                                      {
                                        (registro.reproducciones !== null) && (<>{registro.reproducciones > 0 && ( <> 👁️ {registro.reproducciones} </> )}</>)
                                      }

                                      
                                    </span></a>

                                  </>
                                )}


                                {registro.idred_social === 4 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-instagram" rel="noreferrer" target="_blank" href={registro.reacciones.url} ><i className="fa fa-instagram"></i><span> 
                                      {registro.reacciones.instagram_me_gusta > 0 && ( <> ❤️ {registro.reacciones.instagram_me_gusta} </> )}
                                      {registro.reacciones.instagram_comentarios > 0 && ( <> 🗨️ {registro.reacciones.instagram_comentarios} </> )}
                                    </span></a>

                                  </>
                                )}


                                {registro.idred_social === 2 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-youtube" rel="noreferrer" target="_blank" href={registro.reacciones.url} ><i className="fa fa-youtube"></i><span> 
                                      {registro.reacciones.youtube_me_gusta > 0 && ( <> 👍 {registro.reacciones.youtube_me_gusta} </> )}
                                      {registro.reacciones.youtube_comentarios > 0 && ( <> 🗨️ {registro.reacciones.youtube_comentarios} </> )}
                                      {
                                        // registro.reacciones.youtube_reproducciones > 0 && ( <> 👁️ {registro.reacciones.youtube_reproducciones} </> )
                                      }
                                      {
                                        (registro.reproducciones !== null) && (<>{registro.reproducciones > 0 && ( <> 👁️ {registro.reproducciones} </> )}</>)
                                      }
                                    </span></a>

                                  </>
                                )}



                                {registro.idred_social === 10 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-twitter" rel="noreferrer" target="_blank" href={registro.reacciones.url} ><i className="fa fa-twitter"></i><span> 
                                      {registro.reacciones.twitter_me_gusta > 0 && ( <> ❤️ {registro.reacciones.twitter_me_gusta} </> )}
                                      {registro.reacciones.twitter_respuestas > 0 && ( <> 🗨️ {registro.reacciones.twitter_respuestas} </> )}
                                      {registro.reacciones.twitter_retweet > 0 && ( <> 🔃 {registro.reacciones.twitter_retweet} </> )}
                                      {
                                        (registro.reproducciones !== null) && (<>{registro.reproducciones > 0 && ( <> 👁️ {registro.reproducciones} </> )}</>)
                                      }
                                    </span></a>

                                  </>
                                )}


                                {registro.idred_social === 5 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-twitter" rel="noreferrer" target="_blank" href={registro.reacciones.url} ><i className="fa fa-tiktok"></i><span> 
                                      {
                                        (registro.reproducciones !== null) && (<>{registro.reproducciones > 0 && ( <> 👁️ {registro.reproducciones} </> )}</>)
                                      }
                                    </span></a>

                                  </>
                                )}



                                 {' '} <i className="fa fa-edit" onClick={(e:any)=>{handleShow_ModalEditarReaccionesRedes(registro)}} />


                              </>
                              )
                            }


                          </td>
                          <td className="vat" style={{'width':'250px'}}>


                                  <div className="small">
                                    <strong className="d-block text-gray-dark">{registro.nombre_medio}</strong>
                                    <p className="nm"><small>{registro.tipo_noticia}</small></p>
                                    {  registro.idtendencia === 1 && (<span className="tendencia positivas">{registro.tendencia}</span>)}
                                    {  registro.idtendencia === 2 && (<span className="tendencia negativas">{registro.tendencia}</span>)}
                                    {  registro.idtendencia === 3 && (<span className="tendencia neutras">{registro.tendencia}</span>)}
                                  </div>

                                  <p className="mt-2 small">ALCANCE {registro.alcance}</p>
                                


                          </td>
                        </tr>

                  )}

                </tbody>
              </table>





                <Modal show={modal_multimedia} onHide={()=>{ setModalMultimedia(false); props.cargarNoticiasMetodo(); }}>
                  <Modal.Header closeButton>
                    <Modal.Title>MULTIMEDIA DE LA NOTICIA</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                          <MyUploader noticia={noticia} />

                  </Modal.Body>
                </Modal>






                <Modal show={showEdit} onHide={CerrarModalEdicion}>
                  <Modal.Header closeButton>
                    <Modal.Title>NOTICIA EN EDICIÓN</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                          <EditarReplica noticia={noticia} />


                    <div className="mb-3 mt-2">
                        <Button variant="danger" style={{'width':'100%'}} onClick={()=>{eliminarNota()}} ><i className="fa fa-trash" /> </Button>
                    </div>

                  </Modal.Body>
                </Modal>





                <Modal show={show} fullscreen={true} onHide={CerrarModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>NOTICIA</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>



                  <div className="row">
                    <div className="col-sm-6">


                            <dl>
                              <dt>Titular</dt>
                              <dd>{noticia.titulo}</dd>
                              <dt>Síntesis</dt>
                              <dd>{noticia.detalle}</dd>
                              { noticia.idmedio_red_social && (
                                <>
                                  <dt>Red Social</dt>
                                  <dd>{noticia.red_social}</dd>
                                </>
                              )}
                            </dl>



                          { noticia.idmedio_red_social && (
                            <p>
                              {noticia.idmedio_red_social === 1 && (
                                <>
                                  <a className="btnr btnr-icon btnr-facebook" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-facebook"></i><span> 
                                    {noticia.reacciones.facebook_me_gusta > 0 && ( <> 👍 {noticia.reacciones.facebook_me_gusta} </> )}
                                    {noticia.reacciones.facebook_me_encanta > 0 && ( <> ❤️ {noticia.reacciones.facebook_me_encanta} </> )}
                                    {noticia.reacciones.facebook_me_importa > 0 && ( <> 💕 {noticia.reacciones.facebook_me_importa} </> )}
                                    {noticia.reacciones.facebook_me_divierte > 0 && ( <> 😂 {noticia.reacciones.facebook_me_divierte} </> )}
                                    {noticia.reacciones.facebook_me_sorprende > 0 && ( <> 😮 {noticia.reacciones.facebook_me_sorprende} </> )}
                                    {noticia.reacciones.facebook_me_entristece > 0 && ( <> 😢 {noticia.reacciones.facebook_me_entristece} </> )}
                                    {noticia.reacciones.facebook_me_enoja > 0 && ( <> 😡 {noticia.reacciones.facebook_me_enoja} </> )}
                                    {noticia.reacciones.facebook_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.facebook_comentarios} </> )}
                                    {noticia.reacciones.facebook_compartidos > 0 && ( <> 🔃 {noticia.reacciones.facebook_compartidos} </> )}
                                  </span></a>

                                </>
                              )}


                              {noticia.idmedio_red_social === 4 && (
                                <>
                                  <a className="btnr btnr-icon btnr-instagram" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-instagram"></i><span> 
                                    {noticia.reacciones.instagram_me_gusta > 0 && ( <> ❤️ {noticia.reacciones.instagram_me_gusta} </> )}
                                    {noticia.reacciones.instagram_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.instagram_comentarios} </> )}
                                  </span></a>

                                </>
                              )}


                              {noticia.idmedio_red_social === 2 && (
                                <>
                                  <a className="btnr btnr-icon btnr-youtube" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-youtube"></i><span> 
                                    {noticia.reacciones.youtube_me_gusta > 0 && ( <> 👍 {noticia.reacciones.youtube_me_gusta} </> )}
                                    {noticia.reacciones.youtube_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.youtube_comentarios} </> )}
                                    {noticia.reacciones.youtube_reproducciones > 0 && ( <> 👁️ {noticia.reacciones.youtube_reproducciones} </> )}
                                  </span></a>

                                </>
                              )}



                              {noticia.idmedio_red_social === 10 && (
                                <>
                                  <a className="btnr btnr-icon btnr-twitter" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-twitter"></i><span> 
                                    {noticia.reacciones.twitter_me_gusta > 0 && ( <> ❤️ {noticia.reacciones.twitter_me_gusta} </> )}
                                    {noticia.reacciones.twitter_respuestas > 0 && ( <> 🗨️ {noticia.reacciones.twitter_respuestas} </> )}
                                    {noticia.reacciones.twitter_retweet > 0 && ( <> 🔃 {noticia.reacciones.twitter_retweet} </> )}
                                  </span>
                                  </a>

                                </>
                              )}



                            </p>
                            )}

                            <p>
                              <img className="rounded-circle" src={ SERVER_ + 'medio/logo/square/'+noticia.uuid_medio+'/'+noticia.uuid_medio} alt={noticia.nombre_medio} width="10%" />
                            </p>
                            <h6 className="nm">{noticia.nombre_medio}</h6>
                            

                            <div className="row">
                              <div className="col">
                                <p className="nm"><small>{noticia.fecha_replica}</small></p>
                                <p className="nm"><small>{noticia.tipo_noticia}</small></p>

                                <br />

                              </div>
                              <div className="col-3">
                                {noticia.multimedia.length > 0 &&(
                                  <p className="text-center">
                                    <img src="../assets/images/multimedia.png" alt="Multimedia" width="50%" />
                                  </p>

                                )}
                              </div>
                              <div className="col-3">

                                  <div className="text-right">

                                          {  noticia.idtendencia === 1 && (<span className="tendencia positivas">{noticia.tendencia}</span>)}
                                          {  noticia.idtendencia === 2 && (<span className="tendencia negativas">{noticia.tendencia}</span>)}
                                          {  noticia.idtendencia === 3 && (<span className="tendencia neutras">{noticia.tendencia}</span>)}
                                  </div>

                              </div>
                            </div>

                      
                    </div>
                    <div className="col-sm-6">
                      
                        <div className="row" style={{'marginTop': 10}}>
                          {noticia.multimedia.map( (reg:any) =>


                              <div key={ Math.random().toString(36).substr(2, 9) } className="col-md-6">

                                  { (["jpg","jpeg","png"].indexOf(reg.tipo_archivo) >= 0) && ( 
                                    <>
                                      <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={ SERVER_ + 'hemeroteca/'+reg.uuid} alt={reg.uuid} width="100%" style={{"verticalAlign":'baseline'}} />
                                    </>
                                    )}


                                  { reg.tipo_archivo === "mp4" && ( 
                                    <>
                                          <video controls style={{"width": "100%"}}>
                                              <source type={'video/'+reg.tipo_archivo} src={ SERVER_ + 'hemeroteca/'+reg.uuid} />
                                          </video>
                                    </>
                                    )}


                                  { (reg.tipo_archivo === "mp3" || reg.tipo_archivo === "ogg") && ( 
                                    <>
                                          <audio controls style={{"width": "100%"}}>
                                              <source type={'audio/'+reg.tipo_archivo} src={ SERVER_ + 'hemeroteca/'+reg.uuid} />
                                          </audio>
                                    </>
                                    )}

                              </div>
                          )}
                        </div>


                    </div>
                  </div>

                    


                  </Modal.Body>
                </Modal>






            {
              show_ModalEditarReaccionesRedes && (
                <ModalEditarReaccionesRedes show={show_ModalEditarReaccionesRedes} handleClose={handleClose_ModalEditarReaccionesRedes} noticia={noticia_seleccionada} />
                )

            }


            </>

  );
};






const Replicas = () =>{

  registerLocale('es', es)

  const [ cargando_noticia, setCargandoNoticia ] = useState( true )
  const [ noticias, setNoticias ] = useState([])

  var [fecha,setFecha] = useState<any>( moment().format('YYYY-MM-DD') )

  const onChang = (date:any)=>{
    // let DATE = (moment(date).format('YYYY-MM-DD')) //.toString()

    setFecha( moment(date).format('YYYY-MM-DD') )
    cargarNoticias( moment(date).format('YYYY-MM-DD') )
  }


  const cargarNoticias = async ( fecha  )=>{
    setCargandoNoticia(true)
    RequestService.getEndPoint('noticias/replicas/' + moment(fecha).format('YYYY-MM-DD') )
    .then(
      response => {
        // console.log(response.data)
          setNoticias(response.data)
          setCargandoNoticia(false)
      },
      error => {
        // this.setState({
        //   content:
        //     (error.response &&
        //       error.response.data &&
        //       error.response.data.message) ||
        //     error.message ||
        //     error.toString()
        // });
      }
    );
  }

  

  React.useEffect(() => {
    cargarNoticias( moment().format('YYYY-MM-DD') )
  }, []);

  const startDate = new Date()



  const cargarNoticiasMetodo = () =>{
    cargarNoticias( fecha )
  }


    // render() {

      return (
          <>
              <div className="container">
                  
                  <h2>Listado de Notas Informativas</h2>

                  <div className="row">
                    <div className="col-md-3">


                        <h5>Fecha de filtro</h5>


                        <div className="text-center">
                          <DatePicker
                            onChange={(date)=>{ onChang(date) }}
                            startDate={startDate}
                            locale="es"
                            inline
                          />
                        </div>

                        <hr />

                        <ul className="nav nav-pills flex-column">
                          <li className="nav-item">
                            <Link className="nav-link" to="/noticia/replica/nueva"> Nueva Nota Informativa </Link>
                            <Link className="nav-link" to="/noticia/mis_replicas"> Listado de Noticias </Link>
                            <a className="nav-link" rel="noreferrer" target="_blank" href={ SERVER_ + 'replicas/reporte/'+fecha}> Reporte de Replicas </a>
                          </li>
                        </ul>



                    </div>
                    <div className="col-md-9">





                          {  (!cargando_noticia && noticias.length > 0 ) && (

                              <>

                            <h6>Listado de Notas para el día {fecha}</h6>
                            <p>Total de <b>{noticias.length}</b> notas informativas</p>

                            <hr />

                             <TablonaChaval registros={noticias} cargarNoticiasMetodo={cargarNoticiasMetodo} />

                              </>

                           )}

                          {  cargando_noticia && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

                          { noticias.length === 0 && !cargando_noticia && (


                              <div className="alert alert-dismissible alert-warning">
                                <h4 className="alert-heading">...</h4>
                                <p className="mb-0">No tenemos noticias para este día</p>
                              </div>


                           )}


                    </div>
                  </div>


              </div>




          </>

      );
  

}

export default Replicas
