import React, { useState } from 'react'
import RequestService from "../../../services/request.service";

import { toast } from 'react-toastify'


import { Modal, Button } from 'react-bootstrap'


import Dialog from '../../../services/Dialogs'

const ModalRectificarNoticia = (props)=>{

	const dialog = new Dialog()

	const { uuid_noticia, show, handleClose } = props


  const [ comments, setComments ] = useState({
    resuelto_todo : false,
    comments : []
  })

  const cargarComentarios = async () =>{
      try{
        let response = await RequestService.getEndPoint('noticias/comentarios_atencion/'+uuid_noticia)
        setComments(response.data)
      }
      catch(e){
        toast.error('Error al cargar los comentarios')
      }
  }


  React.useEffect(() => {
    cargarComentarios()
  }, []);


  const comentarioAtendido = async(uuid_comentario:string)=>{
    try{
      if(await dialog.confirm('¿Quieres marcar como atendido?',{})){
        await RequestService.update('noticia/comentario_atencion/'+uuid_comentario,{})
        await cargarComentarios()
      }
    }
    catch(e){
      toast.error('Error al cambiar el estatus del comentario')
    }
  }


  const handleCloseRectificarModal = async(e:any) =>{ 
    try{
    	
      if(await dialog.confirm('¿Estás seguro de enviar a verificación?',{})){
          await RequestService.update('noticia/manda_verificacion',{ uuid_noticia : uuid_noticia })
          toast.success('En espera de verificación ')
          // cargarMiReporte(fecha)
          // setShowRectificarModal(false)
          handleClose()
      }
      
    }
    catch(e){
      toast.error('Error al cambiar de estatus el tramite')
    }
  }

	return (

		<React.Fragment>

		      <Modal show={show} backdrop="static" onHide={handleClose} >
		        <Modal.Header closeButton>
		          <Modal.Title>Rectificar Datos</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		          

		            <p>Comentarios para la Atención del Trámite</p>


		            {
		              comments.comments.map( (comentario:any,i:number) =>

		                        <div key={ Math.random().toString(36).substr(2, 9) } className="card" style={{ margin: '5px 0'}}>
		                          <div className="card-body">
		                            <h6 className="card-subtitle text-muted"><b>{(i+1)}.-</b> {comentario.tipo_comentario} {comentario.finalizado&&(<i className="fa fa-check-circle" style={{color:"green"}} />)}</h6>
		                            <p style={{marginBottom:0}} className="card-text">{comentario.descripcion}</p>
		                            {
		                              !comentario.finalizado && (
		                                  <button type="button" onClick={()=>{comentarioAtendido(comentario.uuid)}} className="btn btn-link"><small>Marca de atendido</small></button>
		                              )
		                            }
		                            
		                          </div>
		                        </div>

		                )
		            }

		        </Modal.Body>
		        {
		          comments.resuelto_todo && (
		                  <Modal.Footer>
		                    <Button variant="primary" onClick={(event)=>{handleCloseRectificarModal(event)}}>
		                      Enviar a Mesa de Verificación
		                    </Button>
		                  </Modal.Footer>
		            )
		        }

		      </Modal>



		</React.Fragment>

	)

}


export default ModalRectificarNoticia