import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalEdicionMultimedia = ( props )=> {

  const { uuid_noticia, show, handleClose } = props
  
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>

      <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>UUID : <b>{uuid_noticia}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          

          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias iure laboriosam facilis consectetur illum cum perspiciatis ut odit, minima! Atque delectus maxime, quibusdam facilis qui dignissimos, explicabo quod deleniti ipsam.</p>


        </Modal.Body>
      </Modal>
    </>
  );


}


export default ModalEdicionMultimedia