import React, { useState } from "react";
import RequestService from "../../services/request.service";

import moment from 'moment'
import 'moment/locale/es'
// import { SERVER_ } from  '../../config';
// import { VictoryPie, VictoryLabel, VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryStack, VictoryGroup } from "victory";

import { Chart as ChartJS, ArcElement, CategoryScale, registerables, defaults } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';

// ChartJS.register(ArcElement, Tooltip, Legend);

import './Analiticas.scss';



import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

const Analiticas = () =>{


  // defaults.global.defaultFontFamily = 'Yeseva One'

defaults.font.family = 'Arsenal';



  ChartJS.register(ArcElement, CategoryScale, ...registerables);
  registerLocale('es', es)

  const [ cargando_noticia, setCargandoNoticias] = useState(true)

  const bd_skull = {
  "captura_usuarios": [{
      "cantidad": 0,
      "prensa": 0,
      "radio": 0,
      "television": 0,
      "web": 0,
      "redes_sociales": 0,
      "idusuario": 0,
      "username": "",
      "nombres": "",
  }],
  acumulado_municipios: [{ municipio : '', cantidad : 0 }]
}

// const myDataset = ;

  const [ big_data, setBigData ] = useState({...bd_skull})


  React.useEffect(() => {
    cargarBigData( moment().format('YYYY-MM-DD') )
  }, []);

  const [startDate, setDate ] = useState(new Date())


  const [ porcentajes_totales_tendencia, setTotalesTendencia ] = useState({ porcentaje_positivas: 0, porcentaje_negativas: 0, porcentaje_neutras: 0 })
  const [ totales, setTotales ] = useState({ prensa: { total: 0, positiva: 0, negativa: 0, neutra: 0 }, radio: { total: 0, positiva: 0, negativa: 0, neutra: 0 }, television: { total: 0, positiva: 0, negativa: 0, neutra: 0 }, web: { total: 0, positiva: 0, negativa: 0, neutra: 0 }, redes_sociales: { total: 0, positiva: 0, negativa: 0, neutra: 0 } })
  const [ total_noticias, setTotalNoticias ] = useState(0)
  const [ totales_tendencia, setTotalTendencias ] = useState({ positivas: 0, negativas: 0, neutras: 0 })
  const [ acumulados_secciones, setAcumuladosSecciones ] = useState([{id:0,seccion:"",totales_temas:0, totales_noticias : 0, totales_noticias_prensa : 0, totales_noticias_radio : 0, totales_noticias_television : 0, totales_noticias_web : 0, totales_noticias_redes_sociales : 0}])


  const cargarBigData = async (fecha) =>{
    setCargandoNoticias(true)
    setDate(fecha)
    let big = await RequestService.getEndPoint('analiticas/' + moment(fecha).format('YYYY-MM-DD') )
    setBigData(big.data)

    setDataPie(big.data.acumulados_tipo_medio)

    setDataBar(big.data.analitica_tendencias)

    setTotalTendencias(big.data.totales_tendencia)
    setTotalesTendencia(big.data.porcentajes_totales_tendencia)
    setTotales(big.data.totales)

    setAcumuladosSecciones(big.data.acumulados_secciones)

    setTotalNoticias(big.data.total_noticias)
    // setAcumuladosTipoMedio(big.data.acumulados_tipo_medio)
    // setAnaliticaTendencia(big.data.analitica_tendencias)
    setCargandoNoticias(false)
  }



const options = {
  responsive: true,
  plugins: {
    legend: {
      font: {
      family: 'Yeseva One' // Add your font here to change the font of your legend label
      },
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Tendencia de las Noticias por Tipo de Medio',
    },
  },
};


const options_pie = {
  defaults: { global: { defaultFontFamily:"Yeseva One"} },
  responsive: true,
  plugins : {
        title: {
      display: true,
      text: 'Acumulado de Noticias por Tipo de Medio',
    }
  }
};

const [data_bar, setDataBar ] = useState({
    "labels": [],
    "datasets": []
})


const height = 300

  const [ data_pie, setDataPie ] = useState({
  labels: [],
  datasets: [],
})




      return (
          <>

              <div className="container">
                  
                  <h2>Analiticas</h2>

                  <div className="row">
                    <div className="col-md-3">


                        <h5>Fecha de filtro</h5>


                        <div className="text-center">
                          <DatePicker
                            onChange={(date)=>{ cargarBigData(date) }}
                            startDate={startDate}
                            locale="es"
                            inline
                          />
                        </div>


                    </div>
                    <div className="col-md">
                        

                          {  cargando_noticia && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}

                          { (!cargando_noticia && total_noticias > 0) && (


                               <>

                                <h5 className="mb-4">Total de {total_noticias} Noticias para el día {moment(startDate).format("dddd, MMMM Do [del año] YYYY")}</h5>


                                <div className="mb-4">
                                  <div className="progress">
                                    <div className="progress-bar bg-positivas" role="progressbar" style={{'width': porcentajes_totales_tendencia.porcentaje_positivas+'%'}} ></div>
                                    <div className="progress-bar bg-negativas" role="progressbar" style={{'width': porcentajes_totales_tendencia.porcentaje_negativas+'%'}} ></div>
                                    <div className="progress-bar bg-neutras" role="progressbar" style={{'width': porcentajes_totales_tendencia.porcentaje_neutras+'%'}} ></div>
                                  </div>

                                <ul className="list-inline">
                                  <li className="list-inline-item"><small><i className="fa fa-circle positivas_" /> {totales_tendencia.positivas} POSITIVAS</small></li>
                                  <li className="list-inline-item"><small><i className="fa fa-circle negativas_" /> {totales_tendencia.negativas} NEGATIVAS</small></li>
                                  <li className="list-inline-item"><small><i className="fa fa-circle neutras_" /> {totales_tendencia.neutras} NEUTRAS</small></li>
                                </ul>


                                </div>

                               <div className="row mb-4">

                                 <div className="col-sm-2" >



                                     <h6 className="mt-2" style={{'fontSize':12}}>Noticias por Municipios</h6>
                                      <table className="table" style={{'fontSize':'10px'}}>
                                        <thead className="table-dark-">
                                          <tr>
                                            <th>Cantidad</th>
                                            <th>Municipio</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            {
                                              big_data.acumulado_municipios.map( municipio =>

                                                  <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                    <th scope="row">{municipio.municipio}</th>
                                                    <td className="text-center" style={{'width':'100px'}}>{municipio.cantidad}</td>
                                                  </tr>

                                              )
                                            }
                                        </tbody>
                                      </table>



                                 </div>
                                 <div className="col-sm-5" >

                                   <Pie options={options_pie} height={height} data={data_pie} />



                                 </div>

 
                                 <div className="col-sm-5">


                                   <Bar height={height} options={options} data={data_bar} />


                                   
                                 </div>
                               </div>




                                      <div className="row justify-content-center mt-4 mb-4">
                                        <div className="col-2 text-center">
                                          <p className="h4">{totales.prensa.total}</p>
                                          <div className="text-muted" id="count">Prensa</div>
                                          <p className="nm text-left"><i className="fa fa-circle positivas_" /> {totales.prensa.positiva} </p>
                                          <p className="nm text-left"><i className="fa fa-circle negativas_" /> {totales.prensa.negativa} </p>
                                          <p className="nm text-left"><i className="fa fa-circle neutras_" /> {totales.prensa.neutra} </p>
                                        </div>
                                        <div className="col-2 text-center">
                                          <p className="h4">{totales.radio.total}</p>
                                          <div className="text-muted" id="count">Radio</div>
                                          <p className="nm text-left"><i className="fa fa-circle positivas_" /> {totales.radio.positiva} </p>
                                          <p className="nm text-left"><i className="fa fa-circle negativas_" /> {totales.radio.negativa} </p>
                                          <p className="nm text-left"><i className="fa fa-circle neutras_" /> {totales.radio.neutra} </p>
                                        </div>
                                        <div className="col-2 text-center">
                                          <p className="h4">{totales.television.total}</p>
                                          <div className="text-muted" id="count">Televisión</div>
                                          <p className="nm text-left"><i className="fa fa-circle positivas_" /> {totales.television.positiva} </p>
                                          <p className="nm text-left"><i className="fa fa-circle negativas_" /> {totales.television.negativa} </p>
                                          <p className="nm text-left"><i className="fa fa-circle neutras_" /> {totales.television.neutra} </p>
                                        </div>
                                        <div className="col-2 text-center">
                                          <p className="h4">{totales.web.total}</p>
                                          <div className="text-muted" id="count">Web</div>
                                          <p className="nm text-left"><i className="fa fa-circle positivas_" /> {totales.web.positiva} </p>
                                          <p className="nm text-left"><i className="fa fa-circle negativas_" /> {totales.web.negativa} </p>
                                          <p className="nm text-left"><i className="fa fa-circle neutras_" /> {totales.web.neutra} </p>
                                        </div>
                                        <div className="col-2 text-center">
                                          <p className="h4">{totales.redes_sociales.total}</p>
                                          <div className="text-muted" id="count">Redes Sociales</div>
                                          <p className="nm text-left"><i className="fa fa-circle positivas_" /> {totales.redes_sociales.positiva} </p>
                                          <p className="nm text-left"><i className="fa fa-circle negativas_" /> {totales.redes_sociales.negativa} </p>
                                          <p className="nm text-left"><i className="fa fa-circle neutras_" /> {totales.redes_sociales.neutra} </p>
                                        </div>
                                      </div>

                                    <hr className="style-six mt-4" />


                                        <table className="table table-sm">
                                          <thead style={{"fontSize":10}}>
                                            <tr>
                                              <th colSpan={2}><small><b>Temáticas</b></small></th>
                                              <th className="text-center table-active" colSpan={6}><small>Noticias</small></th>
                                            </tr>
                                            <tr>
                                              <th scope="col">Usuario</th>
                                              <th scope="col">Temas</th>
                                              <th className="table-active" scope="col">Total</th>
                                              <th className="table-active" scope="col">Prensa</th>
                                              <th className="table-active" scope="col">Radio</th>
                                              <th className="table-active" scope="col">T.V.</th>
                                              <th className="table-active" scope="col">Web</th>
                                              <th className="table-active" scope="col">Redes Sociales</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {
                                            acumulados_secciones.map( seccion =>

                                                <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                  <th scope="row">{seccion.seccion}</th>
                                                  <td className="text-center" style={{'width':'100px'}}>{seccion.totales_temas}</td>
                                                  <td className="text-center" style={{'width':'100px'}}>{seccion.totales_noticias}</td>
                                                  <td className="text-center" style={{'width':'100px'}}>{seccion.totales_noticias_prensa}</td>
                                                  <td className="text-center" style={{'width':'100px'}}>{seccion.totales_noticias_radio}</td>
                                                  <td className="text-center" style={{'width':'100px'}}>{seccion.totales_noticias_television}</td>
                                                  <td className="text-center" style={{'width':'100px'}}>{seccion.totales_noticias_web}</td>
                                                  <td className="text-center" style={{'width':'100px'}}>{seccion.totales_noticias_redes_sociales}</td>
                                                </tr>

                                            )
                                          }
                                          </tbody>
                                        </table>





                                    <table className="table table-sm">
                                      <thead style={{"fontSize":10}}>
                                            <tr>
                                              <th colSpan={1}><small><b>Noticias Capturadas por Usuarios</b></small></th>
                                              <th className="text-center table-active" colSpan={6}><small>Noticias</small></th>
                                            </tr>
                                        <tr>
                                          <th scope="col">Usuario</th>
                                          <th scope="col">Total</th>
                                          <th scope="col">Prensa</th>
                                          <th scope="col">Radio</th>
                                          <th scope="col">T.V.</th>
                                          <th scope="col">Web</th>
                                          <th scope="col">Redes Sociales</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                      {
                                        big_data.captura_usuarios.map( usuario =>

                                            <tr key={ Math.random().toString(36).substr(2, 9) }>
                                              <th scope="row">{usuario.nombres}</th>
                                              <td className="text-center" style={{'width':'100px'}}>{usuario.cantidad}</td>
                                              <td className="text-center" style={{'width':'100px'}}>{usuario.prensa}</td>
                                              <td className="text-center" style={{'width':'100px'}}>{usuario.radio}</td>
                                              <td className="text-center" style={{'width':'100px'}}>{usuario.television}</td>
                                              <td className="text-center" style={{'width':'100px'}}>{usuario.web}</td>
                                              <td className="text-center" style={{'width':'100px'}}>{usuario.redes_sociales}</td>
                                            </tr>

                                        )
                                      }
                                      </tbody>
                                    </table>



                                    

                               </>


                           )}


                          {
                            (!cargando_noticia && total_noticias === 0 ) && (
                                  <>
                                    No tenemos noticias para esta fecha :/
                                  </>
                              )
                          }


                    </div>

                  </div>

               </div>


            

          </>

      );
  

}

export default Analiticas
