import React, { useState } from "react";
import RequestService from "../../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalAuditoriaEdiciones = ( props )=> {

  const { uuid_noticia, show, handleClose } = props
  
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [ data_auditoria, setDataAuditoria ] = useState<any>({
				noticia : [],
				cambios : []
			})
  const cargarAuditoria = async() =>{
  	try{
  		let res = await RequestService.getEndPoint('noticia/auditoria_cambios/'+uuid_noticia)
  		if(res.data)setDataAuditoria(res.data);
			else toast.error('Error al cargar la auditoria')

  	}
  	catch(e){
  		toast.error('Error al cargar la auditoria')
  	}
  }


  React.useEffect(() => {
    cargarAuditoria()
  }, []);


  return (
    <>

      <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>UUID : <b>{uuid_noticia}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>

        	<small><b>Título</b></small>
        	<p>{data_auditoria.noticia.titulo}</p>
          

        	<small><b>Detalle</b></small>
        	<p>{data_auditoria.noticia.detalle}</p>

        	<ul>
	        	{
	        		data_auditoria.cambios.map( cambio =>
	        			<li>
	        				{cambio.fecha_creacion} ({moment(cambio.fecha_creacion).fromNow()}) <b>{cambio.idusuario.nombres} {cambio.idusuario.paterno} {cambio.idusuario.materno}</b>
	        				{
	        					// JSON.stringify(cambio.idusuario)
	        				}
	        			</li>
	        		)
	        	}
        	</ul>
          

          {
          	// JSON.stringify(data_auditoria)
          }


        </Modal.Body>
      </Modal>
    </>
  );


}


export default ModalAuditoriaEdiciones