import React, { useState } from 'react'
import RequestService from "../../../services/request.service";

import { toast } from 'react-toastify'


import { Modal, Button } from 'react-bootstrap'


import Dialog from '../../../services/Dialogs'


const ModalEnviarRectificacion = (props) =>{

	const dialog = new Dialog()

	const { uuid_noticia, show, handleClose } = props

	const [ tipoA, setTipoA ] = useState(false)
	const [ tipoB, setTipoB ] = useState(false)
	const [ tipoC, setTipoC ] = useState(false)
	const [ tipoD, setTipoD ] = useState(false)


	const [ descripcionA, setDescripcionA ] = useState("")
	const [ descripcionB, setDescripcionB ] = useState("")
	const [ descripcionC, setDescripcionC ] = useState("")
	const [ descripcionD, setDescripcionD ] = useState("")


  const [ comments, setComments ] = useState({
    resuelto_todo : false,
    comments : []
  })

  const cargarComentarios = async () =>{
      try{
        let response = await RequestService.getEndPoint('noticias/comentarios_atencion/'+uuid_noticia)
        setComments(response.data)
      }
      catch(e){
        toast.error('Error al cargar los comentarios')
      }
  }



  React.useEffect(() => {
    cargarComentarios()
  }, []);



  const enviarRectificacion = async () => {
    try{
      if(await dialog.confirm('¿Estás seguro?',{})){
        var COMMENTS : any = []
          if(tipoA && descripcionA.trim() !== '') COMMENTS.push({ descripcion : descripcionA, tipo_comentario : 1 })
          if(tipoB && descripcionB.trim() !== '') COMMENTS.push({ descripcion : descripcionB, tipo_comentario : 2 })
          if(tipoC && descripcionC.trim() !== '') COMMENTS.push({ descripcion : descripcionC, tipo_comentario : 3 })
          if(tipoD && descripcionD.trim() !== '') COMMENTS.push({ descripcion : descripcionD, tipo_comentario : 4 })
          // console.log(COMMENTS)
      if(COMMENTS.length === 0){
      	toast.warning('Debes seleccionar al menos una ayuda en la corrección')
      }
      if(COMMENTS.length > 0){
        await RequestService.update('enviar_rectificacion/'+uuid_noticia,{ comments : COMMENTS })
        toast.warning('La noticia fue enviado al capturista para su rectificación de información')
        // cargarMiReporte(0)
        handleClose()
	    }

      }
    }
    catch(e){
      toast.error("Error al enviar a rectificación")
    }

  }


	return (

		<React.Fragment>

		      <Modal
		        show={show}
		        onHide={handleClose}
		        backdrop="static"
		        keyboard={false}
		      >
		        <Modal.Header closeButton>
		          <Modal.Title>
		            <h5 className="nm">ENVIAR TRÁMITE A RECTIFICACIÓN</h5>
		          </Modal.Title>
		        </Modal.Header>
		        <Modal.Body>

		        <p>Para ayudar al asesor a rectificar la información del trámite indica que tipo de corrección debe de hacer:</p>

		      <div className="form-check">
		        <input className="form-check-input" type="checkbox" id="flexCheckCheckedA" onClick={(e)=>{setTipoA(!tipoA)}} />
		        <label className="form-check-label" htmlFor="flexCheckCheckedA">
		          Detalles en la ortografía y/o redacción
		        </label>
		      </div>
		      {
		        tipoA && (
		            <div className="form-group">
		              <label htmlFor="exampleTextareaA" className="form-label mt-2">Detalles del comentario</label>
		              <textarea className="form-control" id="exampleTextareaA" value={descripcionA} onChange={(event)=>{setDescripcionA(event.target.value.toUpperCase())}} rows={3}></textarea>
		            </div>
		          )
		      }


		      <div className="form-check mt-2">
		        <input className="form-check-input" type="checkbox" id="flexCheckCheckedB" onClick={(e)=>{setTipoB(!tipoB)}} />
		        <label className="form-check-label" htmlFor="flexCheckCheckedB">
		          Información complementaria
		        </label>
		      </div>
		      {
		        tipoB && (
		            <div className="form-group">
		              <label htmlFor="exampleTextareaB" className="form-label mt-2">Detalles del comentario</label>
		              <textarea className="form-control" id="exampleTextareaB" value={descripcionB} onChange={(event)=>{setDescripcionB(event.target.value.toUpperCase())}} rows={3}></textarea>
		            </div>
		          )
		      }


		      <div className="form-check mt-2">
		        <input className="form-check-input" type="checkbox" id="flexCheckCheckedC" onClick={(e)=>{setTipoC(!tipoC)}} />
		        <label className="form-check-label" htmlFor="flexCheckCheckedC">
		          Información en los anexos
		        </label>
		      </div>
		      {
		        tipoC && (
		            <div className="form-group">
		              <label htmlFor="exampleTextareaC" className="form-label mt-2">Detalles del comentario</label>
		              <textarea className="form-control" value={descripcionC} onChange={(event)=>{setDescripcionC(event.target.value.toUpperCase())}} id="exampleTextareaC" rows={3}></textarea>
		            </div>
		          )
		      }


		      <div className="form-check mt-2">
		        <input className="form-check-input" type="checkbox" id="flexCheckCheckedD" onClick={(e)=>{setTipoD(!tipoD)}} />
		        <label className="form-check-label" htmlFor="flexCheckCheckedD">
		          Otros
		        </label>
		      </div>
		      {
		        tipoD && (
		            <div className="form-group">
		              <label htmlFor="exampleTextareaD" className="form-label mt-2">Detalles del comentario</label>
		              <textarea className="form-control" value={descripcionD} onChange={(event)=>{setDescripcionD(event.target.value.toUpperCase())}} id="exampleTextareaD" rows={3}></textarea>
		            </div>
		          )
		      }



		            {
		              comments.comments.length > 0 && (
		                  <p className="mt-4">Comentarios anteriores: </p>
		              )
		            }

		            


		            {
		              comments.comments.map( (comentario:any,i:number) =>

		                  <React.Fragment key={comentario.uuid}>

		                      <div  className="card" style={{ margin: '5px 0'}}>
		                        <div className="card-body">
		                          <h6 className="card-subtitle text-muted"><b>{(i+1)}.-</b> {comentario.tipo_comentario} <i style={{color:"green"}} className="fa fa-check-circle"/></h6>
		                          <p style={{marginBottom:0}} className="card-text">{comentario.descripcion}</p>
		                        </div>
		                      </div>

		                    {
		                      // JSON.stringify(comentario)
		                    }
		                  </React.Fragment>

		                )
		            }




		        </Modal.Body>
		        <Modal.Footer>
		          <Button onClick={()=>{enviarRectificacion()}} variant="primary">Enviar a Rectificación</Button>
		        </Modal.Footer>
		      </Modal>


		</React.Fragment>	
	)
	
}

export default ModalEnviarRectificacion