import React, { useState } from "react";
import RequestService from "../../services/request.service";

import './timeline.scss';


import { Modal, Button } from 'react-bootstrap'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import moment from 'moment'

import { SERVER_ } from  '../../config';








// const DetalleNoticia = ( objetos : any) => {
//   // console.log(objetos.detalles)
//   var st = objetos.detalles
//   for (var i = 0; i < objetos.etiquetas.length; ++i) {
//     st = st.replace("cambia_esto_"+i,'<a rel="noreferrer" target="_blank" href="">'+objetos.etiquetas[i].value+'</a>')
//   }
//   return (
//       <>
//       <small dangerouslySetInnerHTML={{ __html: st }} />
//       </>
//     )
// }

const Timeline = () =>{

  registerLocale('es', es)

  const [ noticias, setNoticias ] = useState([])
  const [ cargando_noticia, setCargandoNoticia ] = useState( true )

  const [ fecha, setFecha ] = useState(moment().format('YYYY-MM-DD'))


  const cargarNoticias = async (fecha : string) => {

    try{
      setCargandoNoticia(true)
      let noti = await RequestService.getEndPoint('timeline/'+fecha)
      // let noti = await RequestService.getEndPoint('noticias/replicas/'+fecha)
      setNoticias(noti.data)
      setCargandoNoticia(false)
    }
    catch(e){
      setCargandoNoticia(false)
      alert('Errorr')
    }

  }


  React.useEffect(() => {
    cargarNoticias(moment().format('YYYY-MM-DD'))
  }, []);



  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);


  const skullNoticia = {
  uuid: "",
  fecha_replica: "",
  titulo: "",
  detalle: "",
  fecha_creacion: "",
  idtipo_noticia: {
    tipo_noticia: "",
  },
  idmedio: {
    uuid: "",
    nombre: "",
    detalles: "",
    alcance: "",
    importancia: 0,
    credibilidad: 0,
    idtipo_medio: 0,
    idred_social: 1
  },
  idtendencia: {
    id : 0,
    tendencia: ""
  },

  reacciones: {
    url: "",
    facebook_me_gusta: 0,
    facebook_me_encanta: 0,
    facebook_me_importa: 0,
    facebook_me_divierte: 0,
    facebook_me_sorprende: 0,
    facebook_me_entristece: 0,
    facebook_me_enoja: 0,
    facebook_comentarios: 0,
    facebook_compartidos: 0,
    twitter_respuestas: 0,
    twitter_me_gusta: 0,
    twitter_retweet: 0,
    youtube_comentarios: 0,
    youtube_reproducciones: 0,
    youtube_me_gusta: 0,
    instagram_me_gusta: 0,
    instagram_comentarios: 0,
    idreplica: 0
  },
  idred_social : {
    id : 0
  },
  red_social: "",
  tags: [],
  multimedia: []
}

  const [ noticia, setNoticiaSeleccion ] = useState({...skullNoticia})

  const seleccionaNoticiaModal = (noticia:any)=>{
    setNoticiaSeleccion(noticia)
    setShow(true)
    console.log(noticia)
  }

  const CerrarModal = ()=>{
    setNoticiaSeleccion({...skullNoticia})
    setShow(false)
  }



  const [startDate, setStartDate ] = useState<any>(new Date())


  const [showDate, setShowDate ] = useState(false);

  const handleCloseDate = () => setShowDate(false);
  const handleShowDate = () => setShowDate(true);


  const handleChangeDate = () =>{ 
    setFecha(moment(startDate).format('YYYY-MM-DD'))
    cargarNoticias(moment(startDate).format('YYYY-MM-DD'))
    setShowDate(false);
  }





      return (
          <>



              <div className="container-fluid">
                  <div className="row justify-content-center">
                      <div className="col-sm-4">
                          <div className="timeline timeline-line-solid">


                              <div className="titulo_timeline">
                                <h3 className="text-center">NOTICIAS RELEVANTES</h3>
                                <p className="text-center">
                                  <Button onClick={handleShowDate} variant="link" size="sm">{moment(fecha).format("dddd, MMMM Do [del año] YYYY").toUpperCase()}</Button>
                                </p>
                                
                              </div>



                              {
                                cargando_noticia && (
                                            <>
                                              <div className="loader">
                                                <div className="loaderBar"></div>
                                              </div>
                                            </>
                                  )
                              }

                              {
                                !cargando_noticia && (
                                      <>

                                        { 


                                          <div className="">

                                              { 
                                                 noticias.map(
                                                   (noticia : any, i : number) => 
<div key={ Math.random().toString(36).substr(2, 9) } className="card" style={{"margin":"8px 0"}}>
  {
    noticia.multimedia.length > 0 && (
        <React.Fragment>

          {
            // JSON.stringify(noticia.multimedia[0].tipo_archivo)
          }


{
  // <img src={ SERVER_ + 'hemeroteca/'+noticia.multimedia[0].uuid} className="card-img-top" alt="..." />
}


                                  { (["jpg","jpeg","png"].indexOf(noticia.multimedia[0].tipo_archivo) >= 0) && ( 
                                    <>
                                      <img className="bd-placeholder-img flex-shrink-0 me-2 rounded img_full_width" src={ SERVER_ + 'hemeroteca/'+noticia.multimedia[0].uuid} alt={noticia.multimedia[0].uuid} width="100%" style={{"verticalAlign":'baseline'}} />
                                    </>
                                    )}


                                  { noticia.multimedia[0].tipo_archivo === "mp4" && ( 
                                    <>
                                          <video controls style={{"width": "100%"}}>
                                              <source type={'video/'+noticia.multimedia[0].tipo_archivo} src={ SERVER_ + 'hemeroteca/'+noticia.multimedia[0].uuid} />
                                          </video>
                                    </>
                                    )}


                                  { (noticia.multimedia[0].tipo_archivo === "mp3" || noticia.multimedia[0].tipo_archivo === "ogg") && ( 
                                    <>
                                          <audio controls style={{"width": "100%"}}>
                                              <source type={'audio/'+noticia.multimedia[0].tipo_archivo} src={ SERVER_ + 'hemeroteca/'+noticia.multimedia[0].uuid} />
                                          </audio>
                                    </>
                                    )}



        </React.Fragment>
      )
    // JSON.stringify(noticia.multimedia)
  }
  
  <div className="card-body">
    <h5 className="card-title">{noticia.titulo}</h5>
    <h6 className="card-subtitle mb-1 text-muted"><small><b>{noticia.idmedio.nombre}</b> {noticia.idtipo_medio.tipo_medio}</small></h6>
    <p onClick={()=>{ seleccionaNoticiaModal(noticia) }} style={{margin:"0 0 10px",lineHeight: .9}} className="text-justify card-text"><small>{noticia.detalle}</small></p>
    
      {
        noticia.tags.map(
          (etiqueta :any, indice : number) =>
                    <a key={ Math.random().toString(36).substr(2, 9) } className="card-link"><small>{etiqueta.etiqueta}</small></a>
                    
              )
      }

  </div>
</div>

                                                    )
                                              }




                                              { 
                                                // noticias.map(
                                                //   (noticia : any, i : number) => 

                                                //         <div key={i} className="timeline-item" >
                                                //             <p className="lolo">
                                                //               { (noticia.idtendencia.id === 2) && (<> <i className="fa fa-warning" /> &nbsp; &nbsp; </>)}
                                                //               { (noticia.idtendencia.id === 1) && (<> <i className="fa fa-heart" /> &nbsp; &nbsp; </>)}
                                                              
                                                //               {noticia.fecha_replica}
                                                //             </p>
                                                //           <h5 className="nm text-justify">{noticia.titulo}</h5>
                                                //           <p onClick={()=>{ seleccionaNoticiaModal(noticia) }} style={{"margin":"0 0 10px"}} className="text-justify"><small>{noticia.detalle}</small></p>
                                                //           <p className="" style={{"margin":'0 0 10px 0',"fontSize":16}}><small><b>{noticia.idmedio.nombre}</b> {noticia.idtipo_medio.tipo_medio}</small></p>


                                                //           {
                                                //             noticia.tags.map(
                                                //               (etiqueta :any, indice : number) =>
                                                //                         <span key={ Math.random().toString(36).substr(2, 9) }><span className="badge bg-danger"><small>{etiqueta.etiqueta}</small></span> &nbsp;</span>
                                                //                   )
                                                //           }

                                                //         </div>
                                                                    
                                                //    )
                                              }


                                          </div>





                                        //   noticias.map(
                                        //     (noticia : any) => 



                                        //         <div key={ Math.random().toString(36).substr(2, 9) } className="timeline-item">
                                        //             <div className="timeline-point timeline-point"></div>
                                        //             <div className="timeline-event">
                                        //                 <div className="widget has-shadow">
                                        //                     <div className="widget-header d-flex align-items-center">
                                        //                         <div className="user-image">
                                        //                             <img className="rounded-circle" src={ SERVER_ + 'medio/logo/square/'+noticia.idmedio.uuid+'/'+noticia.idmedio.uuid} alt="..." />
                                        //                         </div>
                                        //                         <div className="d-flex flex-column mr-auto">
                                        //                             <div className="title">
                                        //                                 <h6 className="nm">{noticia.idmedio.nombre}</h6>
                                        //                                 <span className="username">{noticia.titulo}</span>
                                        //                             </div>
                                        //                         </div>
                                        //                     </div>
                                        //                     <div className="widget-body" style={{'paddingBottom': 0}}>

                                        //                         <p onClick={()=>{ seleccionaNoticiaModal(noticia) }} className="noticia_detalle" style={{'marginBottom': 0,'cursor':'pointer'}}>
                                        //                             {noticia.detalle}
                                        //                         </p>


                                        //                           {
                                        //                             noticia.idred_social && (
                                        //                             <>
                                        //                               {noticia.idred_social === 1 && (
                                        //                                 <>
                                        //                                   <a className="btnr btnr-icon btnr-facebook" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-facebook"></i><span> 
                                        //                                     {noticia.reacciones.facebook_me_gusta > 0 && ( <> 👍 {noticia.reacciones.facebook_me_gusta} </> )}
                                        //                                     {noticia.reacciones.facebook_me_encanta > 0 && ( <> ❤️ {noticia.reacciones.facebook_me_encanta} </> )}
                                        //                                     {noticia.reacciones.facebook_me_importa > 0 && ( <> 💕 {noticia.reacciones.facebook_me_importa} </> )}
                                        //                                     {noticia.reacciones.facebook_me_divierte > 0 && ( <> 😂 {noticia.reacciones.facebook_me_divierte} </> )}
                                        //                                     {noticia.reacciones.facebook_me_sorprende > 0 && ( <> 😮 {noticia.reacciones.facebook_me_sorprende} </> )}
                                        //                                     {noticia.reacciones.facebook_me_entristece > 0 && ( <> 😢 {noticia.reacciones.facebook_me_entristece} </> )}
                                        //                                     {noticia.reacciones.facebook_me_enoja > 0 && ( <> 😡 {noticia.reacciones.facebook_me_enoja} </> )}
                                        //                                     {noticia.reacciones.facebook_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.facebook_comentarios} </> )}
                                        //                                     {noticia.reacciones.facebook_compartidos > 0 && ( <> 🔃 {noticia.reacciones.facebook_compartidos} </> )}
                                        //                                   </span></a>

                                        //                                 </>
                                        //                               )}


                                        //                               {noticia.idred_social === 4 && (
                                        //                                 <>
                                        //                                   <a className="btnr btnr-icon btnr-instagram" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-instagram"></i><span> 
                                        //                                     {noticia.reacciones.instagram_me_gusta > 0 && ( <> ❤️ {noticia.reacciones.instagram_me_gusta} </> )}
                                        //                                     {noticia.reacciones.instagram_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.instagram_comentarios} </> )}
                                        //                                   </span></a>

                                        //                                 </>
                                        //                               )}


                                        //                               {noticia.idred_social === 2 && (
                                        //                                 <>
                                        //                                   <a className="btnr btnr-icon btnr-youtube" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-youtube"></i><span> 
                                        //                                     {noticia.reacciones.youtube_me_gusta > 0 && ( <> 👍 {noticia.reacciones.youtube_me_gusta} </> )}
                                        //                                     {noticia.reacciones.youtube_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.youtube_comentarios} </> )}
                                        //                                     {noticia.reacciones.youtube_reproducciones > 0 && ( <> 👁️ {noticia.reacciones.youtube_reproducciones} </> )}
                                        //                                   </span></a>

                                        //                                 </>
                                        //                               )}



                                        //                               {noticia.idred_social === 10 && (
                                        //                                 <>
                                        //                                   <a className="btnr btnr-icon btnr-twitter" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-twitter"></i><span> 
                                        //                                     {noticia.reacciones.twitter_me_gusta > 0 && ( <> ❤️ {noticia.reacciones.twitter_me_gusta} </> )}
                                        //                                     {noticia.reacciones.twitter_respuestas > 0 && ( <> 🗨️ {noticia.reacciones.twitter_respuestas} </> )}
                                        //                                     {noticia.reacciones.twitter_retweet > 0 && ( <> 🔃 {noticia.reacciones.twitter_retweet} </> )}
                                        //                                   </span></a>

                                        //                                 </>
                                        //                               )}



                                        //                             </>
                                        //                             )
                                        //                           }


                                        //                     </div>
                                        //                 </div>

                                        //                 <div className="row">
                                        //                   <div className="col">
                                        //                     <p className="leftp nm"><small>{noticia.fecha_replica}</small></p>
                                        //                     <p className="leftp nm"><small>{noticia.idtipo_noticia.tipo_noticia}</small></p>

                                        //                     <br />

                                        //                   </div>
                                        //                   <div className="col-3">


                                        //                     {noticia.multimedia.length > 0 &&(
                                        //                       <p className="text-center">
                                        //                         <img src="assets/images/multimedia.png" alt="Multimedia" width="20%" />
                                        //                       </p>

                                        //                     )}

                                        //                   </div>
                                        //                   <div className="col-3">

                                        //                       <div className="text-right">

                                        //                               {  noticia.idtendencia.id === 1 && (<span className="tendencia positivas">{noticia.idtendencia.tendencia}</span>)}
                                        //                               {  noticia.idtendencia.id === 2 && (<span className="tendencia negativas">{noticia.idtendencia.tendencia}</span>)}
                                        //                               {  noticia.idtendencia.id === 3 && (<span className="tendencia neutras">{noticia.idtendencia.tendencia}</span>)}
                                        //                       </div>

                                        //                   </div>
                                        //                 </div>


                                        //             </div>
                                        //         </div>

                                        //   )

                                        }




                                      </>
                                  )
                              }







                          </div>
                      </div>
                  </div>
              </div>




                <Modal show={show} fullscreen={true} onHide={CerrarModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>NOTICIA</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>



                  <div className="row">
                    <div className="col-sm-6">

                    {
                      // JSON.stringify(noticia.reacciones)
                    }


                            <dl>
                              <dt>Titular</dt>
                              <dd>{noticia.titulo}</dd>
                              <dt>Síntesis</dt>
                              <dd>{noticia.detalle}</dd>
                              <dd>
                                
                    {
                      noticia.tags.map(
                        (etiqueta :any, indice : number) =>
                                  <span key={ Math.random().toString(36).substr(2, 9) }><span className="badge bg-danger"><small>{etiqueta.etiqueta}</small></span> &nbsp;</span>
                            )
                    }
                              </dd>
                              { noticia.idmedio.idred_social && (
                                <>
                                  <dt>Red Social</dt>
                                  <dd>{noticia.red_social}</dd>
                                </>
                              )}
                            </dl>



                            { 
                              noticia.idred_social && (
                              <p>
                                {noticia.idred_social.id === 1 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-facebook" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-facebook"></i><span> 
                                      {noticia.reacciones.facebook_me_gusta > 0 && ( <> 👍 {noticia.reacciones.facebook_me_gusta} </> )}
                                      {noticia.reacciones.facebook_me_encanta > 0 && ( <> ❤️ {noticia.reacciones.facebook_me_encanta} </> )}
                                      {noticia.reacciones.facebook_me_importa > 0 && ( <> 💕 {noticia.reacciones.facebook_me_importa} </> )}
                                      {noticia.reacciones.facebook_me_divierte > 0 && ( <> 😂 {noticia.reacciones.facebook_me_divierte} </> )}
                                      {noticia.reacciones.facebook_me_sorprende > 0 && ( <> 😮 {noticia.reacciones.facebook_me_sorprende} </> )}
                                      {noticia.reacciones.facebook_me_entristece > 0 && ( <> 😢 {noticia.reacciones.facebook_me_entristece} </> )}
                                      {noticia.reacciones.facebook_me_enoja > 0 && ( <> 😡 {noticia.reacciones.facebook_me_enoja} </> )}
                                      {noticia.reacciones.facebook_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.facebook_comentarios} </> )}
                                      {noticia.reacciones.facebook_compartidos > 0 && ( <> 🔃 {noticia.reacciones.facebook_compartidos} </> )}
                                    </span></a>

                                  </>
                                )}


                                {noticia.idred_social.id === 4 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-instagram" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-instagram"></i><span> 
                                      {noticia.reacciones.instagram_me_gusta > 0 && ( <> ❤️ {noticia.reacciones.instagram_me_gusta} </> )}
                                      {noticia.reacciones.instagram_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.instagram_comentarios} </> )}
                                    </span></a>

                                  </>
                                )}


                                {noticia.idred_social.id === 2 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-youtube" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-youtube"></i><span> 
                                      {noticia.reacciones.youtube_me_gusta > 0 && ( <> 👍 {noticia.reacciones.youtube_me_gusta} </> )}
                                      {noticia.reacciones.youtube_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.youtube_comentarios} </> )}
                                      {noticia.reacciones.youtube_reproducciones > 0 && ( <> 👁️ {noticia.reacciones.youtube_reproducciones} </> )}
                                    </span></a>

                                  </>
                                )}



                                {noticia.idred_social.id === 10 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-twitter" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-twitter"></i><span> 
                                      {noticia.reacciones.twitter_me_gusta > 0 && ( <> ❤️ {noticia.reacciones.twitter_me_gusta} </> )}
                                      {noticia.reacciones.twitter_respuestas > 0 && ( <> 🗨️ {noticia.reacciones.twitter_respuestas} </> )}
                                      {noticia.reacciones.twitter_retweet > 0 && ( <> 🔃 {noticia.reacciones.twitter_retweet} </> )}
                                    </span></a>

                                  </>
                                )}



                              </p>
                              )
                            }

                            <p>
                              <img className="rounded-circle" src={ SERVER_ + 'medio/logo/square/'+noticia.idmedio.uuid+'/'+noticia.idmedio.uuid} alt="..." width="10%" />
                            </p>
                            <h6 className="nm">{noticia.idmedio.nombre}</h6>
                            

                            <div className="row">
                              <div className="col">
                                <p className="nm"><small>{noticia.fecha_replica}</small></p>
                                <p className="nm"><small>{noticia.idtipo_noticia.tipo_noticia}</small></p>

                                <br />

                              </div>
                              <div className="col-3">
                                {noticia.multimedia.length > 0 &&(
                                  <p className="text-center">
                                    <img src="assets/images/multimedia.png" alt="Multimedia" width="30%" />
                                  </p>

                                )}
                              </div>
                              <div className="col-3">

                                  <div className="text-right">

                                          {  noticia.idtendencia.id === 1 && (<span className="tendencia positivas">{noticia.idtendencia.tendencia}</span>)}
                                          {  noticia.idtendencia.id === 2 && (<span className="tendencia negativas">{noticia.idtendencia.tendencia}</span>)}
                                          {  noticia.idtendencia.id === 3 && (<span className="tendencia neutras">{noticia.idtendencia.tendencia}</span>)}
                                  </div>

                              </div>
                            </div>

                      
                    </div>
                    <div className="col-sm-6">
                      
                        <div className="row" style={{'marginTop': 10}}>
                          {noticia.multimedia.map( (reg:any) =>


                              <div key={ Math.random().toString(36).substr(2, 9) } className="col-md-6">

                                  { (["jpg","jpeg","png"].indexOf(reg.tipo_archivo) >= 0) && ( 
                                    <>
                                      <img className="bd-placeholder-img flex-shrink-0 me-2 rounded img_full_width" src={ SERVER_ + 'hemeroteca/'+reg.uuid} alt={reg.uuid} width="100%" style={{"verticalAlign":'baseline'}} />
                                    </>
                                    )}


                                  { reg.tipo_archivo === "mp4" && ( 
                                    <>
                                          <video controls style={{"width": "100%"}}>
                                              <source type={'video/'+reg.tipo_archivo} src={ SERVER_ + 'hemeroteca/'+reg.uuid} />
                                          </video>
                                    </>
                                    )}


                                  { (reg.tipo_archivo === "mp3" || reg.tipo_archivo === "ogg") && ( 
                                    <>
                                          <audio controls style={{"width": "100%"}}>
                                              <source type={'audio/'+reg.tipo_archivo} src={ SERVER_ + 'hemeroteca/'+reg.uuid} />
                                          </audio>
                                    </>
                                    )}

                              </div>
                          )}
                        </div>


                    </div>
                  </div>

                    


                  </Modal.Body>
                </Modal>



                  <Modal size="sm" show={showDate} onHide={handleCloseDate}>
                    <Modal.Header closeButton>
                      <Modal.Title>Fecha de Reporte</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                      <div className="text-center">
                        <DatePicker
                          onChange={(date)=>{ setStartDate(date) }}
                          startDate={startDate}
                          locale="es"
                          inline
                        />
                      </div>

                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseDate}>
                        Cerrar
                      </Button>
                      <Button variant="primary" onClick={handleChangeDate}>
                        Cambiar Fecha
                      </Button>
                    </Modal.Footer>
                  </Modal>




          </>

      );
  

}

export default Timeline
