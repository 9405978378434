import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const YourDialog = ({ show , proceed, confirmation, options }) => (


      <Modal
        show={show}
        onHide={() => proceed(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <p className="nm text-center">{confirmation}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => proceed(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => proceed(true)}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

)


YourDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  confirmation: PropTypes.string,  // arguments of your confirm function
  options: PropTypes.object        // arguments of your confirm function
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(YourDialog);



  // <Dialog  show={show}>
  //   {confirmation}
  //   <button >CANCEL</button>
  //   <button >OK</button>
  // </Dialog>

