import React, { useState } from "react";
import RequestService from "../../services/request.service";

import moment from 'moment'
import 'moment/locale/es'

// import './Etiquetas.scss';

import { Modal, Button } from 'react-bootstrap'

import { toast } from 'react-toastify'


// import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap-daterangepicker/daterangepicker.css';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



import Pagination from "../../services/Pagination";


const EtiquetasReporte = () =>{

  const [ etiqueta, setEtiqueta ] = useState<any>(null)



  React.useEffect(() => {
    cargarDetalle()
  }, []);


  const [ etiquetas_filtro, setEtiquetasFiltro ] = useState<any>([])

  const cargarDetalle = async () => {

    try{
      let request = await RequestService.create('etiqueta/reporte_rango', { etiquetas : etiquetas_filtro.map(obj => obj.uuid), fecha_inicio : moment(startDate).format('YYYY-MM-DD'), fecha_fin : moment(endDate).format('YYYY-MM-DD') } )
      setEtiqueta(request.data)
    }
    catch(e){
      toast.error('Error al cargar')
    }

  }


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ cargando_noticia, setCargandoNoticias] = useState(false)

  const skullEtiqueta = {
      uuid: "",
      etiqueta: "",
      idtendencia: 0,
      tendencia: "",
      idseccion: 0,
      seccion: ""
  }


  const [ etiquetas, setEtiquetas ] = useState<any>([])
  const [ total_resultados, setTotalResulados ] = useState(0)

  const [ text_busqueda, setTextoBusqueda ] = useState('')


  const [ pagina, setPagina ] = useState(0)

  const busquedaEtiquetas = async () =>{
    setPagina(0)
    cargarEtiquetas(0)
    setCurrentPage(1)
  }

  const cargarEtiquetas = async (page:number) =>{
      setCargandoNoticias(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let resultados = await RequestService.getEndPoint('busqueda_etiquetas?where='+text_busqueda+'&skip='+skip)
      // console.log(resultados.data)
      setEtiquetas(resultados.data.results)
      setTotalResulados(resultados.data.totalCount)
      setCounter(resultados.data.totalCount)
      // setPaginas(resultados.data.numberPages)
      setCargandoNoticias(false)
  }

  const agregarEtiqueta = (etiqueta:any) => {

    try{
      let existe = false
      for (var i = 0; i < etiquetas_filtro.length; ++i) {
         if(etiquetas_filtro[i].uuid === etiqueta.uuid) existe = true
      }
      if(!existe){ 
        let eti = [...etiquetas_filtro]
        eti.push(etiqueta)
        setEtiquetasFiltro(eti)
      }
    }
    catch(e){
      toast.error('No pudimos agregar la etiqueta')
    }

  }


      return (
          <>

              <div className="container">
                  
                  <h2>Reporte de Etiquetas</h2>

                  <div className="row">
                      <div className="col-3">


                          <div className="mb-3">
                            <label className="form-label">Fecha del Reporte</label>


                            <DatePicker
                              selected={startDate}
                              onChange={onChange}
                              startDate={startDate}
                              endDate={endDate}
                              selectsRange
                              inline
                            />

                            

                            <p>
                              <button type="button" onClick={handleShow} className="btn btn-link"><small>Filtro de Etiquetas</small></button>
                            </p>

                            {
                              // JSON.stringify(etiquetas_filtro)
                            }


<ul className="list-group list-group-flush mb-4">
  {
    etiquetas_filtro.map( etiqueta =>
        <li className="list-group-item">{etiqueta.etiqueta}</li>
    )
  }
</ul>


                            <button type="button" onClick={()=>{cargarDetalle()}} className="btn btn-primary">Crear Reporte</button>


                          </div>


                        
                      </div>
                      <div className="col-9">
                          {
                              etiqueta && (

                                <React.Fragment>

                                    <h4>{etiqueta.etiqueta}</h4>

                                  {
                                    JSON.stringify(etiqueta)
                                  }
                                </React.Fragment>

                              )
                          }
                      </div>
                  </div>

              
              </div>



      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Etiquetas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
                              <div className="">
                                <label className="form-label">Busqueda de Etiquetas</label>
                                <div className="input-group mb-3">
                                  <input type="text" className="form-control" value={text_busqueda} onKeyPress={(e)=>{if(e.charCode == 13) busquedaEtiquetas() }} onChange={event => setTextoBusqueda(event.target.value)} />
                                  <button onClick={busquedaEtiquetas} className="btn btn-primary" type="button" style={{color:'black'}} id="button-addon2"><i className="fa fa-search" /></button>
                                </div>
                              </div>



                          { !cargando_noticia && (

                            <>





                                  { total_resultados === 0 && (

                                    <>

                                        Trata de buscar algo...


                                    </>

                                    )
                                  }



                                  { total_resultados > 0 && (

                                    <>

                                        <table className="table table-sm">
                                          <thead>
                                            <tr>
                                              <th scope="col">Etiqueta</th>
                                              <th scope="col">Sección</th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>


                                            {
                                              etiquetas.map( (etiqueta, i) =>


                                                      <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                        

                                                        <td>
                                                          <p className="nm">{etiqueta.etiqueta}</p>
                                                          <small>{etiqueta.uuid}</small>
                                                        </td>
                                                        <td className="v" style={{"width":"90px"}}>
                                                           { !etiqueta.idseccion && ( <p className="nm text-center"><i className="fa fa-warning" /> </p> )  }
                                                           { etiqueta.idseccion && ( <p className="nm text-center"><small >{etiqueta.seccion}</small></p> )  }
                                                        </td>
                                                        <td className="v" style={{"width":"40px"}}>
                                                          <button onClick={()=>{agregarEtiqueta(etiqueta)}} type="button" className="btn btn-sm btn-link"><i className="fa fa-plus" /></button>
                                                        </td>
                                                      </tr>

                                              )
                                            }



                                          </tbody>
                                        </table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarEtiquetas}
                                            alwaysShown={false}
                                          />



                                    </>

                                    )
                                  }


                              </>

                              )
                            }


        </Modal.Body>
      </Modal>

          </>

      );
  

}

export default EtiquetasReporte
