import React, { useState } from 'react'
import RequestService from "../../../services/request.service";

import { toast } from 'react-toastify'


import moment from 'moment'

import './caratula.scss';




import { Modal, Button } from 'react-bootstrap'

import { SERVER_ } from  '../../../config';

import Dialog from '../../../services/Dialogs'

const ModalNoticiaCaratula = (props)=>{

	const dialog = new Dialog()

	const { uuid_noticia, show, handleClose } = props

  const [ noticia, setNoticia ] = useState<any>({
                uuid_noticia: "",
                uuid_medio: "",
                fecha_replica: "",
                titulo: "",
                detalle: "",
                fecha_creacion: "",
                // idtipo_noticia: 0,
                idmedio_red_social: 0,
                // {

                // },
                idmedio: {
                    uuid: "",
                    nombre: "",
                    detalles: "",
                    alcance: "",
                    importancia: 0,
                    credibilidad: 0,
                    idtipo_medio: 0,
                },
                idtipo_noticia : {
                  tipo_noticia : ""
                },
                idtipo_medio : {
                  tipo_medio : ""
                },
                idred_social: null,
                // },
                idtendencia: {
                    id: 0,
                    tendencia: ""
                },
                // 
                //   "id" : 0,
                tendencia: "",
                nombre_medio: "",
                // },

                reacciones: {
                    url: "",
                    facebook_me_gusta: 0,
                    facebook_me_encanta: 0,
                    facebook_me_importa: 0,
                    facebook_me_divierte: 0,
                    facebook_me_sorprende: 0,
                    facebook_me_entristece: 0,
                    facebook_me_enoja: 0,
                    facebook_comentarios: 0,
                    facebook_compartidos: 0,
                    twitter_respuestas: 0,
                    twitter_me_gusta: 0,
                    twitter_retweet: 0,
                    youtube_comentarios: 0,
                    youtube_reproducciones: 0,
                    youtube_me_gusta: 0,
                    instagram_me_gusta: 0,
                    instagram_comentarios: 0,
                    idreplica: 0
                },
                red_social: "",
                tags : [],
                multimedia : []
            })

  const cargarNoticia = async () =>{
      try{
        let response = await RequestService.getEndPoint('noticia/caratula/'+uuid_noticia)
        setNoticia(response.data)
      }
      catch(e){
        toast.error('Error al cargar los comentarios')
      }
  }

  // const [ comments, setComments ] = useState({
  //   resuelto_todo : false,
  //   comments : []
  // })

  // const cargarComentarios = async () =>{
  //     try{
  //       let response = await RequestService.getEndPoint('noticias/comentarios_atencion/'+uuid_noticia)
  //       setComments(response.data)
  //     }
  //     catch(e){
  //       toast.error('Error al cargar los comentarios')
  //     }
  // }


  React.useEffect(() => {
    cargarNoticia()
  }, []);



	return (

		<React.Fragment>

		      <Modal show={show} size="lg" backdrop="static" onHide={handleClose} >
		        <Modal.Header closeButton>
		          <Modal.Title>{noticia.idmedio.nombre}</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>







                  <div className="row">
                    <div className="col-sm-6">


                            {
                              noticia.idmedio.uuid !== '' && (
                                <React.Fragment>

                                    <ul className="list-group list-group-flush">
                                      <li className="list-group-item d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                          <img src={ SERVER_ + 'medio/logo/square/'+noticia.idmedio.uuid+'/'+noticia.idmedio.uuid} alt={noticia.idmedio.nombre} style={{width: '45px', height: '45px'}} className="rounded-circle" />
                                          <div className="ms-3">
                                            <p className="fw-bold mb-1">{noticia.idmedio.nombre}</p>
                                            <p className="text-muted mb-0">ALCANCE <b>{noticia.idmedio.alcance}</b></p>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>

                                </React.Fragment>
                              )
                            }


                            <dl>
                              <dt>Titular</dt>
                              <dd>{noticia.titulo}</dd>
                              <dt>Síntesis</dt>
                              <dd>{noticia.detalle}</dd>
                              { noticia.idmedio_red_social && (
                                <>
                                  <dt>Red Social</dt>
                                  <dd>{noticia.red_social}</dd>
                                </>
                              )}
                            </dl>



                          { noticia.idred_social && (
                            <p>
                              {noticia.idred_social.id === 1 && (
                                <>
                                  <a className="btnr btnr-icon btnr-facebook" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-facebook"></i><span> 
                                    {noticia.reacciones.facebook_me_gusta > 0 && ( <> 👍 {noticia.reacciones.facebook_me_gusta} </> )}
                                    {noticia.reacciones.facebook_me_encanta > 0 && ( <> ❤️ {noticia.reacciones.facebook_me_encanta} </> )}
                                    {noticia.reacciones.facebook_me_importa > 0 && ( <> 💕 {noticia.reacciones.facebook_me_importa} </> )}
                                    {noticia.reacciones.facebook_me_divierte > 0 && ( <> 😂 {noticia.reacciones.facebook_me_divierte} </> )}
                                    {noticia.reacciones.facebook_me_sorprende > 0 && ( <> 😮 {noticia.reacciones.facebook_me_sorprende} </> )}
                                    {noticia.reacciones.facebook_me_entristece > 0 && ( <> 😢 {noticia.reacciones.facebook_me_entristece} </> )}
                                    {noticia.reacciones.facebook_me_enoja > 0 && ( <> 😡 {noticia.reacciones.facebook_me_enoja} </> )}
                                    {noticia.reacciones.facebook_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.facebook_comentarios} </> )}
                                    {noticia.reacciones.facebook_compartidos > 0 && ( <> 🔃 {noticia.reacciones.facebook_compartidos} </> )}
                                  </span></a>

                                </>
                              )}


                              {noticia.idred_social.id === 4 && (
                                <>
                                  <a className="btnr btnr-icon btnr-instagram" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-instagram"></i><span> 
                                    {noticia.reacciones.instagram_me_gusta > 0 && ( <> ❤️ {noticia.reacciones.instagram_me_gusta} </> )}
                                    {noticia.reacciones.instagram_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.instagram_comentarios} </> )}
                                  </span></a>

                                </>
                              )}


                              {noticia.idred_social.id === 2 && (
                                <>
                                  <a className="btnr btnr-icon btnr-youtube" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-youtube"></i><span> 
                                    {noticia.reacciones.youtube_me_gusta > 0 && ( <> 👍 {noticia.reacciones.youtube_me_gusta} </> )}
                                    {noticia.reacciones.youtube_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.youtube_comentarios} </> )}
                                    {noticia.reacciones.youtube_reproducciones > 0 && ( <> 👁️ {noticia.reacciones.youtube_reproducciones} </> )}
                                  </span></a>

                                </>
                              )}



                              {noticia.idred_social.id === 10 && (
                                <>
                                  <a className="btnr btnr-icon btnr-twitter" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-twitter"></i><span> 
                                    {noticia.reacciones.twitter_me_gusta > 0 && ( <> ❤️ {noticia.reacciones.twitter_me_gusta} </> )}
                                    {noticia.reacciones.twitter_respuestas > 0 && ( <> 🗨️ {noticia.reacciones.twitter_respuestas} </> )}
                                    {noticia.reacciones.twitter_retweet > 0 && ( <> 🔃 {noticia.reacciones.twitter_retweet} </> )}
                                  </span>
                                  </a>

                                </>
                              )}



                            </p>
                            )}



                            <div className="row mt-2">
                              <div className="col">
                                <p className="nm"><small>{noticia.fecha_replica}</small></p>
                                <p className="nm"><small>{noticia.idtipo_noticia.tipo_noticia}</small></p>

                                {
                                  noticia.publicar && (
                                    <p className="nm"><small><i className="fa fa-check" /> Noticia para el Timeline</small></p>
                                  )
                                }
                                {
                                  noticia.publicar && (
                                    <p className="nm"><small><i className="fa fa-warning" /> FAKE NEW</small></p>
                                  )
                                }

                              </div>
                              <div className="col-3">
                                {noticia.multimedia.length > 0 &&(
                                  <p className="text-center">
                                    <img src="../assets/images/multimedia.png" alt="Multimedia" width="50%" />
                                  </p>

                                )}
                              </div>
                              <div className="col-3">

                                  <div className="text-right">

                                          {  noticia.idtendencia.id === 1 && (<span className="tendencia positivas">{noticia.idtendencia.tendencia}</span>)}
                                          {  noticia.idtendencia.id === 2 && (<span className="tendencia negativas">{noticia.idtendencia.tendencia}</span>)}
                                          {  noticia.idtendencia.id === 3 && (<span className="tendencia neutras">{noticia.idtendencia.tendencia}</span>)}
                                  </div>

                              </div>
                            </div>

                      
                    </div>
                    <div className="col-sm-6">
                      
                        <div className="row" style={{'marginTop': 10}}>
                          {noticia.multimedia.map( (reg:any) =>


                              <div key={ Math.random().toString(36).substr(2, 9) } className="col-md-6">

                                  { (["jpg","jpeg","png"].indexOf(reg.tipo_archivo) >= 0) && ( 
                                    <>
                                      <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={ SERVER_ + 'hemeroteca/'+reg.uuid} alt={reg.uuid} width="100%" style={{"verticalAlign":'baseline'}} />
                                    </>
                                    )}


                                  { reg.tipo_archivo === "mp4" && ( 
                                    <>
                                          <video controls style={{"width": "100%"}}>
                                              <source type={'video/'+reg.tipo_archivo} src={ SERVER_ + 'hemeroteca/'+reg.uuid} />
                                          </video>
                                    </>
                                    )}


                                  { (reg.tipo_archivo === "mp3" || reg.tipo_archivo === "ogg") && ( 
                                    <>
                                          <audio controls style={{"width": "100%"}}>
                                              <source type={'audio/'+reg.tipo_archivo} src={ SERVER_ + 'hemeroteca/'+reg.uuid} />
                                          </audio>
                                    </>
                                    )}

                              </div>
                          )}
                        </div>


                    </div>
                  </div>



		        </Modal.Body>


		      </Modal>



		</React.Fragment>

	)

}


export default ModalNoticiaCaratula