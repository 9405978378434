import React, { useState } from "react";

import { toast } from 'react-toastify'

import RequestService from "../../services/request.service";

import './Medios.scss';

import ReactStars from "react-rating-stars-component";

import Modal from 'react-bootstrap/Modal'

import { SERVER_ } from  '../../config';

import { confirm } from '../../widgets/confirm'





const TablaMedios = ({ medios, someMethod }) => {

  var MDSkull = {
  	nombre : '',
  	detalles : '',
  	alcance : '',
  	prensa : false,
  	radio : false,
  	television : false,
  	web : false,
  	redes_sociales : false
  }


  const [show, setShow] = useState<boolean>(false);
  const [showEdit, setEditShow] = useState<boolean>(false);
  const [medio_seleccionada, setMedio] = useState<any>({...MDSkull});

  const seleccionarMedio = (medio:any)=>{
    // seleccionarMedioCB(medio)
    setMedio({...medio})
    setShow(true)
  }

  const abrirEditarMedioModal = (medio:any)=>{
    var media = {...medio}
    media.representante = media.representante ? media.representante : ""
    media.telefono = media.telefono ? media.telefono : ""
    media.celular = media.celular ? media.celular : ""
    media.direccion = media.direccion ? media.direccion : ""    
    setMedio(media)
    setEditShow(true)
  }


  const actualizarPrensa = (value:boolean)  => setMedio({ ...medio_seleccionada, prensa: value })
  const actualizarRadio = (value:boolean)  => setMedio({ ...medio_seleccionada, radio: value })
  const actualizarTV = (value:boolean)  => setMedio({ ...medio_seleccionada, television: value })
  const actualizarWeb = (value:boolean)  => setMedio({ ...medio_seleccionada, web: value })
  const actualizarRedes = (value:boolean)  => setMedio({ ...medio_seleccionada, redes_sociales: value })



  const actualizarCampoNombre = (e:any) => setMedio({ ...medio_seleccionada, nombre: e.target.value.toUpperCase() })
  const actualizarCampoDetalle = (e:any) => setMedio({ ...medio_seleccionada, detalles: e.target.value.toUpperCase() })
  // const actualizarCampoTipoMedio = (value:number) =>{ 
  //   setMedio({ ...medio_seleccionada, idtipo_medio: value })
  // }
  // const actualizarCampoRedes = (value:number) =>{ 
  //   setMedio({ ...medio_seleccionada, idred_social: value })
  // }
  const actualizarCampoAlcance = (e:any) => setMedio({ ...medio_seleccionada, alcance: e.target.value })

  const actualizarCampoRepresentante = (e:any) => setMedio({ ...medio_seleccionada, representante: e.target.value.toUpperCase() })
  const actualizarCampoTelefono = (e:any) => setMedio({ ...medio_seleccionada, telefono: e.target.value })
  const actualizarCampoCelular = (e:any) => setMedio({ ...medio_seleccionada, celular: e.target.value })
  const actualizarCampoDireccion = (e:any) => setMedio({ ...medio_seleccionada, direccion: e.target.value.toUpperCase() })

  const actualizarCampoCredibilidad = (value:number)  => setMedio({ ...medio_seleccionada, credibilidad: value })
  const actualizarCampoImportancia = (value:number)  => setMedio({ ...medio_seleccionada, importancia: value })

  // const actualizarCampoRepresentante = (e:any) => setNuevoMedioProp({ ...nuevo_medio, representante: e.target.value.toUpperCase() })
  // const actualizarCampoTelefono = (e:any) => setNuevoMedioProp({ ...nuevo_medio, telefono: e.target.value })
  // const actualizarCampoCelular = (e:any) => setNuevoMedioProp({ ...nuevo_medio, celular: e.target.value })

  const actualizarMedio = async () => {
    try{

            if (await confirm({
              confirmation: 'Estas seguro?'
            })) {
              // window.location.reload()
            
                var medio = {...medio_seleccionada}
                delete medio.id
                delete medio.uuid
                delete medio.logo
                delete medio.logo_150x150
                delete medio.logo_300x600
                await RequestService.update('medio/update/'+medio_seleccionada.uuid,medio)
                setEditShow(false)
                toast('Se actualizo correctamente')
                someMethod()

              }


      // window.location.reload();
    }
    catch(e){
        var ERROR : any = JSON.parse(JSON.stringify(e))
        if(ERROR.status === 522 ) toast.error("No puedes duplicar el nombre del Medio")
        else toast.error(JSON.stringify(e))
      // toast('Error al guarda la informacion '+ JSON.stringify(e))
    }
  }



  const [ showDialogEditLogo, editShowDialogEdit ] = useState(false)
  const [ hash, setHash ] = useState( [ ...Array(4) ].map(() => (~~(Math.random() * 36)).toString(36)).join('') )

  const abrirCambiarLogo = (medio:any) => {
    editLogoA('')
    editLogoB('')
    editLogoC('')
    setHash( [ ...Array(4) ].map(() => (~~(Math.random() * 36)).toString(36)).join('') )
    setMedio({...medio})
    editShowDialogEdit(true)
  }



  const [logo, editLogoA ] = useState('')
  const [logo_150x150, editLogoB ] = useState('')
  const [logo_300x600, editLogoC ] = useState('')


  const handleFileLogoA = e => {
    if(e.target.files.length>0){
      var files = e.target.files
        const reader = new FileReader()
            reader.onloadend = () => {
              if(reader.result){
              editLogoA( reader.result+'' )
            }
        }
        reader.readAsDataURL(files[0])
    }
  };



  const handleFileLogoB = e => {
    if(e.target.files.length>0){
      var files = e.target.files
        const reader = new FileReader()
            reader.onloadend = () => {
              if(reader.result){
              editLogoB(reader.result+'')
            }
        }
        reader.readAsDataURL(files[0])
    }
  };



  const handleFileLogoC = e => {
    if(e.target.files.length>0){
      var files = e.target.files
        const reader = new FileReader()
            reader.onloadend = () => {
              if(reader.result){
              editLogoC(reader.result + "" )
            }
        }
        reader.readAsDataURL(files[0])
    }
  };




  const cambiarLogoMedio = async (cual:number) => {
    try{
      if(cual===1) await RequestService.update('medio/logo/update/'+medio_seleccionada.uuid,{ logo : logo.split(',')[1]})
      if(cual===2) await RequestService.update('medio/logo/update/'+medio_seleccionada.uuid,{ logo_150x150 : logo_150x150.split(',')[1]})
      if(cual===3) await RequestService.update('medio/logo/update/'+medio_seleccionada.uuid,{ logo_300x600 : logo_300x600.split(',')[1]})
      	setHash( [ ...Array(4) ].map(() => (~~(Math.random() * 36)).toString(36)).join('') )
      toast('Estamos bien, tu logo se cambio')
    }
    catch(e){
      toast(JSON.stringify(e))
    }
  }





  const habilitaBoton = () => {

    var deshabilitado : boolean = true
    if(
        medio_seleccionada.nombre.length > 0
        &&
        medio_seleccionada.detalles.length > 0
        &&
        medio_seleccionada.alcance !== ""
        &&
      ( medio_seleccionada.prensa || medio_seleccionada.radio || medio_seleccionada.television || medio_seleccionada.web || medio_seleccionada.redes_sociales )
      ) deshabilitado = false
    return deshabilitado

  }







  return  (


        <>

        { medios.length === 0 && (

            <>
              <p><i className="fa fa-warning" /> No tenemos resultados de Medios Informativos para esta búsqueda</p>
            </>

          )}
        { medios.length > 0 && (

            <>



            <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th scope="col">Medio Informativo</th>
                <th scope="col">Alcance</th>
                <th className="chiquito">Prensa</th>
                <th className="chiquito">Radio</th>
                <th className="chiquito">TV</th>
                <th className="chiquito">Web</th>
                <th className="chiquito">Redes</th>

                <th className="chiquito"></th>
                <th className="chiquito"></th>
              </tr>
            </thead>
            <tbody>

                {medios.map(
                  (medio : any) => 

                      <tr key={ Math.random().toString(36).substr(2, 9) } className="table-active">
                        <th className="v" style={{"width":'55px'}}>
                            <img className="bd-placeholder-img flex-shrink-0 me-2 rounded cursor" src={ SERVER_ + 'medio/logo/square/'+medio.uuid+'/'+hash} alt={medio.nombre} width="30px" onClick={()=>{abrirCambiarLogo(medio)}} />
                        </th>
                        <td className="v"> 
                            <p  className="nm">{medio.nombre}</p>
                        </td>
                        <th className="v" scope="row" style={{"width":'150px'}}>{medio.alcance}</th>
                        <td className="v">
                          { medio.prensa && ( <i className="fa fa-check" /> )}
                        </td>
                        <td className="v">
                          { medio.radio && ( <i className="fa fa-check" /> )}
                        </td>
                        <td className="v">
                          { medio.television && ( <i className="fa fa-check" /> )}
                        </td>
                        <td className="v">
                          { medio.web && ( <i className="fa fa-check" /> )}
                        </td>
                        <td className="v">
                          { medio.redes_sociales && ( <i className="fa fa-check" /> )}
                        </td>
                        <td className="v text-center" style={{"width":'50px'}}>
                          <button className="btn btn-link" onClick={()=>{ abrirEditarMedioModal(medio) }}><small><i className="fa fa-edit"/></small></button>
                        </td>
                        <td className="v text-center" style={{"width":'50px'}}>
                          <button className="btn btn-link" onClick={()=>{ seleccionarMedio(medio) }}><small><i className="fa fa-window-maximize"/></small></button>
                        </td>
                      </tr>

                  )}

              </tbody>
            </table>

            </>

          )}


              <Modal
                backdrop="static"
                size="sm"
                show={show}
                onHide={() => setShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4 className="nm">Medio de Comunicación</h4>
                    <h6 className="nm">{medio_seleccionada.uuid}</h6>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  
                        

                              <p className=" text-center">
                                <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={ SERVER_ + 'medio/logo/square/'+medio_seleccionada.uuid+'/'+medio_seleccionada.uuid} alt={medio_seleccionada.nombre} width="50%" />
                              </p>

                          <div className="card" style={{'margin':0}}>
                            <div className="card-body" style={{'paddingBottom': 0 }}>
                              <h5 className="card-title">{medio_seleccionada.nombre}</h5>
                              <h6 className="card-subtitle text-muted">{medio_seleccionada.uuid}</h6>
                              <small>{medio_seleccionada.detalles}</small>
                            </div>
                            <div className="card-body">
                              <p className="card-text">{medio_seleccionada.direccion}</p>
                            </div>
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item"><i className="fa fa-phone" /> {medio_seleccionada.telefono}</li>
                              <li className="list-group-item"><i className="fa fa-mobile" /> {medio_seleccionada.celular}</li>
                              <li className="list-group-item"><b>ALCANCE: </b>{medio_seleccionada.alcance}</li>
                              <li className="list-group-item"><b>IMPORTANCIA: </b><ReactStars
                                      count={5}
                                      value={medio_seleccionada.importancia}
                                      size={20}
                                      edit={false}
                                      activeColor="#ffd700"
                                    /></li>
                              <li className="list-group-item"><b>CREDIBILIDAD: </b><ReactStars
                                      count={5}
                                      value={medio_seleccionada.credibilidad}
                                      size={20}
                                      edit={false}
                                      activeColor="#ffd700"
                                    /></li>
                            </ul>
                          </div>

                  


                </Modal.Body>
              </Modal>




                <Modal
                    backdrop="static"
                    size="lg"
                    show={showDialogEditLogo}
                    onHide={()=>{editShowDialogEdit(false)}}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Cambiar logos del Medio {medio_seleccionada.uuid}</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>

                              <h5 className="card-title">{medio_seleccionada.nombre}</h5>
                              <h6 className="card-subtitle text-muted">{medio_seleccionada.uuid}</h6>
                              <small>{medio_seleccionada.detalles}</small>

                      <div className="row mt-2">
                        <div className="col-md-4">

                        
		                          <p className=" text-center">
		                            <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={ SERVER_ + 'medio/logo/original/'+medio_seleccionada.uuid+'/'+hash} alt={medio_seleccionada.nombre} width="100%" />
		                          </p>


                                  <div className="form-group">
                                    <label htmlFor="logo" className="form-label">Logo Original</label>
                                    <input id="logo" type="file" name="file" className="form-control" accept="image/jpeg,image/jpg,image/png" multiple={false} onChange={handleFileLogoA} />
                                  </div>

                                 {logo !== ""  && (
                                   <>
                                    <div className="mt-2">
                                     <p>
                                       <img src={logo} className="rounded float-left img-thumbnail" alt="..." width="100%" />
                                     </p>
                                    </div>
                                    <button type="button" onClick={()=>{cambiarLogoMedio(1)}} className="btn btn-primary"><small>Cambiar</small></button>
                                   </>


                                  )}
                            

                          </div>
                        <div className="col-md-3">


		                          <p className=" text-center">
		                            <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={ SERVER_ + 'medio/logo/square/'+medio_seleccionada.uuid+'/'+hash} alt={medio_seleccionada.nombre} width="100%" />
		                          </p>


                                  <div className="form-group">
                                    <label htmlFor="logo" className="form-label">Logo 150x150</label>
                                    <input id="logo" type="file" name="file" className="form-control" accept="image/jpeg,image/jpg,image/png" multiple={false} onChange={handleFileLogoB} />
                                  </div>

                                 {logo_150x150 !== ""  && (
                                   <>
                                    <div className="mt-2">
                                     <p>
                                       <img src={logo_150x150} className="rounded float-left img-thumbnail" alt="..." width="100%" />
                                     </p>
                                    </div>
                                    <button type="button" onClick={()=>{cambiarLogoMedio(2)}} className="btn btn-primary"><small>Cambiar</small></button>
                                   </>

                                  )}

                          </div>
                        <div className="col-md-5">


		                          <p className=" text-center">
		                            <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={ SERVER_ + 'medio/logo/big/'+medio_seleccionada.uuid+'/'+hash} alt={medio_seleccionada.nombre} width="100%" />
		                          </p>


                                  <div className="form-group">
                                    <label htmlFor="logo" className="form-label">Logo 300x600</label>
                                    <input id="logo" type="file" name="file" className="form-control" accept="image/jpeg,image/jpg,image/png" multiple={false} onChange={handleFileLogoC} />
                                  </div>

                                 {logo_300x600 !== ""  && (
                                   <>
                                    <div className="mt-2">
                                     <p>
                                       <img src={logo_300x600} className="rounded float-left img-thumbnail" alt="..." width="100%" />
                                     </p>
                                    </div>
                                    <button type="button" onClick={()=>{cambiarLogoMedio(3)}} className="btn btn-primary"><small>Cambiar</small></button>
                                   </>

                                  )}


                          </div>
                       </div>

                  </Modal.Body>

                </Modal>




              <Modal
                backdrop="static"
                size="lg"
                show={showEdit}
                onHide={() => setEditShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4 className="nm">Editar Medio de Comunicación</h4>
                    <h6 className="nm">{medio_seleccionada.uuid}</h6>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  
                        

                          <p className=" text-center">
                            <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={ SERVER_ + 'medio/logo/square/'+medio_seleccionada.uuid+'/'+hash} alt={medio_seleccionada.nombre} width="10%" />
                          </p>


                        <div className="mb-3">
                          <label className="form-label" htmlFor="inputDefault">Nombre del Medio <i className="fa fa-asterisk obligatorios" /> </label>
                          <input type="text" className="form-control" id="inputDefault" onChange={actualizarCampoNombre} value={medio_seleccionada.nombre} />
                        </div>



                      <div className="row mt-2">
                        <div className="col-md-2">

                            <div className="mb-3">
                              <label className="form-label">Importancia</label>
                              <ReactStars
                                  count={5}
                                  value={medio_seleccionada.importancia}
                                  onChange={actualizarCampoImportancia}
                                  size={20}
                                  edit={true}
                                  activeColor="#ffd700"
                                />
                            </div>

                        </div>
                        <div className="col-md-2">

                            <div className="mb-3">
                              <label className="form-label">Credibilidad</label>
                              <ReactStars
                                  count={5}
                                  value={medio_seleccionada.credibilidad}
                                  onChange={actualizarCampoCredibilidad}
                                  size={20}
                                  edit={true}
                                  activeColor="#ffd700"
                                />
                            </div>

                        </div>
                      </div>


                      <div className="mb-3">
                          <label className="form-label d-block">Procedencia de las noticias <i className="fa fa-asterisk obligatorios" /> </label>
                          <div className="form-check form-check-inline">
                              <input className="form-check-input" id="prensa" type="checkbox" name="procedenciaDeLasNoticias" checked={medio_seleccionada.prensa} onChange={(event)=>{actualizarPrensa(!medio_seleccionada.prensa)}} />
                              <label className="form-check-label" htmlFor="prensa">PRENSA</label>
                          </div>
                          <div className="form-check form-check-inline">
                              <input className="form-check-input" id="radio" type="checkbox" name="procedenciaDeLasNoticias" checked={medio_seleccionada.radio} onChange={(event)=>{actualizarRadio(!medio_seleccionada.radio)}} />
                              <label className="form-check-label" htmlFor="radio">RADIO</label>
                          </div>
                          <div className="form-check form-check-inline">
                              <input className="form-check-input" id="television" type="checkbox" name="procedenciaDeLasNoticias" checked={medio_seleccionada.television} onChange={(event)=>{actualizarTV(!medio_seleccionada.television)}} />
                              <label className="form-check-label" htmlFor="television">TELEVISIÓN</label>
                          </div>
                          <div className="form-check form-check-inline">
                              <input className="form-check-input" id="web" type="checkbox" name="procedenciaDeLasNoticias" checked={medio_seleccionada.web} onChange={(event)=>{actualizarWeb(!medio_seleccionada.web)}} />
                              <label className="form-check-label" htmlFor="web">WEB</label>
                          </div>
                          <div className="form-check form-check-inline">
                              <input className="form-check-input" id="redesSociales" type="checkbox" name="procedenciaDeLasNoticias" checked={medio_seleccionada.redes_sociales} onChange={(event)=>{actualizarRedes(!medio_seleccionada.redes_sociales)}} />
                              <label className="form-check-label" htmlFor="redesSociales">REDES SOCIALES</label>
                          </div>
                      </div>



                  <div className="form-group mt-2">
                    <label htmlFor="exampleTextarea" className="form-label">Detalle <i className="fa fa-asterisk obligatorios" /></label>
                    <textarea className="form-control" id="exampleTextarea" onChange={actualizarCampoDetalle} value={medio_seleccionada.detalles} rows={3}></textarea>
                  </div>

                  <div className="form-group mt-2">
                      <label className="form-label d-block">Alcance <i className="fa fa-asterisk obligatorios" /></label>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" id="local" type="radio" name="alcance" checked={medio_seleccionada.alcance==="LOCAL"} onChange={actualizarCampoAlcance} value="LOCAL" />
                          <label className="form-check-label" htmlFor="local">LOCAL</label>
                      </div>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" id="estatal" type="radio" name="alcance" checked={medio_seleccionada.alcance==="ESTATAL"} onChange={actualizarCampoAlcance} value="ESTATAL" />
                          <label className="form-check-label" htmlFor="estatal">ESTATAL</label>
                      </div>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" id="nacional" type="radio" name="alcance" checked={medio_seleccionada.alcance==="NACIONAL"} onChange={actualizarCampoAlcance} value="NACIONAL" />
                          <label className="form-check-label" htmlFor="nacional">NACIONAL</label>
                      </div>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" id="internacional" type="radio" name="alcance" checked={medio_seleccionada.alcance==="INTERNACIONAL"} onChange={actualizarCampoAlcance} value="INTERNACIONAL" />
                          <label className="form-check-label" htmlFor="internacional">INTERNACIONAL</label>
                      </div>
                  </div>
                  

                  <div className="row mt-2">
                    <div className="col-md-6">

                      <div className="form-group">
                        <label className="form-label" htmlFor="representante">Representante del Medio</label>
                        <input type="text" className="form-control" id="representante" onChange={actualizarCampoRepresentante} value={medio_seleccionada.representante} />
                      </div>

                    </div>
                    <div className="col-md-3">

                      <div className="form-group">
                        <label className="form-label" htmlFor="telefono">Teléfono</label>
                        <input type="text" className="form-control" id="telefono" onChange={actualizarCampoTelefono} value={medio_seleccionada.telefono} />
                      </div>

                    </div>
                    <div className="col-md-3">

                      <div className="form-group">
                        <label className="form-label" htmlFor="celular">Celular</label>
                        <input type="text" className="form-control" id="celular" onChange={actualizarCampoCelular} value={medio_seleccionada.celular} />
                      </div>

                    </div>
                  </div>


                  <div className="form-group mt-2">
                    <label className="form-label" htmlFor="representante">Dirección</label>
                    <input type="text" className="form-control" id="representante" onChange={actualizarCampoDireccion} value={medio_seleccionada.direccion} />
                  </div>



                </Modal.Body>

                <Modal.Footer>

                      <button type="button" onClick={actualizarMedio} disabled={habilitaBoton()} className="btn btn-success">Guardar Cambios</button>
                      <button type="button" className="btn btn-danger" onClick={() => setEditShow(false)} >Cancelar</button>
                  
                </Modal.Footer>
              </Modal>


        </>



    )


}


export default TablaMedios

