// import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import AccessDenied from './pages/AccessDenied'
// import { ROLE } from './features/auth/auth'
// import { selectCurrentUser, selectIsAuthenticated } from './features/auth/authSlice'
import AuthService from './features/auth/auth.service'

import { ROLE } from './features/auth/role'



interface Props {
  component: React.ComponentType
  path?: string
  roles: Array<ROLE>
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent, roles }) => {
  // const user = useSelector(selectCurrentUser)
  const user = AuthService.getCurrentUser()

  // roles = Array.from(roles)
  var userHasRequiredRole = false

  var ROLES : any = []
  for (var i = 0; i < roles.length; ++i) {
    // console.log("i:....->",roles[i])
    ROLES.push(roles[i])
    if(roles[i] === user.rol){
         // console.log('PUTA ! SI HAY CHAVO ')
         userHasRequiredRole = true
    }
  }

  // console.log("user.role#####", user.role )
  // console.log("ROLES-*****", ROLES, roles )
  // console.log("ROLES---->",typeof ROLES)
  // console.log("(ROLES.indexOf(user.role) !== -1)",(ROLES.indexOf(user.role) !== -1))
  // // const isAuthenticated = useSelector(selectIsAuthenticated)
  const isAuthenticated = AuthService.selectIsAuthenticated
  // const userHasRequiredRole = roles.includes(user.role)

  // const userHasRequiredRole_ = (ROLES.indexOf(user.role) !== -1)

  // console.log("userHasRequiredRole",userHasRequiredRole)

  if (isAuthenticated && userHasRequiredRole) {
    return <RouteComponent />
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <AccessDenied />
  }

  return <Navigate to="/" />
}
