import React, { useState, Fragment } from "react";
import { toast } from 'react-toastify'
import moment from 'moment'

// import { useNavigate } from "react-router"


import './nueva-noticia.scss';

// import { SERVER_ } from  '../../../config';


import RequestService from "../../../services/request.service";

import { Form, Button } from 'react-bootstrap'


import { confirm } from '../../../widgets/confirm'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


// import { AsyncTypeahead } from 'react-bootstrap-typeahead'; // ES2015

// import 'react-bootstrap-typeahead/css/Typeahead.css';



import { WithContext as ReactTags } from 'react-tag-input';



const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];




function EditarReplica(noticia_:any) {

    // let navigate = useNavigate();


    const [ noticia, updateNoticia ] = useState({...noticia_.noticia, fake_new : noticia_.noticia.fake_new })

    // const [url, setUrl] = useState('')


    // const [red_social, setRedSocial] = useState( false )

    // const [medios_tipo, setMedios_Tipo] = useState([])

    // const [medios_informativos, setMedios_informativos] = useState([])

    const [tipos_noticias, setTipos_noticias] = useState([])

    // const [isLoadingSearchMedio, setIsLoadingSearchMedio] = useState(false)

    const [municipios, setMunicipios ] = useState([])


    const changeTendencia = (value: number) => {
        updateNoticia({ ...noticia, idtendencia: value })
    }

    const changeAlcance = (value: string) => {
        updateNoticia({ ...noticia, alcance: value })
    }

    const handleChangeTitulo = (event: any) => {
        // setTitulo((event.target.value).toUpperCase());
        updateNoticia({ ...noticia, titulo: event.target.value })
    }

    const onChangeDetalle = (event: any) => {
        updateNoticia({ ...noticia, detalle: event.target.value })
        // setTages( e.detail.tagify.getCleanValue() )
        // setDetalle( e.target.value.toUpperCase() )
    }

    const handleChangeTipoNoticia = (event: any) => {
        // setTipo_noticia(event.target.value*1);
    }


    const handleChangeMunicipio = (event:any) => { 
      updateNoticia({ ...noticia, idmunicipio: event.target.value*1 })
    }


     //  const changeUrl = (event:any) => {
 //    setUrl(event.target.value)
 //  }

  // const actualizarCampoRedes = (value:number) =>{ 
  //   updateNoticia({ ...noticia, idred_social: value })
  // }



  const onChangeDate = (date:any) => {

        updateNoticia({ ...noticia, fecha_replica: date })

    // console.log(date)
    // setFecha(date)
  }




  const cargarTipoNoticia = async (idtipo_medio:number) =>{
    try{
        let reqs = await RequestService.getEndPoint('noticias/nueva/tipo_noticia/'+idtipo_medio)
        setTipos_noticias( reqs.data )
    } 
    catch(e){
      toast('Error cargarTipoNoticia')
    } 
  }

  const cargarMunicipios = async () =>{
    try{
        let reqs = await RequestService.getEndPoint('municipios')
        // console.log('jajahah-->',reqs)
        // this.setState((tipos_noticias, props) => ({ tipos_noticias: reqs.data }));
        setMunicipios( reqs.data )
    } 
    catch(e){
      //alert('Error cargarTipoNoticia')
    } 
  }






  const guardarReplica =  async (event:any) =>{
      try{

	    event.preventDefault();

        if (await confirm({
          confirmation: 'Estas seguro?'
        })) {


              var SEND = {...noticia} 

              SEND.tags = tags

              if(SEND.uuid_noticia) delete SEND.uuid_noticia
              if(SEND.detalle_replica) delete SEND.detalle_replica
              if(SEND.fecha_creacion) delete SEND.fecha_creacion
              if(SEND.idusuario) delete SEND.idusuario
              if(SEND.multimedia) delete SEND.multimedia
              if(SEND.reacciones) delete SEND.reacciones
              if(SEND.red_social) delete SEND.red_social
              // delete SEND.tags
              if(SEND.idmunicipio === 0) delete SEND.idmunicipio

              SEND.fecha_replica = moment(noticia.fecha_replica).format('YYYY-MM-DD HH:mm:ss')
                  // console.log(SEND)
               await RequestService.update('editar-replica/'+noticia.uuid_noticia, { data : SEND } )
        	     toast.success('Los cambios en la noticia correctamente')
        	      //   // if(false) 
               //      navigate('/noticias/replicas')
        	     return;

        }   
         

      }
      catch(e){
      	toast('Errror')
      }

  }





  React.useEffect(() => {

    updateNoticia({...noticia, idtipo_medio : noticia.idtipo_medio })

    cargarMunicipios()

    cargarEtiquetas()

    cargarTipoNoticia(noticia.idtipo_medio)


  }, []);

    // const filterBy = () => true;




  const disableSendButton = () => {
    var isDisabled : boolean = true
    // console.log(noticia)
    if(
        (noticia.idtipo_medio > 0)
        &&
        (noticia.idtipo_noticia > 0)
        &&
        (noticia.idtendencia > 0)
        &&
        (noticia.idmedio > 0)
        &&
        (noticia.titulo.trim().length > 0)
        &&
        (noticia.detalle.trim().length > 0)
        &&
        (tags.length > 0)
      ) isDisabled = false;
      // if(noticia.idtipo_medio === 5){
      //   if(url.trim().length > 0) isDisabled = false;
      //   else isDisabled = true
      // }
    return isDisabled
  }







  const [tags, setTags] = useState<any[]>(
    [...noticia.tags].map((obj:any)=>{ return { id : obj.idetiqueta.etiqueta, text : obj.idetiqueta.etiqueta } })
    );

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const [suggestions, setSuggestions] = useState([]);

  const cargarEtiquetas = async () => {
    let res = await RequestService.getEndPoint('etiquetas/all')
    setSuggestions(res.data)
  }






  return (
  		<>
        		
            

              <form>

              {
                // JSON.stringify(noticia)
              }


                              <div key={`fake_new`} className="mb-3">
                              {
                                noticia.fake_new && (
                                    <React.Fragment>

                                      <div className="alert alert-primary" role="alert">
                                        <i className="fa fa-warning" /> FAKE NEW
                                      </div>
                                        
                                    </React.Fragment>
                                 )
                              }
                                
                                <Form.Check 
                                  onChange={(e)=>{updateNoticia({ ...noticia, fake_new: e.target.checked })}}
                                  type={'checkbox'}
                                  id="fake_new"
                                  checked={noticia.fake_new}
                                  label="Esta noticia no es real"
                                />
                              </div>




                            <div className="mb-3">
                                <label className="form-label">Fecha de la Noticia</label>

                              <DatePicker
                                dateFormat="yyyy-MM-dd HH:mm"
                                selected={noticia.fecha_replica}
                                onChange={onChangeDate}
                                showTimeSelect
                              />

                            </div>

                            <div className="mb-3">
                                <label className="form-label" htmlFor="tipoNoticia">Municipios</label>
                                <select className="form-select" id="tipoNoticia" value={noticia.idmunicipio} onChange={handleChangeMunicipio} >
                                    
                                  {municipios.map( (reg:any) =>
                                      <option disabled={reg.id===0} key={ Math.random().toString(36).substr(2, 9) }  value={reg.id}>{reg.municipio}</option>
                                  )}
                                </select>
                            </div>




                        <div className="mb-3">
                            <label className="form-label" htmlFor="tipoNoticia">Tipo de Noticia</label>
                            <select className="form-select" id="tipoNoticia" value={noticia.idtipo_noticia} onChange={handleChangeTipoNoticia} >
                                
                              {tipos_noticias.map( (reg:any) =>
                                  <option disabled={reg.id===0} key={ Math.random().toString(36).substr(2, 9) } value={reg.id}>{reg.tipo_noticia}</option>
                              )}
                            </select>
                        </div>






                            <div className="mb-3">
                                <label className="form-label" htmlFor="titularDeLaNoticia">Titular de la noticia</label>
                                <input className="form-control" id="titularDeLaNoticia" type="text" value={noticia.titulo} onChange={handleChangeTitulo} />
                                <div className="invalid-feedback">Titular de la noticia is required.</div>
                            </div>


                             <div className="mb-3">
                                 <label className="form-label" htmlFor="sintesisDeLaNoticia">Síntesis de la noticia</label>
                                 <textarea className="form-control" id="sintesisDeLaNoticia" style={{'height': '10rem'}} value={noticia.detalle} onChange={onChangeDetalle} ></textarea>
                             </div>



                            <div className="mb-3 mt-2">
                                <label className="form-label d-block">Tendencia o Impacto</label>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="positiva" type="radio" name="tendenciaOImpacto" value="1" checked={noticia.idtendencia === 1} onChange={(e)=>{changeTendencia(1)}} />
                                    <label className="form-check-label" htmlFor="positiva">Positiva</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="negativa" type="radio" name="tendenciaOImpacto" value="2" checked={noticia.idtendencia === 2} onChange={(e)=>{changeTendencia(2)}} />
                                    <label className="form-check-label" htmlFor="negativa">Negativa</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="neutral" type="radio" name="tendenciaOImpacto" value="3" checked={noticia.idtendencia === 3} onChange={(e)=>{changeTendencia(3)}} />
                                    <label className="form-check-label" htmlFor="neutral">Neutral</label>
                                </div>
                            </div>

                            <div className="mb-3 mt-2">
                                <label className="form-label d-block">Alcance</label>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="local" type="radio" name="alcance" value="LOCAL" checked={noticia.alcance === "LOCAL"} onChange={()=>{changeAlcance("LOCAL")}} />
                                    <label className="form-check-label" htmlFor="local">Local</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="estatal" type="radio" name="alcance" value="ESTATAL" checked={noticia.alcance === "ESTATAL"} onChange={()=>{changeAlcance("ESTATAL")}} />
                                    <label className="form-check-label" htmlFor="estatal">Estatal</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="nacional" type="radio" name="alcance" value="NACIONAL" checked={noticia.alcance === "NACIONAL"} onChange={()=>{changeAlcance("NACIONAL")}} />
                                    <label className="form-check-label" htmlFor="nacional">Nacional</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="internacional" type="radio" name="alcance" value="INTERNACIONAL" checked={noticia.alcance === "INTERNACIONAL"} onChange={()=>{changeAlcance("INTERNACIONAL")}} />
                                    <label className="form-check-label" htmlFor="internacional">Internacional</label>
                                </div>
                            </div>


                            <div className="mb-3">
                                <label className="form-label" htmlFor="titularDeLaNoticia">Etiquetas relacionadas</label>

                                <ReactTags
                                placeholder="Presiona ENTER para agregar una nueva etiqueta"
                                  tags={tags}
                                  classNames={{tagInputField: 'form-control'}}
                                  suggestions={suggestions}
                                  delimiters={delimiters}
                                  handleDelete={handleDelete}
                                  handleAddition={handleAddition}
                                  inputFieldPosition="top"
                                  
                                />
                                { tags.length === 0 && (
                                      <small className="form-text text-muted">Debes agrear por lo menos una etiqueta de clasificación para la noticia</small>
                                )}

                            </div>


                              <div className="row">
                                <div className="col-md-12 mt-2">
                                    {
                                       <Button variant="success" style={{'width':'100%'}} onClick={guardarReplica} disabled={disableSendButton()}>GUARDAR</Button> 
                                    }


                                </div>
                                {
                                // <div className="col-md-3 mt-2">
                                //        <Button variant="danger" style={{'width':'100%'}} ><i className="fa fa-trash" /> </Button>
                                // </div>
                                }
                              </div>








              </form>



  		</>
  	)


}



export default EditarReplica
