import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { Modal, Button } from 'react-bootstrap'

import { toast } from 'react-toastify'


import { confirm } from '../../widgets/confirm'


import './Usuarios.scss';


// import moment from 'moment'


// import { SERVER_ } from  '../../config';


const Usuarios = () =>{


  var [ cargando, setCargando ] = useState( true )
  var [ usuarios, setUsuarios ] = useState([])


  const [ modal_nuevo_usuario, setModal ] = useState(false)

const userSkull = {
                    username : "",
                    // username : (Math.random() + 1).toString(36).substring(7),
                    celular : "",
                    password : "",
                    nombres : "",
                    paterno : "",
                    materno : "",
                    sexo : "H",
                    rol : ""
                  }
const [nuevo_usuario, setValuesNuevoUsuario] = useState({...userSkull});



  const actualizarDatosNuevoUsuarioUsername = (e:any) => setValuesNuevoUsuario({ ...nuevo_usuario, username: e.target.value })
  const actualizarDatosNuevoUsuarioCelular = (e:any) => setValuesNuevoUsuario({ ...nuevo_usuario, celular: e.target.value })
  const actualizarDatosNuevoUsuarioPassword = (e:any) => setValuesNuevoUsuario({ ...nuevo_usuario, password: e.target.value })
  const actualizarDatosNuevoUsuarioNombres = (e:any) => setValuesNuevoUsuario({ ...nuevo_usuario, nombres: e.target.value.toUpperCase() })
  const actualizarDatosNuevoUsuarioPaterno = (e:any) => setValuesNuevoUsuario({ ...nuevo_usuario, paterno: e.target.value.toUpperCase() })
  const actualizarDatosNuevoUsuarioMaterno = (e:any) => setValuesNuevoUsuario({ ...nuevo_usuario, materno: e.target.value.toUpperCase() })
// const actualizarDatosNuevoUsuarioSexo = (e:any) => setValuesNuevoUsuario({ ...nuevo_usuario, sexo: e.target.value })


  const cerraFormulario = async () => {

      if (await confirm({ confirmation: '¿Estás seguro? Perderas la información' })) {

        setValuesNuevoUsuario({...userSkull})
        setValuesNuevoUsuario({...nuevo_usuario, username : (Math.random() + 1).toString(36).substring(7) })
        setModal(false)

      } 


  }

  const crearUsuario = async () =>{
    
    try{

      if (await confirm({
        confirmation: '¿Estás seguro?'
      })) {

        console.log(nuevo_usuario)

        let usuario = nuevo_usuario
          await RequestService.create('nuevo-usuario', usuario )
          toast('Su usuario fue agregado correctamente')
          cargarloBaby()
          setModal(false)

      } 


    }
    catch(e){
      console.log('error al crear el usuario')
      toast('error al crear el usuario')
    }



  }




  const cargarloBaby = async ()=>{
    setCargando(true)
    let resp = await RequestService.getEndPoint('usuarios' )
    setUsuarios(resp.data.results)
    setCargando(false)
  }

  const changeEstatus = (index:number, estatus:boolean)  => {
    let newArr : any = [...usuarios];
    newArr[index].activo = estatus;
    setUsuarios(newArr);
  }

  

  React.useEffect(() => {
    cargarloBaby()
  }, []);


  const abrirModal = (value:boolean) => {
    setModal(value)
  }


  const onSiteChanged = (e:any)=>{
    
    setValuesNuevoUsuario({ ...nuevo_usuario, rol: e.currentTarget.value })
  }



      return (



          <>
              <div className="container">
                  

                  <h2>Usuarios</h2>

                <ul className="nav nav-pills">
                  <li className="nav-item">
                    <button className="nav-link" onClick={()=>abrirModal(true)}>Nuevo Usuario</button>
                  </li>
                </ul>

                <hr />


                  {
                    cargando && (


                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                      )
                  }

                  {
                    !cargando && (


                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">UUID</th>
                        <th scope="col">Rol</th>
                        <th scope="col">Usuario</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Activo</th>
                      </tr>
                    </thead>
                    <tbody>

                        {usuarios.map(
                          (usuario : any, index : number) => 

                              <tr key={ index } className="table-active">
                                <th scope="row">{usuario.uuid}</th>
                                <td>{usuario.rol}</td>
                                <td>{usuario.username}</td>
                                <td>{usuario.nombres} {usuario.paterno} {usuario.materno}</td>
                                <td className="text-center">
                                  
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" 
                                    value={usuario.activo} 
                                    onChange={(event) =>{ changeEstatus(index,!usuario.activo) }}
                                    defaultChecked={usuario.activo} 
                                    id={'flexCheckDefault'+index} 
                                    style={{'float': 'none'}} 
                                    />
                                    <label className="form-check-label" htmlFor={'flexCheckDefault'+index} ></label>
                                  </div>

                                </td>
                              </tr>

                          )}

                    </tbody>
                  </table>

                      )
                  }

              </div>




<Modal
show={modal_nuevo_usuario}
onHide={() => abrirModal(false)}
>
  <Modal.Header closeButton>
    <Modal.Title>Usuario Nuevo</Modal.Title>
  </Modal.Header>

  <Modal.Body>

  <h3>Formulario de Nuevo Usuario</h3>

  <h6 className="nm">Usuario</h6>


      <div className="mb-3">
        <label htmlFor="username" className="form-label"> Nombre de Usuario</label>
        <input type="text" className="form-control" id="username" onChange={actualizarDatosNuevoUsuarioUsername} value={nuevo_usuario.username} />
      </div>

      <div className="mb-3">
        <label htmlFor="password" className="form-label"> Contraseña</label>
        <input type="password" className="form-control" id="password" onChange={actualizarDatosNuevoUsuarioPassword} value={nuevo_usuario.password} />
      </div>


      <label className="form-label"> Privilegios</label>
      <div className="form-check">
        <label className="form-check-label">
          <input type="radio" className="form-check-input" name="rol" id="optionsRadios1" value="ROLE_ADMIN" onChange={onSiteChanged} />
          ADMIN
        </label>
      </div>
      <div className="form-check">
        <label className="form-check-label">
          <input type="radio" className="form-check-input" name="rol" id="optionsRadios2" value="ROLE_CAPTURE" onChange={onSiteChanged} />
          CAPTURA
        </label>
      </div>
      <div className="form-check disabled">
        <label className="form-check-label">
          <input type="radio" className="form-check-input" name="rol" id="optionsRadios3" value="ROLE_ANALYST" onChange={onSiteChanged} />
          ANALISTA
        </label>
      </div>
      <div className="form-check disabled">
        <label className="form-check-label">
          <input type="radio" className="form-check-input" name="rol" id="optionsRadios3" value="ROLE_VIEW" onChange={onSiteChanged} />
          VISOR
        </label>
      </div>


<br />

  <h6 className="nm">Datos Personales</h6>

      <div className="mb-3">
        <label htmlFor="nombres" className="form-label"> Nombre(s)</label>
        <input type="text" className="form-control" id="nombres" onChange={actualizarDatosNuevoUsuarioNombres} value={nuevo_usuario.nombres} />
      </div>

      <div className="mb-3">
        <label htmlFor="paterno" className="form-label"> Apellido Paterno</label>
        <input type="text" className="form-control" id="paterno" onChange={actualizarDatosNuevoUsuarioPaterno} value={nuevo_usuario.paterno} />
      </div>

      <div className="mb-3">
        <label htmlFor="materno" className="form-label"> Apellido Materno</label>
        <input type="text" className="form-control" id="materno" onChange={actualizarDatosNuevoUsuarioMaterno} value={nuevo_usuario.materno} />
      </div>

      <div className="mb-3">
        <label htmlFor="celular" className="form-label"> Celular</label>
        <input type="text" className="form-control" id="celular" onChange={actualizarDatosNuevoUsuarioCelular} value={nuevo_usuario.celular} />
      </div>


  </Modal.Body>

  <Modal.Footer>
    <Button onClick={()=>{cerraFormulario()}} variant="secondary">Close</Button>
    <Button onClick={()=>{crearUsuario()}} variant="primary">Crear Cuenta</Button>
  </Modal.Footer>
</Modal>


          </>

      );
  

}

export default Usuarios
