import React, { useState } from "react";

import { toast } from 'react-toastify'

import RequestService from "../../services/request.service";

import './Medios.scss';

import ReactStars from "react-rating-stars-component";

import Modal from 'react-bootstrap/Modal'

// import { SERVER_ } from  '../../config';

import { confirm } from '../../widgets/confirm'




import  TablaMedios  from './TablaMedios'




const Medios = () => {


    // const [ show , setShow ] = useState( false,)
    // const [ medio_seleccionada , setMedio_seleccionada ] = useState( {})
    const [ showNew , setShowNew ] = useState( false )
    const [ medios , setMedios ] = useState( [] )
    const [ texto_busqueda , setTexto_busqueda ] = useState( "" )


    const [ enviando_medios, setEnviandoMedios ] = useState(false)


    const nuevo_medioSkull = {
      idtipo_medio : 0,
      nombre : "",
      detalles : "",
      alcance : "",
      representante : "",
      telefono : "",
      celular : "",
      direccion : "",
      importancia : 0,
      credibilidad : 0,
      idred_social : 0,
      logo : "", 
      logo_150x150 : "",
      logo_300x600 : "",

      prensa : false,
      radio : false,
      television : false,
      web : false,
      redes_sociales : false,

    }


    const [nuevo_medio, setNuevoMedioProp ] = useState({...nuevo_medioSkull});

    const cerrarFormularioNuevo = async () => {
        
        if (await confirm({
          confirmation: 'Estas seguro?'
        })) {
          setNuevoMedioProp({...nuevo_medioSkull})
        }

    }


    const actualizarCampoNombre = (e:any) => setNuevoMedioProp({ ...nuevo_medio, nombre: e.target.value.toUpperCase() })
    const actualizarCampoDetalle = (e:any) => setNuevoMedioProp({ ...nuevo_medio, detalles: e.target.value.toUpperCase() })
    // const actualizarCampoTipoMedio = (e:any) => setNuevoMedioProp({ ...nuevo_medio, idtipo_medio: e.target.value*1 })
    const actualizarCampoAlcance = (e:any) => setNuevoMedioProp({ ...nuevo_medio, alcance: e.target.value })

    const actualizarCampoRepresentante = (e:any) => setNuevoMedioProp({ ...nuevo_medio, representante: e.target.value.toUpperCase() })
    const actualizarCampoTelefono = (e:any) => setNuevoMedioProp({ ...nuevo_medio, telefono: e.target.value })
    const actualizarCampoCelular = (e:any) => setNuevoMedioProp({ ...nuevo_medio, celular: e.target.value })

    // const actualizarCampoRedes = (value:number) => setNuevoMedioProp({ ...nuevo_medio, idred_social: value })

    const actualizarCampoDireccion = (e:any) => setNuevoMedioProp({ ...nuevo_medio, direccion: e.target.value.toUpperCase() })

    const actualizarCampoCredibilidad = (value:number)  => setNuevoMedioProp({ ...nuevo_medio, credibilidad: value })
    const actualizarCampoImportancia = (value:number)  => setNuevoMedioProp({ ...nuevo_medio, importancia: value })


    // const actualizarLogoA = (value:string)  => setNuevoMedioProp({ ...nuevo_medio, logo: value })
    // const actualizarLogoB = (value:string)  => setNuevoMedioProp({ ...nuevo_medio, logo_150x150: value })
    // const actualizarLogoC = (value:string)  => setNuevoMedioProp({ ...nuevo_medio, logo_300x600: value })


    const actualizarPrensa = (value:boolean)  => setNuevoMedioProp({ ...nuevo_medio, prensa: value })
    const actualizarRadio = (value:boolean)  => setNuevoMedioProp({ ...nuevo_medio, radio: value })
    const actualizarTV = (value:boolean)  => setNuevoMedioProp({ ...nuevo_medio, television: value })
    const actualizarWeb = (value:boolean)  => setNuevoMedioProp({ ...nuevo_medio, web: value })
    const actualizarRedes = (value:boolean)  => setNuevoMedioProp({ ...nuevo_medio, redes_sociales: value })



  const buscarMedio =  async () =>{

    try{
        let reqs = await RequestService.getEndPoint('medios/filter?search='+texto_busqueda)
        // console.log(reqs.data)
        // medios = reqs
        // setState({ medios : reqs.data })
        setMedios(reqs.data)

    }
    catch(e){
      toast.error(JSON.stringify(e))
    }

  }

  const changeTextBusqueda = (event:any)=>{
    // console.log(event.target.value)
    // setState({ texto_busqueda : event.target.value})
    setTexto_busqueda(event.target.value)
    // setState({ texto_busqueda : event.target.value })
    // if(texto_busqueda.length>2){
      // buscarMedio()
    // }
  }


  React.useEffect(() => {
    buscarMedio()
    // const [nuevo_medio, setNuevoMedioProp ] = useState({...nuevo_medioSkull});
    // setNuevoMedioProp({...nuevo_medioSkull})
  },[]); // eslint-disable-line 





  const setShowNewsP = (state : boolean)=>{
    setShowNew(state)
  }


  const agregarMedio = async () => {
        if (await confirm({
          confirmation: 'Estas seguro?'
        })) {
          try{
              // console.log(nuevo_medio)
              setEnviandoMedios(true)
              await RequestService.create('nuevo-medio', nuevo_medio )
              toast.success('El Medio se dio de alta correctamente')
              // window.location.reload();
              setEnviandoMedios(false)
              setShowNew(false)
              buscarMedio()
          }
          catch(e:any){
            // console.log(JSON.parse(JSON.stringify(e)))
            var ERROR : any = JSON.parse(JSON.stringify(e))
            if(ERROR.status === 522 ) toast.error("No puedes duplicar el nombre del Medio")
            else toast.error(JSON.stringify(e))
            setEnviandoMedios(false)
          }
        }
  }



  const handleFileLogoA = e => {
    if(e.target.files.length>0){
      var files = e.target.files
        const reader = new FileReader()
            reader.onloadend = () => {
              if(reader.result){
              setNuevoMedioProp({ ...nuevo_medio, logo: reader.result + "" })
            }
        }
        reader.readAsDataURL(files[0])
    }
  };



  const handleFileLogoB = e => {
    if(e.target.files.length>0){
      var files = e.target.files
        const reader = new FileReader()
            reader.onloadend = () => {
              if(reader.result){
              setNuevoMedioProp({ ...nuevo_medio, logo_150x150: reader.result + "" })
            }
        }
        reader.readAsDataURL(files[0])
    }
  };



  const handleFileLogoC = e => {
    if(e.target.files.length>0){
      var files = e.target.files
        const reader = new FileReader()
            reader.onloadend = () => {
              if(reader.result){
              setNuevoMedioProp({ ...nuevo_medio, logo_300x600: reader.result + "" })
            }
        }
        reader.readAsDataURL(files[0])
    }
  };



  const habilitaBoton = () => {

    var deshabilitado : boolean = true
    if(
        !enviando_medios
        &&
        nuevo_medio.nombre.length > 0
        &&
        nuevo_medio.detalles.length > 0
        &&
        nuevo_medio.alcance !== ""
        &&
      ( nuevo_medio.prensa || nuevo_medio.radio || nuevo_medio.television || nuevo_medio.web || nuevo_medio.redes_sociales )
      ) deshabilitado = false
    return deshabilitado

  }









    return (
        <>
            <div className="container">
            <h2>Medios Informativos</h2>


              <div className="row">
                <div className="col-md-4">
                  
                    <div className="form-group">
                      <label className="col-form-label col-form-label-lg" htmlFor="busquedaMedios">Búsqueda de Medios</label>
                      <input className="form-control form-control-lg" type="text" id="busquedaMedios" value={texto_busqueda} onChange={changeTextBusqueda} />
                    </div>

                    <button type="button" onClick={()=>{buscarMedio()}} className="btn btn-primary mt-4">Búscar</button>


                    <hr />

                    <ul className="nav nav-pills flex-column">
                      <li className="nav-item">
                        <button className="nav-link" onClick={()=>setShowNewsP(true)}>Agregar Medio</button>
                      </li>
                    </ul>


                </div>
                <div className="col-md-8">

                  <h5>Resultados de la búsqueda de Medios Informativos</h5>

                  <hr />


                  <TablaMedios medios={medios} someMethod={buscarMedio}  />


                  
                </div>
              </div>



            </div>



              <Modal
                backdrop="static"
                size="lg"
                show={showNew}
                onHide={() => setShowNewsP(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4 className="nm">Agregar Medio de Comunicación</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                  
                        
                    <div className="mb-3">
                      <label className="form-label" htmlFor="inputDefault">Nombre del Medio <i className="fa fa-asterisk obligatorios" /> </label>
                      <input type="text" className="form-control" id="inputDefault" onChange={actualizarCampoNombre} value={nuevo_medio.nombre} />
                    </div>







                      <div className="mb-3">
                          <label className="form-label d-block">Procedencia de las noticias <i className="fa fa-asterisk obligatorios" /> </label>
                          <div className="form-check form-check-inline">
                              <input className="form-check-input" id="prensa" type="checkbox" name="procedenciaDeLasNoticias" checked={nuevo_medio.prensa} onChange={(event)=>{actualizarPrensa(!nuevo_medio.prensa)}} />
                              <label className="form-check-label" htmlFor="prensa">PRENSA</label>
                          </div>
                          <div className="form-check form-check-inline">
                              <input className="form-check-input" id="radio" type="checkbox" name="procedenciaDeLasNoticias" checked={nuevo_medio.radio} onChange={(event)=>{actualizarRadio(!nuevo_medio.radio)}} />
                              <label className="form-check-label" htmlFor="radio">RADIO</label>
                          </div>
                          <div className="form-check form-check-inline">
                              <input className="form-check-input" id="television" type="checkbox" name="procedenciaDeLasNoticias" checked={nuevo_medio.television} onChange={(event)=>{actualizarTV(!nuevo_medio.television)}} />
                              <label className="form-check-label" htmlFor="television">TELEVISIÓN</label>
                          </div>
                          <div className="form-check form-check-inline">
                              <input className="form-check-input" id="web" type="checkbox" name="procedenciaDeLasNoticias" checked={nuevo_medio.web} onChange={(event)=>{actualizarWeb(!nuevo_medio.web)}} />
                              <label className="form-check-label" htmlFor="web">WEB</label>
                          </div>
                          <div className="form-check form-check-inline">
                              <input className="form-check-input" id="redesSociales" type="checkbox" name="procedenciaDeLasNoticias" checked={nuevo_medio.redes_sociales} onChange={(event)=>{actualizarRedes(!nuevo_medio.redes_sociales)}} />
                              <label className="form-check-label" htmlFor="redesSociales">REDES SOCIALES</label>
                          </div>
                      </div>



                  <div className="mb-3">
                    <label htmlFor="exampleTextarea" className="form-label">Detalle <i className="fa fa-asterisk obligatorios" /></label>
                    <textarea className="form-control" id="exampleTextarea" onChange={actualizarCampoDetalle} value={nuevo_medio.detalles} rows={3}></textarea>
                  </div>


                  <div className="mb-3">
                      <label className="form-label d-block">Alcance <i className="fa fa-asterisk obligatorios" /> </label>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" id="local" type="radio" name="alcance" onChange={actualizarCampoAlcance} value="LOCAL" />
                          <label className="form-check-label" htmlFor="local">LOCAL</label>
                      </div>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" id="estatal" type="radio" name="alcance" onChange={actualizarCampoAlcance} value="ESTATAL" />
                          <label className="form-check-label" htmlFor="estatal">ESTATAL</label>
                      </div>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" id="nacional" type="radio" name="alcance" onChange={actualizarCampoAlcance} value="NACIONAL" />
                          <label className="form-check-label" htmlFor="nacional">NACIONAL</label>
                      </div>
                      <div className="form-check form-check-inline">
                          <input className="form-check-input" id="internacional" type="radio" name="alcance" onChange={actualizarCampoAlcance} value="INTERNACIONAL" />
                          <label className="form-check-label" htmlFor="internacional">INTERNACIONAL</label>
                      </div>
                  </div>


                  <div className="row mt-2">
                    <div className="col-md-2">

                        <div className="form-group">
                          <label className="form-label">Importancia</label>
                          <ReactStars
                              count={5}
                              value={nuevo_medio.importancia}
                              onChange={actualizarCampoImportancia}
                              size={20}
                              edit={true}
                              activeColor="#ffd700"
                            />
                        </div>

                    </div>
                    <div className="col-md-2">

                        <div className="form-group">
                          <label className="form-label">Credibilidad</label>
                          <ReactStars
                              count={5}
                              value={nuevo_medio.credibilidad}
                              onChange={actualizarCampoCredibilidad}
                              size={20}
                              edit={true}
                              activeColor="#ffd700"
                            />
                        </div>

                    </div>
                  </div>




                  <div className="row mt-2">
                    <div className="col-md-6">

                      <div className="form-group">
                        <label className="form-label" htmlFor="representante">Representante del Medio</label>
                        <input type="text" className="form-control" id="representante" onChange={actualizarCampoRepresentante} value={nuevo_medio.representante} />
                      </div>

                    </div>
                    <div className="col-md-3">

                      <div className="form-group">
                        <label className="form-label" htmlFor="telefono">Teléfono</label>
                        <input type="text" className="form-control" id="telefono" onChange={actualizarCampoTelefono} value={nuevo_medio.telefono} />
                      </div>

                    </div>
                    <div className="col-md-3">

                      <div className="form-group">
                        <label className="form-label" htmlFor="celular">Celular</label>
                        <input type="text" className="form-control" id="celular" onChange={actualizarCampoCelular} value={nuevo_medio.celular} />
                      </div>

                    </div>
                  </div>


                  <div className="form-group mt-2">
                    <label className="form-label" htmlFor="representante">Dirección</label>
                    <input type="text" className="form-control" id="representante" onChange={actualizarCampoDireccion} value={nuevo_medio.direccion} />
                  </div>








                  <div className="row mt-2">
                    <div className="col-md-4">

                              <div className="form-group">
                                <label htmlFor="logo" className="form-label">Logo Original</label>
                                <input id="logo" type="file" name="file" className="form-control" accept="image/jpeg,image/jpg,image/png" multiple={false} onChange={handleFileLogoA} />
                              </div>

                             {nuevo_medio.logo !== ""  && (
                                <div className="mt-2">
                                 <p>
                                   <img src={nuevo_medio.logo} className="rounded float-left" alt="..." width="100%" />
                                 </p>
                                </div>

                              )}
                        

                      </div>
                    <div className="col-md-4">

                              <div className="form-group">
                                <label htmlFor="logo" className="form-label">Logo 150x150</label>
                                <input id="logo" type="file" name="file" className="form-control" accept="image/jpeg,image/jpg,image/png" multiple={false} onChange={handleFileLogoB} />
                              </div>

                             {nuevo_medio.logo_150x150 !== ""  && (
                                <div className="mt-2">
                                 <p>
                                   <img src={nuevo_medio.logo_150x150} className="rounded float-left" alt="..." width="100%" />
                                 </p>
                                </div>

                              )}

                      </div>
                    <div className="col-md-4">


                              <div className="form-group">
                                <label htmlFor="logo" className="form-label">Logo 300x600</label>
                                <input id="logo" type="file" name="file" className="form-control" accept="image/jpeg,image/jpg,image/png" multiple={false} onChange={handleFileLogoC} />
                              </div>

                             {nuevo_medio.logo_300x600 !== ""  && (
                                <div className="mt-2">
                                 <p>
                                   <img src={nuevo_medio.logo_300x600} className="rounded float-left" alt="..." width="100%" />
                                 </p>
                                </div>

                              )}


                      </div>
                   </div>


                </Modal.Body>

                <Modal.Footer>



                      <button type="button" onClick={agregarMedio} disabled={habilitaBoton()} className="btn btn-success">Crear Medio Informativo</button>
                      
                        <button type="button" className="btn btn-danger" onClick={()=>{cerrarFormularioNuevo()}} >Cerrar</button>
                      
                  
                </Modal.Footer>



              </Modal>



















        </>

    );
  
}

export default Medios
