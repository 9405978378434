import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'


import { Link } from 'react-router-dom'

import './Replicas.scss';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import { Modal, Tabs, Tab, Form, Button } from 'react-bootstrap'


import moment from 'moment'

import { SERVER_ } from  '../../config';


import EditarReplica from './NuevaNoticia/editar_replica_noticia'


// import Button from 'react-bootstrap/Button'

import Dialog from '../../services/Dialogs'

// import { confirm } from '../../widgets/confirm'



// import AuthService from '../../features/auth/auth.service'


import ModalEdicionMultimedia from './ModalEdicionMultimedia'
import ModalAuditoriaEdiciones from './modales/ModalAuditoriaEdiciones'
import ModalEnviarRectificacion from './modales/ModalEnviarRectificacion'
import ModalNoticiaCaratula from './modales/ModalNoticiaCaratula'



import Pagination from "../../services/Pagination";

const Analistas = () => {


  const dialog = new Dialog();


  registerLocale('es', es)


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);


  const [ resultados_busqueda, setResultados ] = useState<any>([])
  const [ cargando_busqueda_noticia, setCargandoBusquedaNoticia ] = useState( false )
  const [ texto_busqueda_noticia, setTextoBusqueda ] = useState('')


  const tipeandoBusqueda = (event)=>{
    setTextoBusqueda(event.target.value)
  }


const [ fake_new, setFakeNew ] = useState<number>(2)

  const busquedaNoticias = async (page:number) =>{
    try{
      setCargandoBusquedaNoticia(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let resultados = await RequestService.getEndPoint('analistas/busqueda_noticia/'+texto_busqueda_noticia+'?skip='+skip+'&leida='+(ver_leidas)+'&estatus='+JSON.stringify(busquedaEstatus)+'&fake_new='+ fake_new)
      setResultados(resultados.data.results)
      setCargandoBusquedaNoticia(false)

      // setEtiquetas(resultados.data.results)
      // setTotalResulados(resultados.data.totalCount)
      setCounter(resultados.data.totalCount)
      // setPaginas(resultados.data.numberPages)
      // setCargandoNoticias(false)


    }
    catch(e){
      setCargandoBusquedaNoticia(false)
      toast.error('Tuvimos problemas procesando su busqueda')
    }
  }


  const notiSkulls = [  {
                      uuid_medio: "",
                      nombre: "",
                      detalles: "",
                      total_noticias: 0,
                      total_prensa_noticias: 0,
                      total_radio_noticias: 0,
                      total_television_noticias: 0,
                      total_web_noticias: 0,
                      total_redes_noticias: 0,
                    }]

  const [ cargando_noticia, setCargandoNoticia ] = useState( true )
  const [ noticias, setNoticias ] = useState([...notiSkulls ])

  var [fecha,setFecha] = useState<any>( moment().format('YYYY-MM-DD') )

  const onChang = (date:any)=>{
    // let DATE = (moment(date).format('YYYY-MM-DD')) //.toString()

    setFecha( moment(date).format('YYYY-MM-DD') )
    cargarNoticias( moment(date).format('YYYY-MM-DD') )
  }


  const cargarNoticias = async ( fecha  )=>{
    try{
        setCargandoNoticia(true)
        let response = await RequestService.getEndPoint('reportes/analistas/' + moment(fecha).format('YYYY-MM-DD') )
        if(response.data){
          setNoticias(response.data)
          setCargandoNoticia(false)
        }
        else{
          toast.error('Error al cargar las noticias')
        }
    }
    catch(e){
      toast.error('Error al cargar las noticias')
    }
  }

  

  React.useEffect(() => {
    cargarNoticias( moment().format('YYYY-MM-DD') )
    busquedaNoticias(0)
    setCurrentPage(1)
  }, []);

  const startDate = new Date()



  // const cargarNoticiasMetodo = () =>{
  //   cargarNoticias( fecha )
  // }


  const volverPublica = (index,uuid,publicar)=>{
    try{
      // ({... ,noticia.publicar})
      var arreglo = [...resultados_busqueda]
      arreglo[index].publicar = publicar
      setResultados(arreglo)
      RequestService.update('publicar-noticia/'+uuid, { publicar : publicar })
      if(publicar) toast.success('La noticia ahora es visible para los ejecutivos')
        else toast.warning('La noticia no aparecera para el ejecutivo')
    }
    catch(e){
      toast.error('Problemas actualizando registro')
    }
  }






  const skullNoticia = {
      uuid_noticia: "",
      uuid_medio: "",
      fecha_replica: "",
      titulo: "",
      detalle: "",
      fecha_creacion: "",
      // idtipo_noticia: 0,
      idmedio_red_social: 0,
      // {
      tipo_noticia: "",
      // },
      idmedio: {
          uuid: "",
          nombre: "",
          detalles: "",
          alcance: "",
          importancia: 0,
          credibilidad: 0,
          idtipo_medio: 0,
      },
      idtipo_noticia : {
        tipo_noticia : ""
      },
      idred_social: 0,
      // },
      idtendencia: {
          id: 0,
          tendencia: ""
      },
      // 
      //   "id" : 0,
      tendencia: "",
      nombre_medio: "",
      // },

      reacciones: {
          url: "",
          facebook_me_gusta: 0,
          facebook_me_encanta: 0,
          facebook_me_importa: 0,
          facebook_me_divierte: 0,
          facebook_me_sorprende: 0,
          facebook_me_entristece: 0,
          facebook_me_enoja: 0,
          facebook_comentarios: 0,
          facebook_compartidos: 0,
          twitter_respuestas: 0,
          twitter_me_gusta: 0,
          twitter_retweet: 0,
          youtube_comentarios: 0,
          youtube_reproducciones: 0,
          youtube_me_gusta: 0,
          instagram_me_gusta: 0,
          instagram_comentarios: 0,
          idreplica: 0
      },
      red_social: "",
      tags : [],
      multimedia : []
  }


  const [ noticia, setNoticiaSeleccion ] = useState({...skullNoticia})


  // const [ noticia, setNoticiaSeleccion ] = useState({...skullNoticia})

  const seleccionaNoticiaModal = (noticia:any)=>{
    setNoticiaSeleccion(noticia)
    setShow(true)
    // console.log(noticia)
  }



  const [showEdit, setShowEdit] = useState(false);

  const seleccionaNoticiaModalEditar = (noticia:any)=>{
    var noti = {...noticia}
    delete noti.idnoticia
    noti.fecha_replica = moment(noti.fecha_replica).toDate();
    // noti.idred_social = noti.idred_social.id
    // if(noti.idred_social) noti.idred_social =noti.idred_social
    // if(noti.idmunicipio) noti.idmunicipio = noti.idmunicipio; else noti.idmunicipio = 0;

    noti.idmedio_uuid = noti.uuid_medio
    noti.medio = noti.nombre_medio
    noti.idmedio = parseInt(noti.idmedio)
    noti.idtendencia = parseInt(noti.idtendencia)
    noti.idtipo_noticia = parseInt(noti.idtipo_noticia)
    noti.idtipo_medio = parseInt(noti.idtipo_medio)

    
    setNoticiaSeleccion(noti)
    setShowEdit(true)
    // console.log(noticia)
  }


  const CerrarModalEdicion = async ()=>{
    setCargandoBusquedaNoticia(true)
    // let response = await RequestService.getEndPoint('reportes/analistas/' + cargarNoticias( fecha ) )
    busquedaNoticias(currentPage===0?0:currentPage-1)

    setCargandoBusquedaNoticia(false)
    // setResultados(response.data)
    setShowEdit(false)
  }



  const [ ver_leidas, setVerLeidas] = useState(0);

  const [show, setShow] = useState(false);


  const CerrarModal = ()=>{
    setNoticiaSeleccion({...skullNoticia})
    setShow(false)
  }



const handleTest = async (e) => { if (e.charCode === 13) { await busquedaNoticias(0); setCurrentPage(1); } if (e.keyCode === 13) { await busquedaNoticias(0); setCurrentPage(1); } }



const [ uuid_noticia_seleccionada, setUUIDNoticiaSeleccionada ] = useState<string>('')
const [showModalEdicionMultimedia, setShowModalEdicionMultimedia ] = useState<boolean>(false)
const abrirModalEdicionMultimedia = (uuid_noticia:string)=>{
  setUUIDNoticiaSeleccionada(uuid_noticia)
  setShowModalEdicionMultimedia(true)
}
const handleCloseModalEdicionMultimedia = ()=>{
  setShowModalEdicionMultimedia(false)
}



const [showModalAuditoriaEdiciones, setShowModalAuditoriaEdiciones ] = useState<boolean>(false)
const abrirModalAuditoriaEdiciones = (uuid_noticia:string)=>{
  setUUIDNoticiaSeleccionada(uuid_noticia)
  setShowModalAuditoriaEdiciones(true)
}
const handleCloseModalAuditoriaEdiciones = ()=>{
  setShowModalAuditoriaEdiciones(false)
}




  const [ busquedaEstatus, setBusquedaEstatus ] = useState<any>([false,true,false,true])


  const [showModalRectifica, setShowModalRectifica] = useState(false);

  const handleCloseRectificaModal = () =>{
    setShowModalRectifica(false)
    busquedaNoticias(currentPage===0?0:currentPage-1)
  }
  const handleShowEnviarRectificacion = async (uuid_noticia:string) =>{ 
    setUUIDNoticiaSeleccionada(uuid_noticia)
    setShowModalRectifica(true)
    // await cargarComentarios(uuid_tramite)
  }



// <ModalNoticiaCaratula uuid_noticia={uuid_noticia_seleccionada} show={showModalCaratula} handleClose={} />

  const [showModalCaratula, setShowModalCaratula] = useState(false);

  const handleCloseCaratulaModal = () =>{
    setShowModalCaratula(false)
    // busquedaNoticias(currentPage===0?0:currentPage-1)
  }
  const handleShowCaratula = async (uuid_noticia:string) =>{ 
    setUUIDNoticiaSeleccionada(uuid_noticia)
    setShowModalCaratula(true)
    // await cargarComentarios(uuid_tramite)
  }





  const MarcarAceptado = async(uuid_noticia:any)=>{
    // console.log(noticia)
    try{
        if(await dialog.confirm('Estas seguro?',{})){
          let res = await RequestService.update('noticia/aceptada', {uuid_noticia : uuid_noticia })
          if(res.data){
            toast.success('La noticia se acepto, ahora puede ser publicada en el timeline')
            busquedaNoticias(currentPage===0?0:currentPage-1)
          }
          else toast.error('Problemas al mandar a verificación')
        }
    }
    catch(e){
      toast.error('Error al mandar a verificación')
    }
  }



    // render() {

      return (
          <>
              <div className="container">
                  
                  <h2>Análisis Informativo</h2>

                  <div className="row">
                    <div className="col-md-12">


                          <Tabs defaultActiveKey="edicion" id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="analisis" title="Análisis Diario">


<div className="row">
  <div className="col-md-3">
    
        <h5>Fecha de filtro</h5>


        <div className="text-center">
          <DatePicker
            onChange={(date)=>{ onChang(date) }}
            startDate={startDate}
            locale="es"
            inline
          />
        </div>

        <hr />

        <ul className="nav nav-pills flex-column">
          <li className="nav-item">
            <Link className="nav-link" to="/noticia/replica/nueva"> Nueva Nota Informativa </Link>
            <a className="nav-link" rel="noreferrer" target="_blank" href={ SERVER_ + 'replicas/reporte/'+fecha}> Reporte de Replicas </a>
            <a className="nav-link" rel="noreferrer" target="_blank" href={ SERVER_ + 'reportes/analistas/descarga/'+fecha}> Descarga Reporte </a>
          </li>
        </ul>


  </div>
  <div className="col-md-9">
    

       <h6>Análisis para el día {fecha}</h6>


        <hr />


        {  (!cargando_noticia && noticias.length > 0 ) && (



          <table className="table table-hover">
            <thead style={{'fontSize':10}}>
              <tr>
                <th scope="col"></th>
                <th scope="col">Medio</th>
                <th scope="col">Total</th>
                <th scope="col">Prensa</th>
                <th scope="col">Radio</th>
                <th scope="col">T.V.</th>
                <th scope="col">Web</th>
                <th scope="col">Redes</th>
              </tr>
            </thead>
            <tbody>
            {
              noticias.map( medio =>

                          <tr key={ Math.random().toString(36).substr(2, 9) } >
                            <th className="v" style={{"width":'55px'}}>
                                <img className="bd-placeholder-img flex-shrink-0 me-2 rounded  " src={ SERVER_ + 'medio/logo/square/'+medio.uuid_medio+'/'+medio.uuid_medio} alt={medio.nombre} width="30px"  />
                            </th>
                            <td>{medio.nombre.toUpperCase()}</td>
                            <td style={{'width':'50px'}}>{medio.total_noticias}</td>
                            <td style={{'width':'50px'}}>{medio.total_prensa_noticias}</td>
                            <td style={{'width':'50px'}}>{medio.total_radio_noticias}</td>
                            <td style={{'width':'50px'}}>{medio.total_television_noticias}</td>
                            <td style={{'width':'50px'}}>{medio.total_web_noticias}</td>
                            <td style={{'width':'50px'}}>{medio.total_redes_noticias}</td>
                          </tr>

               )
            }

            </tbody>
          </table>
           // JSON.stringify(noticias)

         )}

        {  cargando_noticia && (

            <>
              <div className="loader">
                <div className="loaderBar"></div>
              </div>
            </>


         )}

        { noticias.length === 0 && !cargando_noticia && (


            <div className="alert alert-dismissible alert-warning">
              <h4 className="alert-heading">...</h4>
              <p className="mb-0">No tenemos noticias para este día</p>
            </div>


         )}


  </div>
</div>





                                </Tab>
                                <Tab eventKey="edicion" title="Búsqueda y Edición">


                                          <div className="mb-3">
                                            <label className="form-label" htmlFor="busqueda">Búsqueda</label>
                                            <div className="input-group">
                                                <input className="form-control" id="busqueda" type="text" placeholder="Búsqueda" onKeyPress={handleTest} value={texto_busqueda_noticia} onChange={tipeandoBusqueda} />
                                                <button className="input-group-text" onClick={()=>{busquedaNoticias(0);setCurrentPage(1);}}><i className="fa fa-search" /></button>
                                            </div>
                                          </div>


                                            <div className="mb-3">
                                              <Form.Check
                                                inline
                                                checked={busquedaEstatus[0]}
                                                onChange={(e)=>{ var BE = [...busquedaEstatus]; BE[0] = e.target.checked; setBusquedaEstatus(BE) }}
                                                label="En Captura"
                                                name="captura"
                                                type="checkbox"
                                                id="captura"
                                              />
                                              <Form.Check
                                                inline
                                                checked={busquedaEstatus[1]}
                                                onChange={(e)=>{ var BE = [...busquedaEstatus]; BE[1] = e.target.checked; setBusquedaEstatus(BE) }}
                                                label="En Verificación"
                                                name="verificar"
                                                type="checkbox"
                                                id="verificar"
                                              />
                                              <Form.Check
                                                inline
                                                checked={busquedaEstatus[2]}
                                                onChange={(e)=>{ var BE = [...busquedaEstatus]; BE[2] = e.target.checked; setBusquedaEstatus(BE) }}
                                                label="Para Rectificar"
                                                name="rectificar"
                                                type="checkbox"
                                                id="rectificar"
                                              />
                                              <Form.Check
                                                inline
                                                checked={busquedaEstatus[3]}
                                                onChange={(e)=>{ var BE = [...busquedaEstatus]; BE[3] = e.target.checked; setBusquedaEstatus(BE) }}
                                                label="Aceptadas"
                                                name="aceptadas"
                                                type="checkbox"
                                                id="aceptadas"
                                              />


                                              <Form.Check
                                                inline
                                                checked={fake_new==1}
                                                onChange={(e)=>{ setFakeNew(1) }}
                                                label="Fake News"
                                                name="fake_new"
                                                type="radio"
                                                id="fake_news"
                                              />

                                              <Form.Check
                                                inline
                                                checked={fake_new==0}
                                                onChange={(e)=>{ setFakeNew(0) }}
                                                label="Noticas Reales"
                                                name="fake_new"
                                                type="radio"
                                                id="noticias_reales"
                                              />

                                              <Form.Check
                                                inline
                                                checked={fake_new==2}
                                                onChange={(e)=>{ setFakeNew(2) }}
                                                label="Todas las noticias"
                                                name="fake_new"
                                                type="radio"
                                                id="todas_noticias"
                                              />



                                            </div>






                                          {  (!cargando_busqueda_noticia && resultados_busqueda.length>0) && (

                                              <>


                                                {
                                                  // JSON.stringify(resultados_busqueda)
                                                }

                                                <table className="table table-striped table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col"></th>
                                                      <th scope="col"></th>
                                                      <th scope="col">Noticia</th>
                                                      <th scope="col">Fecha Replica</th>
                                                      <th scope="col"></th>
                                                      <th scope="col"></th>
                                                      <th scope="col"></th>
                                                      <th scope="col"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                  {
                                                    resultados_busqueda.map( (noticia : any,i:number) =>

                                                          <tr key={i}>
                                                            <td className="v text-center" style={{'width':'50px'}}> 
                                                            <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={ SERVER_ + 'medio/logo/square/'+noticia.uuid_medio+'/'+noticia.uuid_medio} alt={noticia.nombre} width="40px"  />
                                                            </td>
                                                                <td className="v text-center" style={{width:140}}>
                                                                  <b>{ noticia.estatus }</b>
                                                                  <br/>
                                                                  <small>{noticia.usuario.nombres}</small>
                                                                </td>
                                                            <td >
                                                              <b style={{'cursor':'pointer'}} onClick={()=>{handleShowCaratula(noticia.uuid_noticia)}}><small>{noticia.titulo}</small></b>
                                                              {
                                                                // <b style={{'cursor':'pointer'}} onClick={()=>{seleccionaNoticiaModal(noticia)}}><small>{noticia.titulo}</small></b>
                                                              }
                                                              <p className="sammy-nowrap-2- nm">
                                                                <small>{noticia.detalle}</small>
                                                              </p>
                                                              {
                                                                // JSON.stringify(noticia.leida)
                                                              }

                                                              {
                                                                noticia.tags.map(
                                                                  (etiqueta :any, indice : number) =>
                                                                            <span key={ Math.random().toString(36).substr(2, 9) }><span className="badge bg-danger"><small>{etiqueta.idetiqueta.etiqueta}</small></span> &nbsp;</span>
                                                                  )
                                                              }

                                                            </td>
                                                            <td className="v text-center" style={{'width':'150px'}}>
                                                                <p className="nm"><small>{noticia.fecha_replica}</small></p>
                                                                
                                                                {
                                                                  noticia.cambios.length > 0 &&
                                                                    <small className="cursor" onClick={()=>{abrirModalAuditoriaEdiciones(noticia.uuid_noticia)}}>Editada</small>
                                                                }
                                                            </td>
                                                            <td className="v text-center" style={{'width':'30px'}}>
                                                                  {
                                                                    noticia.idestatus === 2 && (
                                                                          <React.Fragment>
                                                                            <button type="button" onClick={()=>{handleShowEnviarRectificacion(noticia.uuid_noticia)}} className="btn btn-xs btn-link"><i className="fa fa-gavel"/></button>
                                                                            <button type="button" onClick={()=>{MarcarAceptado(noticia.uuid_noticia)}} className="btn btn-xs btn-link"><i className="fa fa-check-circle"/></button>
                                                                          </React.Fragment>
                                                                      )
                                                                  }
                                                                  
                                                                  {
                                                                    // noticia.leida && <i className="fa fa-envelope-open-o" />
                                                                  }
                                                                  {
                                                                    // !noticia.leida && <i onClick={()=>{marcarComoLeida(noticia.uuid_noticia,i)}} className="fa cursor fa-envelope" />
                                                                  }
                                                                  {
                                                                    // JSON.stringify(noticia)
                                                                  }
                                                            </td>
                                                            <td className="v text-center" style={{'width':'30px'}}>
                                                                  {
                                                                    // <i onClick={()=>{abrirModalEdicionMultimedia(noticia.uuid_noticia)}} className="fa cursor fa-image" />
                                                                  }

                                                                  {
                                                                    noticia.fake_new && (
                                                                        <span className="badge bg-danger">
                                                                          <i className="fa fa-warning" /> Fake New
                                                                        </span>
                                                                    )
                                                                  }

                                                                  
                                                            </td>
                                                            <td className="v text-center" style={{'width':'30px'}}>
                                                            {
                                                              noticia.idestatus === 4 &&
                                                            
                                                                  <div className="form-check">
                                                                      <input className="form-check-input" style={{"marginLeft":0}} id="si" type="checkbox" checked={noticia.publicar} onChange={(event:any)=>{volverPublica(i,noticia.uuid_noticia,event.target.checked)}} />
                                                                  </div>
                                                            }

                                                            </td>
                                                            <td className="v" style={{'width':'30px'}}>
                                                              {
                                                                noticia.idestatus !== 1 &&
                                                              <button type="button" onClick={()=>{seleccionaNoticiaModalEditar(noticia)}} className="btn btn-xs btn-link"><i className="fa fa-edit"/></button>
                                                              }

                                                            </td>
                                                          </tr> 

                                                      )
                                                  }

                                                  </tbody>
                                                </table>



                                                <Pagination
                                                  itemsCount={allSessionsCount}
                                                  itemsPerPage={sessionsPerPage}
                                                  currentPage={currentPage}
                                                  setCurrentPage={setCurrentPage}
                                                  cambiarPaginaEvento={busquedaNoticias}
                                                  alwaysShown={false}
                                                />



                                              </>


                                           )}


                                          {  cargando_busqueda_noticia && (

                                              <>
                                                <div className="loader">
                                                  <div className="loaderBar"></div>
                                                </div>
                                              </>


                                           )}

                                          { resultados_busqueda.length === 0 && !cargando_busqueda_noticia && (


                                              <div className="alert alert-dismissible alert-warning">
                                                <h4 className="alert-heading">...</h4>
                                                <p className="mb-0">No tenemos resultados para su búsqueda **</p>
                                              </div>


                                           )}



                                </Tab>
                          </Tabs>



                    </div>
                  </div>


              </div>








                <Modal show={showEdit} onHide={CerrarModalEdicion}>
                  <Modal.Header closeButton>
                    <Modal.Title>NOTICIA EN EDICIÓN</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                          <EditarReplica noticia={noticia} />

                  </Modal.Body>
                </Modal>






                <Modal show={show} fullscreen={true} onHide={CerrarModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>NOTICIA</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>



                  <div className="row">
                    <div className="col-sm-6">

                    {
                      // JSON.stringify(noticia.reacciones)
                    }


                            <dl>
                              <dt>Titular</dt>
                              <dd>{noticia.titulo}</dd>
                              <dt>Síntesis</dt>
                              <dd>{noticia.detalle}</dd>
                              <dd>
                                
                                {
                                  noticia.tags.map(
                                    (etiqueta :any, indice : number) =>
                                              <span key={ Math.random().toString(36).substr(2, 9) }><span className="badge bg-danger"><small>{etiqueta.idetiqueta.etiqueta}</small></span> &nbsp;</span>
                                        )
                                }
                              </dd>
                              { noticia.idred_social && (
                                <>
                                  <dt>Red Social</dt>
                                  <dd>{noticia.red_social}</dd>
                                </>
                              )}
                            </dl>



                            { 
                              noticia.idred_social && (
                              <p>
                                {noticia.idred_social === 1 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-facebook" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-facebook"></i><span> 
                                      {noticia.reacciones.facebook_me_gusta > 0 && ( <> 👍 {noticia.reacciones.facebook_me_gusta} </> )}
                                      {noticia.reacciones.facebook_me_encanta > 0 && ( <> ❤️ {noticia.reacciones.facebook_me_encanta} </> )}
                                      {noticia.reacciones.facebook_me_importa > 0 && ( <> 💕 {noticia.reacciones.facebook_me_importa} </> )}
                                      {noticia.reacciones.facebook_me_divierte > 0 && ( <> 😂 {noticia.reacciones.facebook_me_divierte} </> )}
                                      {noticia.reacciones.facebook_me_sorprende > 0 && ( <> 😮 {noticia.reacciones.facebook_me_sorprende} </> )}
                                      {noticia.reacciones.facebook_me_entristece > 0 && ( <> 😢 {noticia.reacciones.facebook_me_entristece} </> )}
                                      {noticia.reacciones.facebook_me_enoja > 0 && ( <> 😡 {noticia.reacciones.facebook_me_enoja} </> )}
                                      {noticia.reacciones.facebook_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.facebook_comentarios} </> )}
                                      {noticia.reacciones.facebook_compartidos > 0 && ( <> 🔃 {noticia.reacciones.facebook_compartidos} </> )}
                                    </span></a>

                                  </>
                                )}


                                {noticia.idred_social === 4 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-instagram" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-instagram"></i><span> 
                                      {noticia.reacciones.instagram_me_gusta > 0 && ( <> ❤️ {noticia.reacciones.instagram_me_gusta} </> )}
                                      {noticia.reacciones.instagram_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.instagram_comentarios} </> )}
                                    </span></a>

                                  </>
                                )}


                                {noticia.idred_social === 2 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-youtube" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-youtube"></i><span> 
                                      {noticia.reacciones.youtube_me_gusta > 0 && ( <> 👍 {noticia.reacciones.youtube_me_gusta} </> )}
                                      {noticia.reacciones.youtube_comentarios > 0 && ( <> 🗨️ {noticia.reacciones.youtube_comentarios} </> )}
                                      {noticia.reacciones.youtube_reproducciones > 0 && ( <> 👁️ {noticia.reacciones.youtube_reproducciones} </> )}
                                    </span></a>

                                  </>
                                )}



                                {noticia.idred_social === 10 && (
                                  <>
                                    <a className="btnr btnr-icon btnr-twitter" rel="noreferrer" target="_blank" href={noticia.reacciones.url} ><i className="fa fa-twitter"></i><span> 
                                      {noticia.reacciones.twitter_me_gusta > 0 && ( <> ❤️ {noticia.reacciones.twitter_me_gusta} </> )}
                                      {noticia.reacciones.twitter_respuestas > 0 && ( <> 🗨️ {noticia.reacciones.twitter_respuestas} </> )}
                                      {noticia.reacciones.twitter_retweet > 0 && ( <> 🔃 {noticia.reacciones.twitter_retweet} </> )}
                                    </span></a>

                                  </>
                                )}



                              </p>
                              )
                            }

                            <p>
                              <img className="rounded-circle" src={ SERVER_ + 'medio/logo/square/'+noticia.idmedio.uuid+'/'+noticia.idmedio.uuid} alt="..." width="10%" />
                            </p>
                            <h6 className="nm">{noticia.idmedio.nombre}</h6>
                            

                            <div className="row">
                              <div className="col">
                                <p className="nm"><small>{noticia.fecha_replica}</small></p>
                                <p className="nm"><small>{noticia.idtipo_noticia.tipo_noticia}</small></p>

                                <br />

                              </div>
                              <div className="col-3">
                                {noticia.multimedia.length > 0 &&(
                                  <p className="text-center">
                                    <img src="assets/images/multimedia.png" alt="Multimedia" width="30%" />
                                  </p>

                                )}
                              </div>
                              <div className="col-3">

                                  <div className="text-right">

                                          {  noticia.idtendencia.id === 1 && (<span className="tendencia positivas">{noticia.idtendencia.tendencia}</span>)}
                                          {  noticia.idtendencia.id === 2 && (<span className="tendencia negativas">{noticia.idtendencia.tendencia}</span>)}
                                          {  noticia.idtendencia.id === 3 && (<span className="tendencia neutras">{noticia.idtendencia.tendencia}</span>)}
                                  </div>

                              </div>
                            </div>

                      
                    </div>
                    <div className="col-sm-6">
                      
                        <div className="row" style={{'marginTop': 10}}>
                          {noticia.multimedia.map( (reg:any) =>


                              <div key={ Math.random().toString(36).substr(2, 9) } className="col-md-6">

                                  { (["jpg","jpeg","png"].indexOf(reg.tipo_archivo) >= 0) && ( 
                                    <>
                                      <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={ SERVER_ + 'hemeroteca/'+reg.uuid} alt={reg.uuid} width="100%" style={{"verticalAlign":'baseline'}} />
                                    </>
                                    )}


                                  { reg.tipo_archivo === "mp4" && ( 
                                    <>
                                          <video controls style={{"width": "100%"}}>
                                              <source type={'video/'+reg.tipo_archivo} src={ SERVER_ + 'hemeroteca/'+reg.uuid} />
                                          </video>
                                    </>
                                    )}


                                  { (reg.tipo_archivo === "mp3" || reg.tipo_archivo === "ogg") && ( 
                                    <>
                                          <audio controls style={{"width": "100%"}}>
                                              <source type={'audio/'+reg.tipo_archivo} src={ SERVER_ + 'hemeroteca/'+reg.uuid} />
                                          </audio>
                                    </>
                                    )}

                              </div>
                          )}
                        </div>


                    </div>
                  </div>

                    


                  </Modal.Body>
                </Modal>





                {
                  showModalEdicionMultimedia && (

                      <ModalEdicionMultimedia uuid_noticia={uuid_noticia_seleccionada} show={showModalEdicionMultimedia} handleClose={handleCloseModalEdicionMultimedia} />

                  )

                }



                {
                  showModalAuditoriaEdiciones && (

                      <ModalAuditoriaEdiciones uuid_noticia={uuid_noticia_seleccionada} show={showModalAuditoriaEdiciones} handleClose={handleCloseModalAuditoriaEdiciones} />

                  )

                }


                {
                  showModalRectifica && (

                      <ModalEnviarRectificacion uuid_noticia={uuid_noticia_seleccionada} show={showModalRectifica} handleClose={handleCloseRectificaModal} />

                  )

                }




                {
                  showModalCaratula && (

                      <ModalNoticiaCaratula uuid_noticia={uuid_noticia_seleccionada} show={showModalCaratula} handleClose={handleCloseCaratulaModal} />

                  )

                }





          </>

      );
  

}

export default Analistas