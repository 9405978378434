import React, { useState, Fragment } from "react";
import { toast } from 'react-toastify'
import moment from 'moment'

import { useNavigate } from "react-router"


import './nueva-noticia.scss';

import { SERVER_ } from  '../../../config';


import RequestService from "../../../services/request.service";

import { Form, Button } from 'react-bootstrap'

// import FileUpload from "../../../widgets/file-upload.component";
// import { MixedTags } from "@yaireo/tagify/dist/react.tagify" // React-wrapper file
// import "@yaireo/tagify/dist/tagify.css" // Tagify CSS

import { confirm } from '../../../widgets/confirm'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { AsyncTypeahead } from 'react-bootstrap-typeahead'; // ES2015

import 'react-bootstrap-typeahead/css/Typeahead.css';

import { Modal } from 'react-bootstrap'


import { WithContext as ReactTags } from 'react-tag-input';



const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];





function NuevaReplica() {

    let navigate = useNavigate();

    const [show, setShow] = useState(false);
    // const ref = useRef();
    const [value, setValue] = useState('');

    const [idtipo_medio, setTipo_medio] = useState(0)
    const [idtipo_noticia, setTipo_noticia] = useState(0)
    const [idmunicipio, setMunicipio] = useState(0)
    // const [idred_social, setRedSocial ] = useState(0)
    // } onChange={handleChangeMunicipio
    const [idtendencia, setTendencia] = useState(0)
    const [idmedio, setMedio] = useState(0)
    const [uuidmedio, setMedioUUID] = useState('')
    const [nombre_medio, setMedioNombre] = useState('')

    const [alcance, setAlcance] = useState('')
    const [fake_new, setFakeNew] = useState(false)

    const [titulo, setTitulo] = useState('')
    const [detalle, setDetalle] = useState('')


    const [facebook_me_gusta, setFacebook_me_gusta ] = useState(0);
    const [facebook_me_encanta, setFacebook_me_encanta ] = useState(0);
    const [facebook_me_importa, setFacebook_me_importa ] = useState(0);
    const [facebook_me_divierte, setFacebook_me_divierte ] = useState(0);
    const [facebook_me_sorprende, setFacebook_me_sorprende ] = useState(0);
    const [facebook_me_entristece, setFacebook_me_entristece ] = useState(0);
    const [facebook_me_enoja, setFacebook_me_enoja ] = useState(0);
    const [facebook_comentarios, setFacebook_comentarios ] = useState(0);
    const [facebook_compartidos, setFacebook_compartidos ] = useState(0);
    const [twitter_respuestas, setTwitter_respuestas ] = useState(0);
    const [twitter_me_gusta, setTwitter_me_gusta ] = useState(0);
    const [twitter_retweet, setTwitter_retweet ] = useState(0);
    const [youtube_comentarios, setYoutube_comentarios ] = useState(0);
    const [youtube_reproducciones, setYoutube_reproducciones ] = useState(0);
    const [youtube_me_gusta, setYoutube_me_gusta ] = useState(0);
    const [instagram_me_gusta, setInstagram_me_gusta] = useState(0);
    const [instagram_comentarios, setInstagram_comentarios] = useState(0);


    const [reproducciones, setReproducciones] = useState<any>(0);


    const changeFacebook_me_gusta = (event:any) => { setFacebook_me_gusta(event.target.value) }
    const changeFacebook_me_encanta = (event:any) => { setFacebook_me_encanta(event.target.value) }
    const changeFacebook_me_importa = (event:any) => { setFacebook_me_importa(event.target.value) }
    const changeFacebook_me_divierte = (event:any) => { setFacebook_me_divierte(event.target.value) }
    const changeFacebook_me_sorprende = (event:any) => { setFacebook_me_sorprende(event.target.value) }
    const changeFacebook_me_entristece = (event:any) => { setFacebook_me_entristece(event.target.value) }
    const changeFacebook_me_enoja = (event:any) => { setFacebook_me_enoja(event.target.value) }
    const changeFacebook_comentarios = (event:any) => { setFacebook_comentarios(event.target.value) }
    const changeFacebook_compartidos = (event:any) => { setFacebook_compartidos(event.target.value) }
    const changeTwitter_respuestas = (event:any) => { setTwitter_respuestas(event.target.value) }
    const changeTwitter_me_gusta = (event:any) => { setTwitter_me_gusta(event.target.value) }
    const changeTwitter_retweet = (event:any) => { setTwitter_retweet(event.target.value) }
    const changeYoutube_comentarios = (event:any) => { setYoutube_comentarios(event.target.value) }
    const changeYoutube_reproducciones = (event:any) => { setYoutube_reproducciones(event.target.value) }
    const changeYoutube_me_gusta = (event:any) => { setYoutube_me_gusta(event.target.value) }
    const changeInstagram_me_gusta = (event:any) => { setInstagram_me_gusta(event.target.value) }
    const changeInstagram_comentarios = (event:any) => { setInstagram_comentarios(event.target.value) }

    const [url, setUrl] = useState('')


    const [fecha, setFecha] = useState( new Date() )


    const [red_social, setRedSocial] = useState( false )
    const [idred_social, setIDRedSocial] = useState( 0 )


    const [medios_tipo, setMedios_Tipo] = useState([])

    const [medios_informativos, setMedios_informativos] = useState([])

    const [tipos_noticias, setTipos_noticias] = useState([])

    const [municipios, setMunicipios ] = useState([])







  const [tags, setTags] = useState<any[]>([]);

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const [suggestions, setSuggestions] = useState([]);

const cargarEtiquetas = async () => {

    let res = await RequestService.getEndPoint('etiquetas/all')

    setSuggestions(res.data)

  }







    const [isLoadingSearchMedio, setIsLoadingSearchMedio] = useState(false)




  const changeTendencia = (event:any) => {
  	setTendencia(event.target.value)
  }

  const changeAlcance = (event:any) => {
    setAlcance(event.target.value)
  }


  const handleChangeTitulo = (event:any) => { 
    setTitulo((event.target.value)) //.toUpperCase());
  }

  const handleChangeTipoNoticia = (event:any) => { 
    setTipo_noticia(event.target.value*1);
  }
  const handleChangeMunicipio = (event:any) => { 
    setMunicipio(event.target.value*1);
  }






  const changeUrl = (event:any) => {
    setUrl(event.target.value)
  }




  const onChangeDate = (date:any) => {
    // console.log(date)
    setFecha(date)
  }

  // const updateUploadedFiles = (files:any) => {
  //   console.log(files)
  //   setProfileImages(files)
  // }


  const canBeSubmitted = () => {
  	return true
  }




  const cargarTipoNoticia = async (idtipo_medio:number) =>{
    try{
        let reqs = await RequestService.getEndPoint('noticias/nueva/tipo_noticia/'+idtipo_medio)
        // console.log('jajahah-->',reqs)
        // this.setState((tipos_noticias, props) => ({ tipos_noticias: reqs.data }));
        setTipos_noticias( reqs.data )
    } 
    catch(e){
      //alert('Error cargarTipoNoticia')
    } 
  }



  const cargarMunicipios = async () =>{
    try{
        let reqs = await RequestService.getEndPoint('municipios')
        // console.log('jajahah-->',reqs)
        // this.setState((tipos_noticias, props) => ({ tipos_noticias: reqs.data }));
        setMunicipios( reqs.data )
    } 
    catch(e){
      //alert('Error cargarTipoNoticia')
    } 
  }

  const [ refrescaMediosList, setRefreshMediosList ] = useState(false)

  const onChangeTipoMedio = async (event:any, idtipo_medio:number) => {

        // this.setState((disabled_tipo_medio, props) => ({ disabled_tipo_medio: true }));
        setTipo_medio(idtipo_medio);
        setTipo_noticia(0)

        setMedio(0)
        setMedioUUID('')
        setSeleccionMedio(false)
        setValue('')
        console.log("setValue('')")
        setRefreshMediosList(true)
        // ref.current.clear()

        if(idtipo_medio===5) setRedSocial(true)
          else setRedSocial(false)

        setIDRedSocial(0)
        setUrl('')
        setMedios_informativos([])

        await cargarTipoNoticia(idtipo_medio)

        setRefreshMediosList(false)


  }

  const handleSearchMedio = async(query:String) => {

    setIsLoadingSearchMedio(true)
    
    // setMedio(0)

        let reqs = await RequestService.getEndPoint(`medios/filter/`+idtipo_medio+`/`+query)
        // this.setState((tipos_noticias, props) => ({ tipos_noticias: reqs.data }));
        const options = reqs.data.map((i:any) => ({
          avatar_url: i.logo_150x150,
          id: i.id,
          uuid: i.uuid,
          nombre: i.nombre,
          idred_social: i.idred_social,
        }));
        // this.setState((medios_informativos, props) => ({ medios_informativos: options }));
        setMedios_informativos(options)
        setIsLoadingSearchMedio(false)

  }

  const setSingleSelectionMedio = (s:any) => {
	if(s[0]){
    setMedio(s[0].id )
    setMedioUUID(s[0].uuid)
    setMedioNombre(s[0].nombre)
    setSeleccionMedio(true)
      // if(s[0].idred_social){
      // 	setRedSocial(true)
      // 	setIDRedSocial(s[0].idred_social)
      // }
      // console.log(s[0])
    }
  }

  const dismarkMedio = () =>{
        setMedio(0)
        setMedioNombre('')
        setMedioUUID('')
        setSeleccionMedio(false)
  }

  // canBeSubmitted() {
  //   const { titulo, detalle, idmedio, idtipo_noticia, idtipo_medio } = this.state;
  //   // var idtipo_medio = this.state.idtipo_medio
  //   return (
  //     idtipo_medio > 0 &&
  //     idtipo_noticia > 0 &&
  //     idmedio > 0 &&
  //     titulo.length > 0 &&
  //     detalle.length > 0
  //   );
  // }


  const [ enviandoNoticia, setEnviandoNoticia ] = useState(false)

  const crearNuevaReplica =  async (event:any) =>{
      try{

	    event.preventDefault();

	      if (canBeSubmitted()) {


                if (await confirm({
                  confirmation: 'Estas seguro?'
                })) {
                  setEnviandoNoticia(true)
                // const { titulo, detalle, idmedio, idtipo_noticia, profileImages } = this.state;
                var SEND = {
                	idtendencia : idtendencia,
                	idtipo_medio : idtipo_medio,
                  alcance : alcance, 
                  titulo : titulo, 
                  detalle : detalle,
                  idmunicipio : idmunicipio,
                  idmedio : idmedio,
                  idtipo_noticia : idtipo_noticia,
                  idred_social : (idred_social>0 ? idred_social : null),
                  fecha_replica : moment(fecha).format('YYYY-MM-DD HH:mm:ss'),

                  fake_new : fake_new,
                  
                  etiquetas : tags,

                  multimedia : archivos_anexos,

                  reacciones : {
                      url : url,
                      facebook_me_gusta : facebook_me_gusta,
                      facebook_me_encanta : facebook_me_encanta,
                      facebook_me_importa : facebook_me_importa,
                      facebook_me_divierte : facebook_me_divierte,
                      facebook_me_sorprende : facebook_me_sorprende,
                      facebook_me_entristece : facebook_me_entristece,
                      facebook_me_enoja : facebook_me_enoja,
                      facebook_comentarios : facebook_comentarios,
                      facebook_compartidos : facebook_compartidos,
                      twitter_respuestas : twitter_respuestas,
                      twitter_me_gusta : twitter_me_gusta,
                      twitter_retweet : twitter_retweet,
                      youtube_comentarios : youtube_comentarios,
                      youtube_reproducciones : youtube_reproducciones,
                      youtube_me_gusta : youtube_me_gusta,
                      instagram_me_gusta : instagram_me_gusta,
                      instagram_comentarios : instagram_comentarios,
                  },
                  reproducciones : reproducciones
                }

                // console.log(SEND)
                await RequestService.create('nueva-replica', SEND )

                toast.success('Su noticia fue agregada correctamente')
                setEnviandoNoticia(false)
                // if(false) 
                if (await confirm({ confirmation: '¿Deseas seguir capturando?' })){
                    window.location.reload()
                }
                else navigate('/noticias/replicas');
                return;
              }


	      }

        // await RequestService.create('nueva-replica', { ok :  true } )




      }
      catch(e:any){
        var E = JSON.parse(JSON.stringify(e))
        console.log(e.response)
        if(E.status===400) toast.error(e.response.data)
      	else toast.error('Error al intentar agregar la noticia '+JSON.stringify(e))
        setEnviandoNoticia(false)
      }

  }




const handleOnClick = async () => {
  if (await confirm({
    confirmation: 'Estas seguro? Perderas los datos capturados'
  })) {
    window.location.reload()
  }
}


  const cargarTiposMedios = async  ()=>{
    RequestService.getEndPoint('noticias/nueva/medios_tipo').then(
      response => {
        // console.log(response.data)
        setMedios_Tipo(response.data)
      },
      error => {
        alert('error cargando tipo medios')
      }
    );
  }

  // const cargarWhiteList = async () => {
  //   let wl = await RequestService.getEndPoint('etiquetas/replica/all')
  //   wlchange(wl.data)
  // }

  // crearNuevaReplica

  React.useEffect(() => {
    // cargarloBaby( fecha.toString() )

    cargarTiposMedios()

    cargarEtiquetas()

    cargarMunicipios()
    // cargarWhiteList()


  }, []);

    // const filterBy = () => true;


const onChange = (e:any) => {  
  // setTages( e.detail.tagify.getCleanValue() )
  setDetalle( e.target.value )
}



  const actualizarCampoRedes = (value:number) =>{ 
    setIDRedSocial( value )
  }

  // const [ WL, wlchange ] = useState([])

  // const [ tages, setTages] = useState([])

  const [ archivos_anexos, setAnexos ] = useState<any>([])
  const [ archivos_anexos_tipo_archivo, setAnexosTipoArchivo ] = useState<any>([])


  const handleFileInputChange = e => {
    if(e.target.files.length>0){
      var files = e.target.files
      console.log(files)
      for (var i = 0; i < files.length; ++i) {

        if(files[i].size < 20000001){

              const reader = new FileReader()
                  reader.onloadend = () => {
                    if(reader.result){
                    setAnexos([...archivos_anexos, reader.result ])
                  }
              }
              // console.log(files[i].type)
              var ARCHIVOS_tipo_archivo = [...archivos_anexos_tipo_archivo]
              ARCHIVOS_tipo_archivo.push(files[i].type.split('/')[0])
              setAnexosTipoArchivo( ARCHIVOS_tipo_archivo )
              reader.readAsDataURL(files[i])

          }
          else{
            toast.warning('El archivo que intentas agregar es demasiado grande, supera los 20 MB')
          }
      }

    }

  }

  const quitarImagen = (index : number) => {
    var ARCHIVOS = [...archivos_anexos]
    var ARCHIVOS_tipo_archivo = [...archivos_anexos_tipo_archivo]
    ARCHIVOS.splice(index,1)
    ARCHIVOS_tipo_archivo.splice(index,1)
    setAnexos( ARCHIVOS )
    setAnexosTipoArchivo( ARCHIVOS_tipo_archivo )

  }


  const disableSendButton = () => {
    var isDisabled : boolean = true
    if(
        (idtipo_medio > 0)
        &&
        (idtipo_noticia > 0)
        &&
        (idtendencia > 0)
        &&
        (alcance !== "")
        &&
        (idmedio > 0)
        &&
        (titulo.trim().length > 0)
        &&
        (detalle.trim().length > 0)
        &&
        (tags.length > 0)
      ){ 
      if(idtipo_medio === 5 || idtipo_medio === 4){
        if(url.trim().length > 0) isDisabled = false;
        else isDisabled = true
      }

      else isDisabled = false;
    }

    return isDisabled
  }




  const [ medio_seleccionado, setSeleccionMedio ] = useState(false)


  const [ noticias_de_hoy, setNoticiasHoy ] = useState([])

  const verNoticiasHoy =  async () => {

    let noticias = await RequestService.getEndPoint('noticias_de_hoy/'+idmedio+'/'+moment(fecha).format('YYYY-MM-DD'))
    // console.log(noticias)
    setNoticiasHoy(noticias.data)

    setShow(true)

  }



  return (
  		<>
        		<div className="container">
            
        		<h2>Nueva Nota Informativa</h2>
            <hr />

              <form encType="multipart/form-data" onSubmit={crearNuevaReplica}>
                
                  <div className="row">
                    <div className="col-sm-3">


                        <div className="mb-3">
                            <label className="form-label">Fecha y Hora de la Noticia</label>

                          <DatePicker
                            dateFormat="yyyy-MM-dd HH:mm"
                            selected={fecha}
                            onChange={onChangeDate}
                            showTimeSelect
                          />

                        </div>



                        <div className="mb-3">
                            <label className="form-label" htmlFor="tipoNoticia">Municipios</label>
                            <select className="form-select" id="tipoNoticia" value={idmunicipio} onChange={handleChangeMunicipio} >
                                
                              {municipios.map( (reg:any) =>
                                  <option disabled={reg.id===0} key={ Math.random().toString(36).substr(2, 9) } value={reg.id}>{reg.municipio}</option>
                              )}
                            </select>
                            {
                              // <small className="form-text text-muted">Deja vacío el campo si proviene de más de un Municipio</small>
                            }
                        </div>




                        <div className="mb-3">
                            <label className="form-label d-block">Entrada</label>

                              {medios_tipo.map( (reg:any, i : number) =>

                                <div key={i} className="form-check">
                                    <input className="form-check-input" id={'optionA'+i} type="radio" name="tipoDeMedio" value={reg.id} onChange={(event)=>{onChangeTipoMedio(event,reg.id)}} />
                                    <label className="form-check-label" htmlFor={'optionA'+i}>{reg.tipo_medio}</label>
                                </div>
                                
                              )}

                        </div>



                  { 
                    idtipo_medio===5 && (

                          <div className="mb-3">
                              <label className="form-label d-block">Red Social de Procedencia</label>
                              <div className="form-check form-check-inline">
                                  <input className="form-check-input" id="facebook" type="radio" checked={idred_social===1} onChange={()=>{actualizarCampoRedes(1)}} name="red_social"/>
                                  <label className="form-check-label" htmlFor="facebook">FACEBOOK</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input className="form-check-input" id="youtube" type="radio" checked={idred_social===2} onChange={()=>{actualizarCampoRedes(2)}} name="red_social"/>
                                  <label className="form-check-label" htmlFor="youtube">YOUTUBE</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input className="form-check-input" id="whatsapp" type="radio" checked={idred_social===3} onChange={()=>{actualizarCampoRedes(3)}} name="red_social"/>
                                  <label className="form-check-label" htmlFor="whatsapp">WHATSAPP</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input className="form-check-input" id="instagram" type="radio" checked={idred_social===4} onChange={()=>{actualizarCampoRedes(4)}} name="red_social"/>
                                  <label className="form-check-label" htmlFor="instagram">INSTAGRAM</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input className="form-check-input" id="tiktok" type="radio" checked={idred_social===5} onChange={()=>{actualizarCampoRedes(5)}} name="red_social"/>
                                  <label className="form-check-label" htmlFor="tiktok">TIKTOK</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input className="form-check-input" id="telegram" type="radio" checked={idred_social===6} onChange={()=>{actualizarCampoRedes(6)}} name="red_social"/>
                                  <label className="form-check-label" htmlFor="telegram">TELEGRAM</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input className="form-check-input" id="snapchat" type="radio" checked={idred_social===7} onChange={()=>{actualizarCampoRedes(7)}} name="red_social"/>
                                  <label className="form-check-label" htmlFor="snapchat">SNAPCHAT</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input className="form-check-input" id="pinterest" type="radio" checked={idred_social===8} onChange={()=>{actualizarCampoRedes(8)}} name="red_social"/>
                                  <label className="form-check-label" htmlFor="pinterest">PINTEREST</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input className="form-check-input" id="reddit" type="radio" checked={idred_social===9} onChange={()=>{actualizarCampoRedes(9)}} name="red_social"/>
                                  <label className="form-check-label" htmlFor="reddit">REDDIT</label>
                              </div>
                              <div className="form-check form-check-inline">
                                  <input className="form-check-input" id="twitter" type="radio" checked={idred_social===10} onChange={()=>{actualizarCampoRedes(10)}} name="red_social"/>
                                  <label className="form-check-label" htmlFor="twitter">TWITTER</label>
                              </div>
                          </div>


                    )
                  }

                        <div className="mb-3">
                            <label className="form-label" htmlFor="tipoNoticia">Tipo de Noticia</label>
                            <select className="form-select" id="tipoNoticia" value={idtipo_noticia} onChange={handleChangeTipoNoticia} >
                                
                              {tipos_noticias.map( (reg:any) =>
                                  <option disabled={reg.id===0} key={ Math.random().toString(36).substr(2, 9) } value={reg.id}>{reg.tipo_noticia}</option>
                              )}
                            </select>
                        </div>



                    </div>
                    <div className="col-sm">

                      <div className="row">
                        <div className=" col-sm">







                        {
                          medio_seleccionado && (

                            <div className="d-flex mb-3">
                              <div className="flex-shrink-0">
                                <img src={ SERVER_ + 'medio/logo/square/'+uuidmedio+'/'+uuidmedio} alt="..." width="40px" />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <span className="pull-right clicoso" onClick={dismarkMedio}><i className="fa fa-close" /></span>
                                <h3 className="nm">{nombre_medio}</h3>
                              </div>
                            </div>

                          )
                        }  



                        {
                          (!medio_seleccionado && !refrescaMediosList) && (

                            <div className="mb-3">
                                <label className="form-label" htmlFor="medio_informativo">Medio Informativo</label>

                                <AsyncTypeahead
                                  defaultInputValue={value}
                                  
                                  id="medio_informativo"
                                  isLoading={isLoadingSearchMedio}
                                  labelKey="nombre"
                                  minLength={2}
                                  onSearch={handleSearchMedio}
                                  onChange={setSingleSelectionMedio}
                                  options={medios_informativos}
                                  placeholder="Buscar un medio de comunicación..."
                                  disabled = {(idtipo_medio<=0)? "disabled" : ""}
                                  renderMenuItemChildren={(option:any, props:any) => (
                                    <Fragment>
                                      <img
                                        alt={option.nombre}
                                        src={ SERVER_ + 'medio/logo/square/'+option.uuid+'/'+option.uuid}
                                        style={{
                                          height: '24px',
                                          marginRight: '10px',
                                          width: '24px',
                                        }}
                                      />
                                      <span>{option.nombre}</span>
                                    </Fragment>
                                  )}
                              />


                            </div>

                          )
                        }  














                        { red_social && (
                            <>
                                  { 
                                    // red_social :{(red_social)?'SI':'NO'} <br />
                                    //idred_social : {idred_social}
                                }

                               { idred_social === 1 &&
                                 (

                                      <div className="card text-white mb-3 bg-facebook nm">
                                        <div className="card-header"> <i className="fa fa-facebook-official" /> Facebook</div>
                                        <div className="card-body">


                                              <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">👍</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_gusta} onChange={changeFacebook_me_gusta} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio2">❤️</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_encanta} onChange={changeFacebook_me_encanta} type="number" id="reaccio2" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio3">💕</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_importa} onChange={changeFacebook_me_importa} type="number" id="reaccio3" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio4">😂</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_divierte} onChange={changeFacebook_me_divierte} type="number" id="reaccio4" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio5">😮</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_sorprende} onChange={changeFacebook_me_sorprende} type="number" id="reaccio5" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio6">😢</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_entristece} onChange={changeFacebook_me_entristece} type="number" id="reaccio6" />
                                                    </div>
                                                </div>
                                              </div>


                                              <div className="row">
                                                <div className="col col-md-2">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio6">😡</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_enoja} onChange={changeFacebook_me_enoja} type="number" id="reaccio6" />
                                                    </div>
                                                </div>
                                                <div className="col col-md-2">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio7">🗨️</label>
                                                      <input className="form-control form-control-sm" value={facebook_comentarios} onChange={changeFacebook_comentarios} type="number" id="reaccio7" />
                                                    </div>
                                                </div>
                                                <div className="col col-md-2">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio8">🔃</label>
                                                      <input className="form-control form-control-sm" value={facebook_compartidos} onChange={changeFacebook_compartidos} type="number" id="reaccio8" />
                                                    </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col mt-2">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>


                                              <div className="col-4 mt-2">
                                                <div className="mb-3">
                                                  <label className="form-label">Reproducciones</label>
                                                  <input type="number" className="form-control" value={reproducciones} onChange={(e:any)=>{setReproducciones(parseInt(e.target.value))}} />
                                                </div>
                                              </div>


                                        </div>
                                      </div>


                                   )
                               }


                               { idred_social === 4 &&
                                 (


                                      <div className="card text-white mb-3 bg-instagram nm">
                                        <div className="card-header"> <i className="fa fa-instragram" /> Instagram</div>
                                        <div className="card-body">


                                              <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">👍</label>
                                                      <input className="form-control form-control-sm" value={instagram_me_gusta} onChange={changeInstagram_me_gusta} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio7">🗨️</label>
                                                      <input className="form-control form-control-sm" value={instagram_comentarios} onChange={changeInstagram_comentarios} type="number" id="reaccio7" />
                                                    </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col mt-2">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-4 mt-2">
                                                <div className="mb-3">
                                                  <label className="form-label">Reproducciones</label>
                                                  <input type="number" className="form-control" value={reproducciones} onChange={(e:any)=>{setReproducciones(parseInt(e.target.value))}} />
                                                </div>
                                              </div>


                                        </div>
                                      </div>


                                   )
                               }



                               { idred_social === 5 &&
                                 (


                                      <div className="card text-white- mb-3 bg-tiktok nm">
                                        <div className="card-header"> <i className="fa fa-tiktok" /> Tiktok</div>
                                        <div className="card-body">



                                              <div className="row">
                                                <div className="col">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-4 mt-2">
                                                <div className="mb-3">
                                                  <label className="form-label">Reproducciones</label>
                                                  <input type="number" className="form-control" value={reproducciones} onChange={(e:any)=>{setReproducciones(parseInt(e.target.value))}} />
                                                </div>
                                              </div>


                                        </div>
                                      </div>


                                   )
                               }


                               { idred_social === 8 &&
                                 (


                                      <div className="card text-white- mb-3 bg-pinterest nm">
                                        <div className="card-header"> <i className="fa fa-pinterest" /> Pinterest</div>
                                        <div className="card-body">



                                              <div className="row">
                                                <div className="col">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>


                                        </div>
                                      </div>


                                   )
                               }



                               { idred_social === 9 &&
                                 (


                                      <div className="card text-white- mb-3 bg-reddit nm">
                                        <div className="card-header"> <i className="fa fa-reddit" /> Reddit</div>
                                        <div className="card-body">



                                              <div className="row">
                                                <div className="col">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>


                                        </div>
                                      </div>


                                   )
                               }


                               { idred_social === 10 &&
                                 (

                                      <div className="card text-white mb-3 bg-twitter nm">
                                        <div className="card-header"> <i className="fa fa-twitter" /> Twitter</div>
                                        <div className="card-body">

                                              <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio7">🗨️</label>
                                                      <input className="form-control form-control-sm" value={twitter_respuestas} onChange={changeTwitter_respuestas} type="number" id="reaccio7" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio8">🔃</label>
                                                      <input className="form-control form-control-sm" value={twitter_retweet} onChange={changeTwitter_retweet} type="number" id="reaccio8" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">❤️</label>
                                                      <input className="form-control form-control-sm" value={twitter_me_gusta} onChange={changeTwitter_me_gusta} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col mt-2">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-4 mt-2">
                                                <div className="mb-3">
                                                  <label className="form-label">Reproducciones</label>
                                                  <input type="number" className="form-control" value={reproducciones} onChange={(e:any)=>{setReproducciones(parseInt(e.target.value))}} />
                                                </div>
                                              </div>

                                        </div>
                                      </div>



                                   )
                               }


                               { idred_social === 2 &&
                                 (

                                      <div className="card text-white mb-3 bg-youtube nm">
                                        <div className="card-header"> <i className="fa fa-youtube" /> Youtube</div>
                                        <div className="card-body">

                                              <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio7">🗨️</label>
                                                      <input className="form-control form-control-sm" value={youtube_comentarios} onChange={changeYoutube_comentarios} type="number" id="reaccio7" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">👍</label>
                                                      <input className="form-control form-control-sm" value={youtube_me_gusta} onChange={changeYoutube_me_gusta} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio2">👁️</label>
                                                      <input className="form-control form-control-sm" value={youtube_reproducciones} onChange={changeYoutube_reproducciones} type="number" id="reaccio2" />
                                                    </div>
                                                </div>
                                              </div>

                                                <div className="mt-2">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div> 

                                              <div className="col-4 mt-2">
                                                <div className="mb-3">
                                                  <label className="form-label">Reproducciones</label>
                                                  <input type="number" className="form-control" value={reproducciones} onChange={(e:any)=>{setReproducciones(parseInt(e.target.value))}} />
                                                </div>
                                              </div>

                                        </div>
                                      </div>


                                   )
                               }








                            </>
                            )
                        }



                            <div className="mb-3 mt-2">
                                <label className="form-label" htmlFor="titularDeLaNoticia">Título de la noticia</label>
                                <input className="form-control" id="titularDeLaNoticia" type="text" value={titulo} onChange={handleChangeTitulo} />
                                { (titulo.length > 0 && idmedio > 0) && (
                                      <small className="form-text text-muted">Puedes <a className="clikeame" href="/#" onClick={() => verNoticiasHoy() }> verificar</a> las noticias creadas para la fecha seleccionada para evitar duplicidades</small>
                                )}
                            </div>


                         <div className="mb-3">
                             <label className="form-label" htmlFor="sintesisDeLaNoticia">Síntesis de la noticia</label>
                             <textarea className="form-control" id="sintesisDeLaNoticia" style={{'height': '7rem'}} value={detalle} onChange={onChange} ></textarea>
                         </div>


                         { idtipo_medio === 4 && (

                                  <div className="mb-3 mt-2">
                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                  </div>
                           )}


                          <div className="row">

                            <div className="col-6">
                              <div className="mb-3">
                                <label className="form-label">Tendencia o Impacto</label>
                                <select className="form-select" onChange={changeTendencia}>
                                  <option value="" selected>Seleccionar</option>
                                  <option value="1">Positiva</option>
                                  <option value="2">Negativa</option>
                                  <option value="3">Neutral</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label className="form-label">Alcance</label>
                                <select className="form-select" onChange={changeAlcance}>
                                  <option value="" selected>Seleccionar</option>
                                  <option value="LOCAL">Local</option>
                                  <option value="ESTATAL">Estatal</option>
                                  <option value="NACIONAL">Nacional</option>
                                  <option value="INTERNACIONAL">Internacional</option>
                                </select>
                              </div>
                            </div>


                            
                          </div>







{
//                           <div className="mb-3">
//                           <label className="form-label" htmlFor="sintesisDeLaNoticia">Síntesis de la noticia</label>
//                             <MixedTags
//                             whitelist={WL}
//                               settings={{"required": true, "duplicate": "NOOO", mode: 'mix', pattern: /@|#/ }}
//                               onChange={onChange}
//                               defaultValue={ detalle }
//                             />
//                           </div>
}

                        </div>
                        <div className="col-sm-5">





                              <div key={`fake_new`} className="">
                              {
                                fake_new && (
                                    <React.Fragment>

                                      <div className="alert alert-primary" role="alert">
                                        <i className="fa fa-warning" /> FAKE NEW
                                      </div>
                                        
                                    </React.Fragment>
                                 )
                              }
                                
                                <Form.Check 
                                  onChange={(e)=>{setFakeNew(e.target.checked)}}
                                  type={'checkbox'}
                                  id="fake_new"
                                  label="Esta noticia no es real"
                                />
                              </div>






                    <div className="mb-3">
                        <label className="form-label" htmlFor="titularDeLaNoticia">Etiquetas relacionadas</label>

                        <ReactTags
                          tags={tags}
                          placeholder="Presiona ENTER para agregar una nueva etiqueta"
                          classNames={{tagInputField: 'form-control'}}
                          suggestions={suggestions}
                          delimiters={delimiters}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          inputFieldPosition="top"
                          
                        />
                        { tags.length === 0 && (
                              <small className="form-text text-muted">Debes agrear por lo menos una etiqueta de clasificación para la noticia</small>
                        )}

                    </div>



                              <div className="mb-3 mt-2">
                                <label htmlFor="formFile" className="form-label">Anexos de la noticia</label>
                                <input type="file" name="file" accept="image/jpeg,image/jpg,image/png,video/mp4,audio/mp3,audio/ogg" multiple={false} onChange={handleFileInputChange} />
                                <p className="nm"><small className="form-text text-muted">El limite de tamaño del archivo anexo no debe pasar los 20 mb</small></p>
                              </div>


                             {archivos_anexos.length > 0 && (
                                 <div className="row">
                                      {archivos_anexos.map( (reg:any, index : number) =>




                                                <div className="col-4" key={index} >
                                                


                                                    { archivos_anexos_tipo_archivo[index] === "image" && ( 
                                                      <>
                                                        <img src={reg} className="rounded float-left" alt="..." width="100%" />
                                                      </>
                                                      )}


                                                    { archivos_anexos_tipo_archivo[index] === "video" && ( 
                                                      <>
                                                            <video controls style={{"width": "100%"}}>
                                                                <source type="video/webm" src={reg} />
                                                                <source type="video/mp4" src={reg} />
                                                            </video>
                                                      </>
                                                      )}


                                                    { archivos_anexos_tipo_archivo[index] === "audio" && ( 
                                                      <>
                                                            <audio controls style={{"width": "100%"}}>
                                                                <source type="audio/ogg" src={reg} />
                                                                <source type="audio/mpeg" src={reg} />
                                                            </audio>
                                                      </>
                                                      )}




                                                    
                                                    <p className="nm text-center">
                                                      <button type="button" onClick={()=>{quitarImagen(index)}} className="btn btn-link"><small>Quitar <i className="fa fa-trash" /></small></button>
                                                    </p>
                                                </div>






                                              
                                      )}
                                 </div>
                              )}


                              { !enviandoNoticia &&

                                  <div className="row">
                                    <div className="col-md-8 mt-2">
                                           <Button variant="success" style={{'width':'100%'}} disabled={disableSendButton()} onClick={crearNuevaReplica}>CREAR NOTICIA</Button>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <Button variant="danger" onClick={handleOnClick} style={{'width':'100%'}} >BORRAR</Button>
                                    </div>
                                  </div>

                              }
                              { enviandoNoticia &&

                                  <p >
                                     <i className="fa fa-spin fa-spinner" /> Enviando noticia...
                                  </p>

                              }

                        </div>
                      </div>




                    </div>
                  </div>


              </form>

        		</div>




      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Noticias del Medio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            
            <div className="d-flex mb-3">
              <div className="flex-shrink-0">
                <img src={ SERVER_ + 'medio/logo/square/'+uuidmedio+'/'+uuidmedio} alt="..." width="40px" />
              </div>
              <div className="flex-grow-1 ms-3">
                <h3 className="nm">{nombre_medio}</h3>
              </div>
            </div>
            <ul className="list-group list-group-flush">
              {noticias_de_hoy.map( (noticia:any) =>
                  <li className="list-group-item" key={ Math.random().toString(36).substr(2, 9) } >
                  
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">{noticia.titulo}</div>
                      {noticia.detalle}
                    </div>
                  </li>
              )}
            </ul>

        </Modal.Body>
      </Modal>



  		</>
  	)


}



export default NuevaReplica
