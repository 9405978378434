import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import { WithContext as ReactTags } from 'react-tag-input';


import { SERVER_ } from '../../config'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { Button, Form, ButtonGroup } from 'react-bootstrap'


import './reportes.scss'
const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];


const ReporteMedios = () =>{


    const [ generando_reporte, setGenerandoReporte ] = useState<boolean>(false)

      React.useEffect(() => {
        cargarMedios()
        cargarEtiquetas()
      }, []);


  const [tags, setTags] = useState<any[]>([]);

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const [suggestions, setSuggestions] = useState([]);

const cargarMedios = async () => {

    let res = await RequestService.getEndPoint('medios/all')

    setSuggestions(res.data)

  }




  const [tags_etiquetas, setTagsEtiquetas] = useState<any[]>([]);

  const handleDeleteEtiquetas = i => {
    setTagsEtiquetas(tags_etiquetas.filter((tag, index) => index !== i));
  };

  const handleAdditionEtiquetas = tag => {
    setTagsEtiquetas([...tags_etiquetas, tag]);
  };

  const [suggestions_etiquetas, setSuggestionsEtiquetas] = useState([]);

const cargarEtiquetas = async () => {

    let res = await RequestService.getEndPoint('etiquetas/all')

    setSuggestionsEtiquetas(res.data)

  }



  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };




const [ fake_new, setFakeNew ] = useState<number>(2)







  const abrirReportePDF = async () => {

    try{

        
        setGenerandoReporte(true)
        // let etiquetas_ = tags_etiquetas.map(etiqueta => etiqueta.uuid )
        // window.open(SERVER_+'reporte/temas_analisis/'+moment(startDate).format('YYYY-MM-DD')+'/'+moment(endDate).format('YYYY-MM-DD')+'/'+tendencia+'/'+etiquetas_.join(','))
        let etiquetas_ = tags_etiquetas.map(etiqueta => etiqueta.uuid )
        let medios_ = tags.map(medio => medio.uuid )
        let res = await RequestService.downloadFile('reporte/temas_analisis', { titulo_reporte : titulo_reporte, alcance : alcance, fake_new : fake_new, fecha_inicio : moment(startDate).format('YYYY-MM-DD'), fecha_fin : moment(endDate).format('YYYY-MM-DD'), idtendencia : tendencia, medios : medios_.join(','), etiquetas : etiquetas_.join(',') })
        // console.log(res)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte Acumulado `+moment().format('YYYY_MM_DD_HH_mm')+`.pdf`);
        document.body.appendChild(link);
        link.click();
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }

  const abrirListadoPDF = async () => {
      try{
        // let etiquetas_ = tags_etiquetas.map(etiqueta => etiqueta.uuid )
        // window.open(SERVER_+'listado/temas_analisis/'+moment(startDate).format('YYYY-MM-DD')+'/'+moment(endDate).format('YYYY-MM-DD')+'/'+tendencia+'/'+etiquetas_.join(','))
        setGenerandoReporte(true)
        let etiquetas_ = tags_etiquetas.map(etiqueta => etiqueta.uuid )
        let medios_ = tags.map(medio => medio.uuid )
        let res = await RequestService.downloadFile('listado/temas_analisis', { titulo_reporte : titulo_reporte, alcance : alcance, fake_new : fake_new, fecha_inicio : moment(startDate).format('YYYY-MM-DD'), fecha_fin : moment(endDate).format('YYYY-MM-DD'), idtendencia : tendencia, medios : medios_.join(','), etiquetas : etiquetas_.join(',') })
        // console.log(res)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte Listado de Noticias `+moment().format('YYYY_MM_DD_HH_mm')+`.pdf`);
        document.body.appendChild(link);
        link.click();
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }
  }
  const abrirCaratulasPDF = async() => {
      try{
        setGenerandoReporte(true)
        let etiquetas_ = tags_etiquetas.map(etiqueta => etiqueta.uuid )
        let medios_ = tags.map(medio => medio.uuid )
        let res = await RequestService.downloadFile('caratulas/temas_analisis', { titulo_reporte : titulo_reporte, alcance : alcance,fake_new : fake_new, fecha_inicio : moment(startDate).format('YYYY-MM-DD'), fecha_fin : moment(endDate).format('YYYY-MM-DD'), idtendencia : tendencia, medios : medios_.join(','), etiquetas : etiquetas_.join(',') })
        // console.log(res)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte Caratulas de Noticias `+moment().format('YYYY_MM_DD_HH_mm')+`.pdf`);
        document.body.appendChild(link);
        link.click();
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }

  const abrirCaratulasPDF2 = async() => {
      try{
        setGenerandoReporte(true)
        let etiquetas_ = tags_etiquetas.map(etiqueta => etiqueta.uuid )
        let medios_ = tags.map(medio => medio.uuid )
        let res = await RequestService.downloadFile('caratulas/temas_analisis_horizontal', { titulo_reporte : titulo_reporte, alcance : alcance,fake_new : fake_new, fecha_inicio : moment(startDate).format('YYYY-MM-DD'), fecha_fin : moment(endDate).format('YYYY-MM-DD'), idtendencia : tendencia, medios : medios_.join(','), etiquetas : etiquetas_.join(',') })
        // console.log(res)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte Caratulas de Noticias `+moment().format('YYYY_MM_DD_HH_mm')+`.pdf`);
        document.body.appendChild(link);
        link.click();
        setGenerandoReporte(false)
      }
      catch(e){
        setGenerandoReporte(false)
      }

  }


  const [ tendencia, setTendencia ] = useState<number>(0)
  const [ alcance, setAlcance ] = useState<string>("TODAS")



  const [ titulo_reporte , setTituloReporte ] = useState<string>("")









      return (
          <>

              
            <div className="container">

                <h2>Reportes de Medios</h2>


                {
                  generando_reporte && (
                      <React.Fragment>
                        <i className="fa fa-spin fa-spinner" /> Generando Reporte
                      </React.Fragment>
                  )
                }



                {
                  !generando_reporte && (
                      <React.Fragment>


                              <div className="row">

                                <div className="col-md-3">
                                  

                                    <label className="form-label" htmlFor="titularDeLaNoticia">Fecha del Reporte</label>

                                    <DatePicker selected={startDate} onChange={onChange} startDate={startDate} endDate={endDate} selectsRange inline />

                                    <div  className="mb-3 mt-2">
                                                    <label className="form-label" htmlFor="titularDeLaNoticia">Tendencia</label>

                                        <div className="form-check form-check-inline-">
                                          <input className="form-check-input" type="radio" name="tendencia" id="positiva" checked={tendencia===1} onChange={(e)=>{ setTendencia(1) }} />
                                          <label className="form-check-label" htmlFor="positiva">Positiva</label>
                                        </div>


                                        <div className="form-check form-check-inline-">
                                          <input className="form-check-input" type="radio" name="tendencia" id="negativa" checked={tendencia===2} onChange={(e)=>{ setTendencia(2) }} />
                                          <label className="form-check-label" htmlFor="negativa">Negativa</label>
                                        </div>


                                        <div className="form-check form-check-inline-">
                                          <input className="form-check-input" type="radio" name="tendencia" id="neutral" checked={tendencia===3} onChange={(e)=>{ setTendencia(3) }} />
                                          <label className="form-check-label" htmlFor="neutral">Neutral</label>
                                        </div>


                                        <div className="form-check form-check-inline-">
                                          <input className="form-check-input" type="radio" name="tendencia" id="todas" checked={tendencia===0} onChange={(e)=>{ setTendencia(0) }} />
                                          <label className="form-check-label" htmlFor="todas">Todas</label>
                                        </div>

                                    </div>



                                    <div  className="mb-3 mt-2">

                                        <label className="form-label" htmlFor="veracidad">Veracidad de la Noticia</label>

                                        <Form.Check
                                          checked={fake_new==1}
                                          onChange={(e)=>{ setFakeNew(1); setTituloReporte("Fake News".toUpperCase()) }}
                                          label="Fake News"
                                          name="fake_new"
                                          type="radio"
                                          id="fake_new"
                                        />

                                        <Form.Check
                                          checked={fake_new==0}
                                          onChange={(e)=>{ setFakeNew(0); setTituloReporte("Noticias Reales".toUpperCase()) }}
                                          label="Noticias Reales"
                                          name="fake_new"
                                          type="radio"
                                          id="noticias_reales"
                                        />

                                        <Form.Check
                                          checked={fake_new==2}
                                          onChange={(e)=>{ setFakeNew(2); setTituloReporte("Todas las noticias".toUpperCase()) }}
                                          label="Todas las noticias"
                                          name="fake_new"
                                          type="radio"
                                          id="todas_noticias"
                                        />



                                    </div>



                                    <div  className="mb-3 mt-2">

                                        <label className="form-label" htmlFor="alcance">Alcance de la Noticia</label>

                                        <Form.Check
                                          checked={alcance==="LOCAL"}
                                          onChange={(e)=>{ setAlcance("LOCAL") }}
                                          label="Local"
                                          name="alcance"
                                          type="radio"
                                          id="LOCAL"
                                        />


                                        <Form.Check
                                          checked={alcance==="ESTATAL"}
                                          onChange={(e)=>{ setAlcance("ESTATAL") }}
                                          label="Estatal"
                                          name="alcance"
                                          type="radio"
                                          id="ESTATAL"
                                        />


                                        <Form.Check
                                          checked={alcance==="NACIONAL"}
                                          onChange={(e)=>{ setAlcance("NACIONAL") }}
                                          label="Nacional"
                                          name="alcance"
                                          type="radio"
                                          id="NACIONAL"
                                        />


                                        <Form.Check
                                          checked={alcance==="INTERNACIONAL"}
                                          onChange={(e)=>{ setAlcance("INTERNACIONAL") }}
                                          label="Internacional"
                                          name="alcance"
                                          type="radio"
                                          id="INTERNACIONAL"
                                        />


                                        <Form.Check
                                          checked={alcance==="TODAS"}
                                          onChange={(e)=>{ setAlcance("TODAS") }}
                                          label="Todas"
                                          name="alcance"
                                          type="radio"
                                          id="TODAS"
                                        />




                                    </div>


                                </div>
                                <div className="col-md-9">








                                        <div className="mb-3">
                                          <label className="form-label">Título del Reporte</label>
                                          <input type="text" className="form-control" value={titulo_reporte} onChange={(e:any)=>{ setTituloReporte(e.target.value.toUpperCase()); }} />
                                        </div>





                                  
                                    <ButtonGroup className="justify-content-between mb-3" aria-label="Opciones">

                                        <Button onClick={(e)=>{abrirListadoPDF()}}  variant="secondary">
                                          Ver Listado
                                        </Button>
                                        <Button onClick={(e)=>{abrirReportePDF()}}  variant="secondary">
                                          Ver Reporte
                                        </Button>
                                        <Button onClick={(e)=>{abrirCaratulasPDF()}}  variant="secondary">
                                          Ver Caratulas
                                        </Button>
                                        <Button onClick={(e)=>{abrirCaratulasPDF2()}}  variant="secondary">
                                          Ver Caratulas 2
                                        </Button>

                                    </ButtonGroup>



                            <div className="row">

                                <div className="col-md-6">


                                  <div className="mb-3">
                                      <label className="form-label" htmlFor="titularDeLaNoticia">Medios</label>



                            {
                            // JSON.stringify(tags)
                            }


                                      <ReactTags
                                        tags={tags}
                                        placeholder="Presiona ENTER para agregar un medio"
                                        classNames={{tagInputField: 'form-control'}}
                                        suggestions={suggestions}
                                        delimiters={delimiters}
                                        handleDelete={handleDelete}
                                        handleAddition={handleAddition}
                                        inputFieldPosition="top"
                                        
                                      />



                                      { 
                                        //   tags.length === 0 && (
                                        //       <small className="form-text text-muted">Debes agrear por lo menos una etiqueta de clasificación para la noticia</small>
                                        // )
                                      }

                                      {
                                        // tags.length > 0 && (

                                        //     <React.Fragment>

                                        //         <h6 className="mt-4">Medios Filtrados</h6>

                                        //         <ul className="list-group list-group-light mb-4">
                                        //           {
                                        //             tags.map( (medio:any, index:number) =>
                                        //                   <li key={ Math.random().toString(36).substr(2, 9) } className="list-group-item d-flex justify-content-between align-items-center">
                                        //                     <div className="d-flex align-items-center">
                                        //                       <img src={SERVER_+'medio/logo/square/'+medio.uuid+'/00'} alt="" style={{width: '45px', height: '45px'}}
                                        //                         className="rounded-circle" />
                                        //                       <div className="ms-3">
                                        //                         <p className="fw-bold mb-1">{medio.nombre}</p>
                                        //                         <p onClick={(e)=>{handleDelete(index)}} className="text-muted mb-0 pointer"><small>Quitar</small> <i className="fa fa-trash"/></p>
                                        //                       </div>
                                        //                     </div>
                                        //                   </li>
                                        //               )
                                        //           }

                                        //         </ul>
                                        //     </React.Fragment>

                                        // )
                                      }





                                  </div>


                                </div>
                                <div className="col-md-6">
                                  


                                  <div className="mb-3">
                                      <label className="form-label" htmlFor="titularDeLaNoticia">Temas</label>



                            {
                            // JSON.stringify(tags)
                            }

                                      <ReactTags
                                        tags={tags_etiquetas}
                                        placeholder="Presiona ENTER para agregar una nueva etiqueta"
                                        classNames={{tagInputField: 'form-control'}}
                                        suggestions={suggestions_etiquetas}
                                        delimiters={delimiters}
                                        handleDelete={handleDeleteEtiquetas}
                                        handleAddition={handleAdditionEtiquetas}
                                        inputFieldPosition="top"
                                        
                                      />


                                      { tags_etiquetas.length === 0 && (
                                            <small className="form-text text-muted">Debes agrear por lo menos una de clasificación para la noticia</small>
                                      )}

                                      {
                                        // tags_etiquetas.length > 0 && (

                                        //     <React.Fragment>
                                        //           <h6 className="mt-4">Temas</h6>

                                        //           <ul className="list-group list-group-light mb-4">
                                        //             {
                                        //               tags_etiquetas.map( (etiqueta:any,index:number) =>
                                        //                   <li key={ Math.random().toString(36).substr(2, 9) } className="list-group-item d-flex justify-content-between align-items-center">
                                        //                         <p className="fw-bold mb-1">{etiqueta.etiqueta}</p>
                                        //                         <p onClick={(e)=>{handleDeleteEtiquetas(index)}} className="text-muted mb-0"><small>Quitar</small> <i className="fa fa-trash"/></p>
                                        //                   </li>
                                        //                 )
                                        //             }

                                        //           </ul>
                                        //     </React.Fragment>

                                        // )
                                      }







                                  </div>



                                </div>

                            </div>


                                </div>



                              </div>



                      </React.Fragment>
                  )
                }

              
            </div>
              

          </>

      );
  

}

export default ReporteMedios
