import React, { useState } from 'react';
import RequestService from "../../services/request.service";

import moment from 'moment'

import { toast } from 'react-toastify'


import { Modal, Button } from 'react-bootstrap'

// import { SERVER_ } from  '../../config';


const ModalMencionesEtiquetas = ( props : any ) =>{


	const { isOpen, handleClose, etiqueta_seleccionada, fecha } = props

	const [ noticias, setNoticias ] = useState([])


	const noticiasMencionadas = async (fecha:string) => { 
		try{
			let news = await RequestService.getEndPoint('etiqueta/noticias/'+etiqueta_seleccionada.uuid_etiqueta+'/'+fecha)
			setNoticias(news.data)

		}
		catch(e){
			toast.error('Error al cargar las noticias del día de esta etiqueta')
		}

	}


  React.useEffect(() => {
    // console.log( moment(fecha).format('YYYY-MM-DD') )
    noticiasMencionadas( moment(fecha).format('YYYY-MM-DD') )
  }, [fecha]);



  const publicarNoticia = async ( indice : number, uuid : string, estado : boolean ) => { 
		try{
			await RequestService.update('publicar-noticia/'+uuid, { publicar : estado })
			if(estado) toast.success('Ahora esta noticia aparecera en el Timeline')
				else toast.warning('Quitaste esta noticia del Timeline, esperamos que sepas lo que estas haciendo')
			let n_actualizar : any = [ ...noticias ]
			n_actualizar[indice].publicar = estado
			setNoticias(n_actualizar)

		}
		catch(e){
			toast.error('Error al cargar las noticias del día de esta etiqueta')
		}
  }



	return (


		<>


      <Modal show={isOpen} onHide={handleClose}>
        <Modal.Body>

          <h5 className="nm">{etiqueta_seleccionada.etiqueta}</h5>
          <p className="nm"><small><b>{etiqueta_seleccionada.seccion}</b></small></p>
          <p className="nm"><small>{moment(fecha).format("dddd, MMMM Do [del año] YYYY")}</small></p>
          <hr/>

				<ul className="list-group">
				{
					noticias.map((noticia:any, i : number) =>
						  <li key={i} className="list-group-item flex-column align-items-start">
						    <div className="d-flex w-100 justify-content-between">
						      <h6 className="nm">{noticia.titulo}</h6>
						      <small>{moment(noticia.fecha_replica).format("HH:mm")}</small>
						    </div>
						    <p style={{"lineHeight":1}} className="mb-1"><small>{noticia.detalle}</small></p>
						    <small><b>{noticia.medio}</b></small>
						    {
						    	// JSON.stringify(noticia.tags)
						    }
						    	<p className="nm">
						    	{
						    		noticia.tags.map( (tag:any, index:number) =>
						    			<span key={index}>
						    				<span className={'badge '+ ( tag.uuid_etiqueta === etiqueta_seleccionada.uuid_etiqueta ? 'bg-primary' : 'bg-light' )}>{tag.etiqueta}</span> &nbsp; 
						    			</span>
						    		)
						    	}
						    	</p>

        <div className="mt-">
            <div className="form-check">
                <input className="form-check-input" id={'debeAparecer'+i} type="checkbox" checked={noticia.publicar} onChange={()=>{publicarNoticia(i, noticia.uuid,!noticia.publicar)}} />
                <label className="form-check-label" htmlFor={'debeAparecer'+i}><small>Debe aparecer en el Timeline</small></label>
            </div>
        </div>

						  </li>
					)
				}
				</ul>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>


		</>


	)




}



export default ModalMencionesEtiquetas;