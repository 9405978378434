import React, { useState, useEffect } from "react";

import RequestService from "../../services/request.service";


import { Button, Modal, Form } from 'react-bootstrap'


import { toast } from 'react-toastify'

import { SERVER_ } from  '../../config';


import moment from 'moment'


import EditarReplica from './NuevaNoticia/editar_replica_noticia'


import { confirm } from '../../widgets/confirm'


import Pagination from "../../services/Pagination";


import Dialog from '../../services/Dialogs'



import ModalRectificarNoticia from './modales/ModalRectificarNoticia'


const MisReplicas = ( props ) => { 



  const dialog = new Dialog();



  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 40
  const [currentPage, setCurrentPage] = useState(1);



  const [ resultados_busqueda, setResultados ] = useState<any>([])
  const [ cargando_busqueda_noticia, setCargandoBusquedaNoticia ] = useState( false )
  const [ texto_busqueda_noticia, setTextoBusqueda ] = useState('')


  const tipeandoBusqueda = (event)=>{
    setTextoBusqueda(event.target.value)
  }


  const busquedaNoticias = async (page:number) =>{
    try{
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let resultados = await RequestService.getEndPoint('noticias/mi_busqueda?where='+texto_busqueda_noticia+'&skip='+skip+'&limit='+sessionsPerPage+'&estatus='+JSON.stringify(busquedaEstatus))
      setResultados(resultados.data.results)
      setCargandoBusquedaNoticia(false)

      setCounter(resultados.data.totalCount)


    }
    catch(e){
      setCargandoBusquedaNoticia(false)
      toast.error('Tuvimos problemas procesando su busqueda')
    }
  }



  React.useEffect(() => {
    busquedaNoticias(0)
    setCurrentPage(1)
  }, []);


const handleTest = async (e) => { if (e.charCode === 13) { await busquedaNoticias(0); setCurrentPage(1); } if (e.keyCode === 13) { await busquedaNoticias(0); setCurrentPage(1); } }




  const [ noticia_edicion, setNoticiaSeleccion ] = useState<any>({})
  const [showEdit, setShowEdit] = useState(false);



  const seleccionaNoticiaModalEditar = (noticia:any)=>{
    var noti = {...noticia}
    delete noti.idnoticia
    noti.fecha_replica = moment(noti.fecha_replica).toDate();
    // noti.idred_social = noti.idred_social.id
    // if(noti.idred_social) noti.idred_social =noti.idred_social
    if(!noti.idmunicipio) noti.idmunicipio = 0;

    noti.idmedio_uuid = noti.uuid_medio
    noti.medio = noti.nombre_medio
    noti.idmedio = parseInt(noti.idmedio)
    noti.idtendencia = parseInt(noti.idtendencia)
    noti.idtipo_noticia = parseInt(noti.idtipo_noticia)
    noti.idtipo_medio = parseInt(noti.idtipo_medio)

    
    setNoticiaSeleccion(noti)
    setShowEdit(true)
    // console.log(noticia)
  }

  const CerrarModalEdicion = ()=>{
    setNoticiaSeleccion({})
    busquedaNoticias(currentPage===0?0:currentPage-1)
    setShowEdit(false)
  }



  const eliminarNota = async ()=>{
    try{
        if (await confirm({
          confirmation: 'Estas seguro? Perderas toda la información de la Noticia'
        })) {
          // try{
              // console.log('confirmooooo')
              await RequestService.delete('eliminar-noticia/'+noticia_edicion.uuid_noticia)
              // cargarRegistros()
              // console.log(cargarNoticiasMetodo)
              props.cargarNoticiasMetodo()
              CerrarModalEdicion()
          // }
          // catch(e:any){
          //   var E = JSON.parse(JSON.stringify(e))
          //   console.log(e.response)
          //   if(E.status===400) toast.error(e.response.data)
          //   else toast.error('Error al intentar eliminar la noticia '+JSON.stringify(e))
          //   // setEnviandoNoticia(false)
          // }
        }
      }
      catch(E){
        toast.error('No podemos eliminar esta noticia.')
      }
  }

  const mandarVerificar = async(noticia:any)=>{
    // console.log(noticia)
    try{
        if(await dialog.confirm('¿Estás seguro de enviar a verificación con el Analista esta Noticia?',{})){
          let res = await RequestService.update('noticia/manda_verificacion', { uuid_noticia : noticia.uuid_noticia })
          if(res.data){
            toast.success('Se envio a verificación')
            busquedaNoticias(currentPage===0?0:currentPage-1)
          }
          else toast.error('Problemas al mandar a verificación')
        }
    }
    catch(e){
      toast.error('Error al mandar a verificación')
    }
  }


  const [ busquedaEstatus, setBusquedaEstatus ] = useState<any>([true,true,true,true])



const [ uuid_noticia_seleccionada, setUUIDNoticiaSeleccionada ] = useState<string>('')


  const [showModalRectifica, setShowModalRectifica] = useState(false);

  const handleCloseRectificaModal = () =>{
    busquedaNoticias(currentPage===0?0:currentPage-1)
    setShowModalRectifica(false)
  }
  const handleShowRectificarModal = async (uuid_noticia:string) =>{ 
    setUUIDNoticiaSeleccionada(uuid_noticia)
    setShowModalRectifica(true)
    // await cargarComentarios(uuid_tramite)
  }


	return (

		<>


	        <div className="container">
	              
	              <h2>Mi lista de Notas Informativas</h2>

					

              <div className="mb-3">
                <label className="form-label" htmlFor="busqueda">Búsqueda</label>
                <div className="input-group">
                    <input className="form-control" id="busqueda" type="text" onKeyPress={handleTest} value={texto_busqueda_noticia} onChange={tipeandoBusqueda} />
                    <button className="input-group-text" onClick={()=>{busquedaNoticias(0);setCurrentPage(1);}}><i className="fa fa-search" /></button>
                </div>
              </div>


{
  // JSON.stringify(busquedaEstatus)
}

        <div className="mb-3">
          <Form.Check
            inline
            checked={busquedaEstatus[0]}
            onChange={(e)=>{ var BE = [...busquedaEstatus]; BE[0] = e.target.checked; setBusquedaEstatus(BE) }}
            label="En Captura"
            name="captura"
            type="checkbox"
            id="captura"
          />
          <Form.Check
            inline
            checked={busquedaEstatus[1]}
            onChange={(e)=>{ var BE = [...busquedaEstatus]; BE[1] = e.target.checked; setBusquedaEstatus(BE) }}
            label="En Verificación"
            name="verificar"
            type="checkbox"
            id="verificar"
          />
          <Form.Check
            inline
            checked={busquedaEstatus[2]}
            onChange={(e)=>{ var BE = [...busquedaEstatus]; BE[2] = e.target.checked; setBusquedaEstatus(BE) }}
            label="Para Rectificar"
            name="rectificar"
            type="checkbox"
            id="rectificar"
          />
          <Form.Check
            inline
            checked={busquedaEstatus[3]}
            onChange={(e)=>{ var BE = [...busquedaEstatus]; BE[3] = e.target.checked; setBusquedaEstatus(BE) }}
            label="Aceptadas"
            name="aceptadas"
            type="checkbox"
            id="aceptadas"
          />
        </div>

			







                                          {  (!cargando_busqueda_noticia && resultados_busqueda.length>0) && (

                                              <>


                                                {
                                                  // JSON.stringify(resultados_busqueda)
                                                }

                                                <table className="table table-striped table-hover">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">Título</th>
                                                      <th scope="col">Detalle</th>
                                                      <th scope="col"></th>
                                                      <th scope="col"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>

                                                          {
                                                            resultados_busqueda.map(noticia =>
                                                              <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                                <td className="v">
                                                                  <img src={ SERVER_ + 'medio/logo/square/'+noticia.uuid_medio+'/'+noticia.uuid_medio} alt="props.noticia.uuid_medio" width="70px" />
                                                                </td>
                                                                <td>
                                                                  <b>{noticia.nombre}</b>
                                                                  <p className="nm"><small>{noticia.titulo}</small></p>
                                                                  <p className="nm" style={{lineHeight:.9}}>{noticia.detalle}</p>
                                                                  {
                                                                    noticia.fake_new && (
                                                                        <span className="badge bg-danger">
                                                                          <i className="fa fa-warning" /> Fake New
                                                                        </span>
                                                                    )
                                                                  }
                                                                </td>
                                                                <td className="v text-center" style={{width:100}}>
                                                                    {
                                                                      noticia.idestatus == 1 && (
                                                                            <button type="button" onClick={()=>{mandarVerificar(noticia)}} className="btn btn-xs btn-link">{noticia.estatus}</button>
                                                                      )
                                                                    }
                                                                    {
                                                                      noticia.idestatus == 3 && (
                                                                            <Button className="nm" onClick={()=>{handleShowRectificarModal(noticia.uuid_noticia)}} size="sm" variant="outline-warning"><i className="fa fa-list" /></Button>
                                                                      )
                                                                    }  
                                                                </td>
                                                                <td className="v text-center" style={{width:80}}>
                                                                  {
                                                                    (noticia.idestatus === 1 || noticia.idestatus === 3) &&
                                                                      <Button onClick={()=>{seleccionaNoticiaModalEditar(noticia)}} variant="link"><i className="fa fa-edit"/></Button>
                                                                  }
                                                                  {
                                                                    noticia.idestatus === 4 &&
                                                                    <i className="fa fa-check-circle" />
                                                                  }
                                                                </td>
                                                              </tr>
                                                            )
                                                          }


                                                  </tbody>
                                                </table>



        <Pagination
          itemsCount={allSessionsCount}
          itemsPerPage={sessionsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          cambiarPaginaEvento={busquedaNoticias}
          alwaysShown={false}
        />



                                              </>


                                           )}







			</div>




                <Modal show={showEdit} onHide={CerrarModalEdicion}>
                  <Modal.Header closeButton>
                    <Modal.Title>NOTICIA EN EDICIÓN</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                          <EditarReplica noticia={noticia_edicion} />


                    <div className="mb-3 mt-2">
                        <Button variant="danger" style={{'width':'100%'}} onClick={()=>{eliminarNota()}} ><i className="fa fa-trash" /> </Button>
                    </div>

                  </Modal.Body>
                </Modal>







                {
                  showModalRectifica && (

                      <ModalRectificarNoticia uuid_noticia={uuid_noticia_seleccionada} show={showModalRectifica} handleClose={handleCloseRectificaModal} />

                  )

                }





		</>


	)

}


export default MisReplicas;