// import React from 'react'
import React, { useState, useEffect } from 'react'

import { Link, Route, Routes, useLocation } from 'react-router-dom'



import { useNavigate } from "react-router"

// import { Offcanvas, Button } from 'react-bootstrap'


import { PrivateRoute } from './PrivateRoute'
import NotFound from './pages/NotFound'
import Login from './pages/Login'
import Users from './pages/Users'
import Dashboard from './pages/Dashboard'
// import { ROLE } from './features/auth/auth'
import { ROLE } from './features/auth/role'

// import { useDispatch, useSelector } from 'react-redux'
// import { logout, selectCurrentUser, selectIsAuthenticated } from 'features/auth/authSlice'
import AuthService from './features/auth/auth.service'



import Usuarios from './pages/Usuarios/Usuarios'




import Medios from './pages/Medios/Medios'


import Etiquetas from './pages/Etiquetas/Etiquetas'
import EtiquetasReporte from './pages/Etiquetas/EtiquetasReporte'
import EdicionEtiquetas from './pages/Etiquetas/EdicionEtiquetas'

import Analiticas from './pages/Analiticas/Analiticas'

import Reporte from './pages/Analiticas/Reporte'

import Analistas from './pages/Noticias/Analistas'


import Timeline from './pages/Noticias/timeline'


// import Noticias from './pages/Noticias/Noticias'
import NuevaNoticia from './pages/Noticias/NuevaNoticia/nueva-noticia'

import NuevaReplica from './pages/Noticias/NuevaNoticia/nueva_replica_noticia'
import Replicas from './pages/Noticias/Replicas'
import MisReplicas from './pages/Noticias/MisReplicas'
import Profile from './pages/Profille'



import ReporteMedios from './pages/Reportes/Reportes'


import './App.scss';


function BootHeader() {
  // const dispatch = useDispatch()
  // const user = useSelector(selectCurrentUser)
  const user = AuthService.getCurrentUser()
  // const isAuthenticated = useSelector(selectIsAuthenticated)
  const isAuthenticated = AuthService.selectIsAuthenticated

 
    let navigate = useNavigate();



let location = useLocation();
// console.log(location.pathname)

let activo_perfil = location.pathname === '/profile' ? 'active' : ''
let activo_medios = location.pathname === '/medios' ? 'active' : ''
// let activo_noticias = location.pathname === '/noticias' ? 'active' : ''
let activo_replicas = location.pathname === '/noticias/replicas' ? 'active' : ''
let activo_usuarios = location.pathname === '/usuarios' ? 'active' : ''
let activo_timeline = location.pathname === '/timeline' ? 'active' : ''
let activo_analiticas = location.pathname === '/analiticas' ? 'active' : ''
// let activo_reporte = location.pathname === '/reporte' ? 'active' : ''
let activo_etiquetas = location.pathname === '/etiquetas' ? 'active' : ''
let activo_analistas = location.pathname === '/analistas' ? 'active' : ''
let activo_reportes = location.pathname === '/reportes' ? 'active' : ''




  const [ ROLE_ADMIN, setAdmin ] = useState(false)
  const [ ROLE_CAPTURE, setCapture ] = useState(false)
  const [ ROLE_ANALYST, setAnalyst ] = useState(false)
  const [ ROLE_VIEW, setView ] = useState(false)

  useEffect(() => {

      if(user.rol === "ROLE_ADMIN") setAdmin(true)
      if(user.rol === "ROLE_CAPTURE") setCapture(true)
      if(user.rol === "ROLE_ANALYST") setAnalyst(true)
      if(user.rol === "ROLE_VIEW") setView(true)

  },[ ROLE_ADMIN, ROLE_CAPTURE, ROLE_ANALYST, ROLE_VIEW, user]);


  const navegar = (ruta) => {
    navigate(ruta)
  }


  return (
    <>
    { isAuthenticated && ( 

      <>

      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container-fluid">
          <a className="navbar-brand" href="/#">
            <img src="./assets/images/escudo_white.png" alt="" width="20" className="d-inline-block align-text-top" style={{"marginRight":"10px"}} />
            Síntesis Informativa
          </a>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">


            { ROLE_ADMIN && (
              <>
                <li className="nav-item">
                  <Link className={'nav-link ' + activo_usuarios } to="/usuarios"> Usuarios </Link>
                </li>
              </>
            )}

            { ROLE_ADMIN && (
              <li className="nav-item">
                <Link className={'nav-link ' + activo_perfil } to="/profile"> Perfil </Link>
              </li>
            )}

            { (ROLE_ADMIN || ROLE_ANALYST ) && (
              <li className="nav-item">
                <Link className={'nav-link ' + activo_medios } to="/medios"> Medios </Link>
              </li>
            )}

            { (ROLE_ADMIN) && (
              <li className="nav-item">
                <Link className={'nav-link ' + activo_reportes } to="/reportes"> Reportes </Link>
              </li>
            )}

            { 
            //   ROLE_ADMIN && (
            //   <li className="nav-item">
            //     <Link className={'nav-link ' + activo_noticias } to="/noticias"> Noticias </Link>
            //   </li>
            // )
          }
          {
            (ROLE_ANALYST||ROLE_ADMIN) && (

              <li className="nav-item">
                <Link className={'nav-link ' + activo_analistas } to="/analistas"> Analistas </Link>
              </li>

              )
          }
          
          {
              ROLE_ADMIN && (
              <li className="nav-item">
                <Link className={'nav-link ' + activo_analiticas } to="/analiticas"> Analiticas </Link>
              </li>
            )
          }        
          
          {
            //   ROLE_ADMIN && (
            //   <li className="nav-item">
            //     <Link className={'nav-link ' + activo_reporte } to="/reporte"> Reporte </Link>
            //   </li>
            // )
          }        

          {
              (ROLE_ADMIN||ROLE_ANALYST) && (
              <li className="nav-item">
                <Link className={'nav-link ' + activo_etiquetas } to="/etiquetas"> Etiquetas </Link>
              </li>
            )
          }




          

            { (ROLE_ADMIN || ROLE_CAPTURE) && (
              <li className="nav-item">
                <Link className={'nav-link ' + activo_replicas } to="/noticias/replicas"> Notas Informativas </Link>
              </li>
            )}

            { (ROLE_ADMIN||ROLE_ANALYST) && (
              <li className="nav-item">
                <Link className={'nav-link ' + activo_timeline } to="/timeline"> Timeline </Link>
              </li>
            )}

            </ul>

            <span className="navbar-text">
              Usuario: <Link className="link" to="/profile"> {user?.nombres} </Link>
            </span>

          </div>

        </div>
      </nav>

      <div className="onlymobile padding_mobil">
        <ol className="breadcrumb">
          <li onClick={()=>{ navegar('/profile') }} className={'breadcrumb-item '+activo_perfil}>Perfil</li>
          <li onClick={()=>{ navegar('/medios') }} className={'breadcrumb-item '+activo_medios}>Medios</li>
          <li onClick={()=>{ navegar('/noticias/replicas') }} className={'breadcrumb-item '+activo_replicas}>Noticias</li>
          <li onClick={()=>{ navegar('/timeline') }} className={'breadcrumb-item '+activo_timeline}>Timeline</li>
        </ol>
      </div>      


      <hr className="onlymobile" style={{'marginTop' : '0px'}} />

      
      </>


    ) }

      </>
  )




}

function App() {



  return (
    <>


    
      <BootHeader />



      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />

        <Route path="dashboard" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Dashboard} />} />


        <Route path="usuarios" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Usuarios} />} />



        <Route path="profile" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.CAPTURE, ROLE.ANALYST, ROLE.VIEW]} component={Profile} />} />



        <Route path="timeline" element={<PrivateRoute roles={[ROLE.ADMIN,ROLE.ANALYST,ROLE.VIEW,ROLE.CAPTURE]} component={Timeline} />} />

        <Route path="analiticas" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Analiticas} />} />
        <Route path="reporte" element={<PrivateRoute roles={[ROLE.ADMIN]} component={Reporte} />} />

        <Route path="etiquetas" element={<PrivateRoute roles={[ROLE.ADMIN,ROLE.ANALYST]} component={Etiquetas} />} />
        <Route path="etiquetas/editar" element={<PrivateRoute roles={[ROLE.ADMIN,ROLE.ANALYST]} component={EdicionEtiquetas} />} />
        <Route path="etiquetas/reporte" element={<PrivateRoute roles={[ROLE.ADMIN,ROLE.ANALYST]} component={EtiquetasReporte} />} />



        
        {
          // <Route path="noticias" element={<PrivateRoute roles={[ROLE.ADMIN,ROLE.ANALYST]} component={Noticias} />} />
        }


        <Route path="noticia/agregar" element={<PrivateRoute roles={[ROLE.ADMIN,ROLE.ANALYST]} component={NuevaNoticia} />} />

        <Route path="noticia/replica/nueva" element={<PrivateRoute roles={[ROLE.ADMIN,ROLE.CAPTURE,ROLE.ANALYST]} component={NuevaReplica} />} />

        <Route path="noticias/replicas" element={<PrivateRoute roles={[ROLE.ADMIN,ROLE.CAPTURE]} component={Replicas} />} />
        <Route path="noticia/mis_replicas" element={<PrivateRoute roles={[ROLE.ADMIN,ROLE.CAPTURE]} component={MisReplicas} />} />



        <Route path="analistas" element={<PrivateRoute roles={[ROLE.ADMIN, ROLE.ANALYST]} component={Analistas} />} />


        <Route path="medios" element={<PrivateRoute roles={[ROLE.ADMIN,ROLE.ANALYST]} component={Medios} />} />




        <Route path="reportes" element={<PrivateRoute roles={[ROLE.ADMIN]} component={ReporteMedios} />} />



        <Route
          path="users"
          element={<PrivateRoute roles={[ ROLE.ADMIN ]} component={Users} />}
        />
      </Routes>



    </>
  )
}

export default App
