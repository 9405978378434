import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Dialog from '../../services/Dialogs'


const ModalEditarReaccionesRedes = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, noticia } = params


    const [facebook_me_gusta, setFacebook_me_gusta ] = useState(noticia.reacciones.facebook_me_gusta);
    const [facebook_me_encanta, setFacebook_me_encanta ] = useState(noticia.reacciones.facebook_me_encanta);
    const [facebook_me_importa, setFacebook_me_importa ] = useState(noticia.reacciones.facebook_me_importa);
    const [facebook_me_divierte, setFacebook_me_divierte ] = useState(noticia.reacciones.facebook_me_divierte);
    const [facebook_me_sorprende, setFacebook_me_sorprende ] = useState(noticia.reacciones.facebook_me_sorprende);
    const [facebook_me_entristece, setFacebook_me_entristece ] = useState(noticia.reacciones.facebook_me_entristece);
    const [facebook_me_enoja, setFacebook_me_enoja ] = useState(noticia.reacciones.facebook_me_enoja);
    const [facebook_comentarios, setFacebook_comentarios ] = useState(noticia.reacciones.facebook_comentarios);
    const [facebook_compartidos, setFacebook_compartidos ] = useState(noticia.reacciones.facebook_compartidos);
    const [twitter_respuestas, setTwitter_respuestas ] = useState(noticia.reacciones.twitter_respuestas);
    const [twitter_me_gusta, setTwitter_me_gusta ] = useState(noticia.reacciones.twitter_me_gusta);
    const [twitter_retweet, setTwitter_retweet ] = useState(noticia.reacciones.twitter_retweet);
    const [youtube_comentarios, setYoutube_comentarios ] = useState(noticia.reacciones.youtube_comentarios);
    const [youtube_reproducciones, setYoutube_reproducciones ] = useState(noticia.reacciones.youtube_reproducciones);
    const [youtube_me_gusta, setYoutube_me_gusta ] = useState(noticia.reacciones.youtube_me_gusta);
    const [instagram_me_gusta, setInstagram_me_gusta] = useState(noticia.reacciones.instagram_me_gusta);
    const [instagram_comentarios, setInstagram_comentarios] = useState(noticia.reacciones.instagram_comentarios);


    const [reproducciones, setReproducciones] = useState<any>(noticia.reproducciones);


    const changeFacebook_me_gusta = (event:any) => { setFacebook_me_gusta(event.target.value) }
    const changeFacebook_me_encanta = (event:any) => { setFacebook_me_encanta(event.target.value) }
    const changeFacebook_me_importa = (event:any) => { setFacebook_me_importa(event.target.value) }
    const changeFacebook_me_divierte = (event:any) => { setFacebook_me_divierte(event.target.value) }
    const changeFacebook_me_sorprende = (event:any) => { setFacebook_me_sorprende(event.target.value) }
    const changeFacebook_me_entristece = (event:any) => { setFacebook_me_entristece(event.target.value) }
    const changeFacebook_me_enoja = (event:any) => { setFacebook_me_enoja(event.target.value) }
    const changeFacebook_comentarios = (event:any) => { setFacebook_comentarios(event.target.value) }
    const changeFacebook_compartidos = (event:any) => { setFacebook_compartidos(event.target.value) }
    const changeTwitter_respuestas = (event:any) => { setTwitter_respuestas(event.target.value) }
    const changeTwitter_me_gusta = (event:any) => { setTwitter_me_gusta(event.target.value) }
    const changeTwitter_retweet = (event:any) => { setTwitter_retweet(event.target.value) }
    const changeYoutube_comentarios = (event:any) => { setYoutube_comentarios(event.target.value) }
    const changeYoutube_reproducciones = (event:any) => { setYoutube_reproducciones(event.target.value) }
    const changeYoutube_me_gusta = (event:any) => { setYoutube_me_gusta(event.target.value) }
    const changeInstagram_me_gusta = (event:any) => { setInstagram_me_gusta(event.target.value) }
    const changeInstagram_comentarios = (event:any) => { setInstagram_comentarios(event.target.value) }

    const [url, setUrl] = useState( noticia.reacciones?.url )



  const changeUrl = (event:any) => {
    setUrl(event.target.value)
  }


const dialog = new Dialog();

const guardarCambios = async(e:any) => {
    try{

        let data = {
          url : url,
          facebook_me_gusta : facebook_me_gusta,
          facebook_me_encanta : facebook_me_encanta,
          facebook_me_importa : facebook_me_importa,
          facebook_me_divierte : facebook_me_divierte,
          facebook_me_sorprende : facebook_me_sorprende,
          facebook_me_entristece : facebook_me_entristece,
          facebook_me_enoja : facebook_me_enoja,
          facebook_comentarios : facebook_comentarios,
          facebook_compartidos : facebook_compartidos,
          twitter_respuestas : twitter_respuestas,
          twitter_me_gusta : twitter_me_gusta,
          twitter_retweet : twitter_retweet,
          youtube_comentarios : youtube_comentarios,
          youtube_reproducciones : youtube_reproducciones,
          youtube_me_gusta : youtube_me_gusta,
          instagram_me_gusta : instagram_me_gusta,
          instagram_comentarios : instagram_comentarios, 
        }

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: e.target,
          template: ``
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo ){
          let res = await RequestService.update(`noticias/editar_reacciones/${noticia.uuid_noticia}`, { data: data, reproducciones : reproducciones } )
          if(res.data){
            toast.success('Se actualizo la información de la Noticia')
            handleClose()
          }
          
        }

    }
    catch(e){
      toast.error('No pudimos actualizar las reacciones')
    }

  }


  return (
    <>
      <Modal show={show} size={'lg'} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Modificar Reacciones de Noticia</Modal.Title>
        </Modal.Header>
        <Modal.Body>



          {
            // JSON.stringify(noticia.uuid_noticia)
          }






                        { true && (
                            <>
                                  { 
                                    // red_social :{(red_social)?'SI':'NO'} <br />
                                    //idred_social : {idred_social}
                                }

                               { noticia.idred_social === 1 &&
                                 (

                                      <div className="card text-white mb-3 bg-facebook nm">
                                        <div className="card-header"> <i className="fa fa-facebook-official" /> Facebook</div>
                                        <div className="card-body">


                                              <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">👍</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_gusta} onChange={changeFacebook_me_gusta} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio2">❤️</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_encanta} onChange={changeFacebook_me_encanta} type="number" id="reaccio2" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio3">💕</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_importa} onChange={changeFacebook_me_importa} type="number" id="reaccio3" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio4">😂</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_divierte} onChange={changeFacebook_me_divierte} type="number" id="reaccio4" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio5">😮</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_sorprende} onChange={changeFacebook_me_sorprende} type="number" id="reaccio5" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio6">😢</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_entristece} onChange={changeFacebook_me_entristece} type="number" id="reaccio6" />
                                                    </div>
                                                </div>
                                              </div>


                                              <div className="row">
                                                <div className="col col-md-2">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio6">😡</label>
                                                      <input className="form-control form-control-sm" value={facebook_me_enoja} onChange={changeFacebook_me_enoja} type="number" id="reaccio6" />
                                                    </div>
                                                </div>
                                                <div className="col col-md-2">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio7">🗨️</label>
                                                      <input className="form-control form-control-sm" value={facebook_comentarios} onChange={changeFacebook_comentarios} type="number" id="reaccio7" />
                                                    </div>
                                                </div>
                                                <div className="col col-md-2">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio8">🔃</label>
                                                      <input className="form-control form-control-sm" value={facebook_compartidos} onChange={changeFacebook_compartidos} type="number" id="reaccio8" />
                                                    </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col mt-2">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>


                                              <div className="col-4 mt-2">
                                                <div className="mb-3">
                                                  <label className="form-label">Reproducciones</label>
                                                  <input type="number" className="form-control" value={reproducciones} onChange={(e:any)=>{setReproducciones(parseInt(e.target.value))}} />
                                                </div>
                                              </div>


                                        </div>
                                      </div>


                                   )
                               }


                               { noticia.idred_social === 4 &&
                                 (


                                      <div className="card text-white mb-3 bg-instagram nm">
                                        <div className="card-header"> <i className="fa fa-instragram" /> Instagram</div>
                                        <div className="card-body">


                                              <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">👍</label>
                                                      <input className="form-control form-control-sm" value={instagram_me_gusta} onChange={changeInstagram_me_gusta} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio7">🗨️</label>
                                                      <input className="form-control form-control-sm" value={instagram_comentarios} onChange={changeInstagram_comentarios} type="number" id="reaccio7" />
                                                    </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col mt-2">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-4 mt-2">
                                                <div className="mb-3">
                                                  <label className="form-label">Reproducciones</label>
                                                  <input type="number" className="form-control" value={reproducciones} onChange={(e:any)=>{setReproducciones(parseInt(e.target.value))}} />
                                                </div>
                                              </div>


                                        </div>
                                      </div>


                                   )
                               }



                               { noticia.idred_social === 5 &&
                                 (


                                      <div className="card text-white- mb-3 bg-tiktok nm">
                                        <div className="card-header"> <i className="fa fa-tiktok" /> Tiktok</div>
                                        <div className="card-body">



                                              <div className="row">
                                                <div className="col">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-4 mt-2">
                                                <div className="mb-3">
                                                  <label className="form-label">Reproducciones</label>
                                                  <input type="number" className="form-control" value={reproducciones} onChange={(e:any)=>{setReproducciones(parseInt(e.target.value))}} />
                                                </div>
                                              </div>


                                        </div>
                                      </div>


                                   )
                               }


                               { noticia.idred_social === 8 &&
                                 (


                                      <div className="card text-white- mb-3 bg-pinterest nm">
                                        <div className="card-header"> <i className="fa fa-pinterest" /> Pinterest</div>
                                        <div className="card-body">



                                              <div className="row">
                                                <div className="col">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>


                                        </div>
                                      </div>


                                   )
                               }



                               { noticia.idred_social === 9 &&
                                 (


                                      <div className="card text-white- mb-3 bg-reddit nm">
                                        <div className="card-header"> <i className="fa fa-reddit" /> Reddit</div>
                                        <div className="card-body">



                                              <div className="row">
                                                <div className="col">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>


                                        </div>
                                      </div>


                                   )
                               }


                               { noticia.idred_social === 10 &&
                                 (

                                      <div className="card text-white mb-3 bg-twitter nm">
                                        <div className="card-header"> <i className="fa fa-twitter" /> Twitter</div>
                                        <div className="card-body">

                                              <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio7">🗨️</label>
                                                      <input className="form-control form-control-sm" value={twitter_respuestas} onChange={changeTwitter_respuestas} type="number" id="reaccio7" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio8">🔃</label>
                                                      <input className="form-control form-control-sm" value={twitter_retweet} onChange={changeTwitter_retweet} type="number" id="reaccio8" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">❤️</label>
                                                      <input className="form-control form-control-sm" value={twitter_me_gusta} onChange={changeTwitter_me_gusta} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col mt-2">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-4 mt-2">
                                                <div className="mb-3">
                                                  <label className="form-label">Reproducciones</label>
                                                  <input type="number" className="form-control" value={reproducciones} onChange={(e:any)=>{setReproducciones(parseInt(e.target.value))}} />
                                                </div>
                                              </div>

                                        </div>
                                      </div>



                                   )
                               }


                               { noticia.idred_social === 2 &&
                                 (

                                      <div className="card text-white mb-3 bg-youtube nm">
                                        <div className="card-header"> <i className="fa fa-youtube" /> Youtube</div>
                                        <div className="card-body">

                                              <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio7">🗨️</label>
                                                      <input className="form-control form-control-sm" value={youtube_comentarios} onChange={changeYoutube_comentarios} type="number" id="reaccio7" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio1">👍</label>
                                                      <input className="form-control form-control-sm" value={youtube_me_gusta} onChange={changeYoutube_me_gusta} type="number" id="reaccio1" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                      <label className="col-form-label col-form-label-sm" htmlFor="reaccio2">👁️</label>
                                                      <input className="form-control form-control-sm" value={youtube_reproducciones} onChange={changeYoutube_reproducciones} type="number" id="reaccio2" />
                                                    </div>
                                                </div>
                                              </div>

                                                <div className="mt-2">
                                                  <div className="form-group">
                                                      <label className="form-label" htmlFor="urlNoticia">URL</label>
                                                      <input className="form-control" id="urlNoticia" type="text" value={url} onChange={changeUrl} />
                                                  </div>
                                                </div> 

                                              <div className="col-4 mt-2">
                                                <div className="mb-3">
                                                  <label className="form-label">Reproducciones</label>
                                                  <input type="number" className="form-control" value={reproducciones} onChange={(e:any)=>{setReproducciones(parseInt(e.target.value))}} />
                                                </div>
                                              </div>

                                        </div>
                                      </div>


                                   )
                               }








                            </>
                            )
                        }



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={guardarCambios} type="button">Guarda</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarReaccionesRedes;