import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'


import moment from 'moment'
import 'moment/locale/es'

import './Etiquetas.scss';

import { Tab, Tabs, Button } from 'react-bootstrap'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';



import { Link } from 'react-router-dom'


import { SERVER_ } from  '../../config';


import ModalMencionesEtiquetas from './ModalMencionesEtiquetas'


interface Etiqueta {
    id?: number;
    etiqueta : string;
    cantidad_menciones : number;
}

interface Seccion{
  id?: number;
  seccion : string;
  etiquetas_usadas : any;
}

const Etiquetas = () =>{



// const ctx = document.querySelector('canvas').getContext('2d');

// const chart = new Chart( {}, {
//   type: 'wordCloud',
//   data: {
//     // text
//     labels: ['Hello', 'world', 'normally', 'you', 'want', 'more', 'words', 'than', 'this'],
//     datasets: [
//       {
//         label: 'DS',
//         maxRotation: 90,
//         // size in pixel
//         data: [90, 80, 70, 60, 50, 40, 30, 20, 10],
//       },
//     ],
//   },
//   options: {
//     elements: {
//       word: {
//         maxRotation: 90,
//       },
//     },
//   },
// });

  registerLocale('es', es)


  const [ cargando_noticia, setCargandoNoticias] = useState(true)
  const [ total_etiquetas, setTotalEtiquetas ] = useState(0)

  const [startDate, setDate ] = useState(new Date())

  const skullEtiqueta : Etiqueta = {
    etiqueta : "",
    cantidad_menciones : 0
  }

  const skullSeccion : Seccion = {
    seccion : "",
    etiquetas_usadas : [{...skullEtiqueta}]
  }

  // const [ etiquetas, setEtiquetas ] = useState<[Etiqueta]>([{...skullEtiqueta}])
  const [ secciones, setSecciones ] = useState ([{...skullSeccion},{...skullSeccion}])


  // const [ big_data, setBigData ] = useState({...bd_skull})

  const cargarEtiquetas = async (fecha) =>{
    // cargarEtiquetas
    try{
        setDate(fecha)
        setCargandoNoticias(true)
        let big = await RequestService.getEndPoint('etiquetas/' + moment(fecha).format('YYYY-MM-DD') )
        setSecciones(big.data)
        setTotalEtiquetas(big.data.length)
        setCargandoNoticias(false)

    }
    catch(e){
      toast.error('Problemas cargando las etiquetas')
      toast.error(JSON.stringify(e))
      setCargandoNoticias(false)
    }
  }

  const canvasRef = React.useRef();

  React.useEffect(() => {
    cargarEtiquetas( moment().format('YYYY-MM-DD') )
  }, []);




    const [show, setShow] = useState(false);

    const [ etiqueta_seleccionada, setEtiquetaSeleccion ] = useState({})

  const handleClose = () => setShow(false);

  const handleShow = (etiqueta:any) =>{ 
    console.log(etiqueta)
    setEtiquetaSeleccion(etiqueta)
    setShow(true);

  }

      return (
          <>

              <div className="container">
                  


                  <h2>Etiquetas</h2>

                  <div className="row">
                    <div className="col-md-3">


                        <h5>Fecha de filtro</h5>


                        <div className="text-center">
                          <DatePicker
                            onChange={(date)=>{ cargarEtiquetas(date) }}
                            startDate={startDate}
                            locale="es"
                            inline
                          />
                        </div>

                        <hr />

                        <ul className="nav nav-pills flex-column">
                          <li className="nav-item">
                            <Link className="nav-link" to="/etiquetas/editar"> Editar Etiquetas </Link>
                            <Link className="nav-link" to="/etiquetas/reporte"> Reporte de Etiquetas </Link>
                            <a className="nav-link" target="_blank" href={ SERVER_ + 'reportes/etiquetas' }> Descargar Reporte </a>
                          </li>
                        </ul>



                    </div>
                    <div className="col-md">



                          {  cargando_noticia && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



                          { (!cargando_noticia && total_etiquetas>0)  && (

                            <>

                              <h5>Etiquetas del día {moment(startDate).format("dddd, MMMM Do [del año] YYYY")} </h5>
                              <hr />


                            {
                              (
                                secciones[0].etiquetas_usadas.length === 0 &&
                                secciones[1].etiquetas_usadas.length === 0 &&
                                secciones[2].etiquetas_usadas.length === 0 &&
                                secciones[3].etiquetas_usadas.length === 0 &&
                                secciones[4].etiquetas_usadas.length === 0 &&
                                secciones[5].etiquetas_usadas.length === 0 &&
                                secciones[6].etiquetas_usadas.length === 0 
                              )
                              &&
                              (
                                <p>No hay etiquetas para este día</p>
                              )
                            }


                              <Tabs defaultActiveKey="0" id="uncontrolled-tab-example" className="mb-3">
                                {
                                  secciones.map( (seccion, i) =>



                                               (seccion.etiquetas_usadas.length > 0) && (

                                                <Tab key={i} eventKey={i} title={seccion.seccion}>

                                                  <table className="table table-borderless">
                                                      <thead>
                                                          <tr className="p-0">
                                                              <th className="w350 p-0" scope="col"> {seccion.seccion} | {seccion.etiquetas_usadas.length} ETIQUETA(S)  </th>
                                                              <th className="text-center w100 p-0 py-2" scope="col"><small className="text-muted">TENDENCIA</small></th>
                                                              <th className="text-center w100 p-0 py-2" scope="col"><small className="text-muted">MENCIONES</small> </th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                      {
                                                        seccion.etiquetas_usadas.map( etiqueta =>

                                                          <tr key={ Math.random().toString(36).substr(2, 9) } className="border-bottom bg-white">
                                                              <td className="row">
                                                                  <div className="d-flex align-items-center"> <span className={'bg bg-'+seccion.id+' mx-2'}></span> <span>{etiqueta.etiqueta}</span> </div>
                                                              </td>
                                                              <td className="text-center">
                                                                  <span className={'tendencia-'+etiqueta.idtendencia}>{etiqueta.tendencia}</span>
                                                              </td>
                                                              <td className="text-center">
                                                                <Button variant="link" size="sm" onClick={()=>{handleShow(etiqueta)}}> {etiqueta.cantidad_menciones} </Button>
                                                              </td>
                                                          </tr>

                                                          )
                                                      }

                                                      </tbody>
                                                  </table>

                                                </Tab>

                                              )
                                            

                                    )
                                }
                              </Tabs>





                                </>

                                        )
                                      }

                    </div>

                  </div>

              </div>



              {
                show && (
                    <ModalMencionesEtiquetas isOpen={show} handleClose={handleClose} fecha={startDate} etiqueta_seleccionada={etiqueta_seleccionada} />
                  )
              }

              

          </>

      );
  

}

export default Etiquetas
