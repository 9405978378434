import React, { useState } from "react";
import RequestService from "../../../services/request.service";

import { useNavigate } from "react-router"


import { toast } from 'react-toastify'
import Button from 'react-bootstrap/Button'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import './nueva-noticia.scss';

import moment from 'moment'

import { SERVER_ } from  '../../../config';


import { WithContext as ReactTags } from 'react-tag-input';



const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];


function NuevaNoticia() {


  let navigate = useNavigate();



  registerLocale('es', es)

  var [fecha_noticia,setFecha] = useState( moment().format('YYYY-MM-DD') )

  const [titulo, setTitulo] = useState('')
  const [sintesis, setDetalle] = useState('')

  const [noticias, setNoticias] : any[] = useState([{ uuid: 0, idmedio : { uuid : '0b02b5c6-7069-4547-a2d2-2aa229537f90' }, selected : false }])






  const [tags, setTags] = useState([{ id: 'Thailand', text: 'Thailand' }]);

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  // const handleTagClick = index => {
  //   console.log('The tag at index ' + index + ' was clicked');
  // };

 

  const [suggestions, setSuggestions] = useState([]);


  const startDate = new Date()

  const updateChange = (date : any)=>{
    setFecha(moment(date).format('YYYY-MM-DD'))
    cargarReplicas(moment(date).format('YYYY-MM-DD'))
  }

  const handleChangeTitulo = (event:any) => { 
    setTitulo((event.target.value).toUpperCase());
  }
  const handleChangeDetalle = (event:any) => { 
    setDetalle((event.target.value).toUpperCase());
  }


  const cargarReplicas = async (fecha:string) => {

    let res = await RequestService.getEndPoint('replicas/sin-noticia/'+moment(fecha).format('YYYY-MM-DD'))

    setNoticias(res.data)

  }


  const cargarEtiquetas = async () => {

    let res = await RequestService.getEndPoint('etiquetas/all')

    setSuggestions(res.data)

  }


  const selectReplicas = async (value : boolean,index:number,event: any) => {
    let NEWS = noticias
    NEWS[index].selected = value ? true : false;
    setNoticias([...NEWS])
  }



  React.useEffect(() => {

    cargarEtiquetas()
    cargarReplicas(moment().format('YYYY-MM-DD'))




  }, []);



  const crearNuevaNoticia =  async (event:any) =>{
      try{

      event.preventDefault();

      const REPLICAS : any = []
      var i
      for ( i = 0; i < noticias.length; ++i) {
        if(noticias[i].selected){ 
          REPLICAS.push(noticias[i].uuid)
        }
      }

      const ETIQUETAS : any = []
      for ( i = 0; i < tags.length; ++i) {
        ETIQUETAS.push(tags[i].id)
      }

          var SEND = {
            noticia : {
              titulo : titulo, 
              sintesis : sintesis,
              fecha_noticia : moment(fecha_noticia).format('YYYY-MM-DD'),
            },
            replicas : REPLICAS,
            etiquetas : ETIQUETAS
          }

          console.log(SEND)

          await RequestService.create('nueva-noticia', SEND )
          toast('Su noticia fue agregada correctamente')
          navigate('/noticias')
          return;
        // }

      }
      catch(e){
        alert('Errror')
      }

  }





  return (


    <>

            <div className="container">

                <h2>Nueva Nota Informativa</h2>
                <hr />


                <div className="row">
                  <div className="col-3">


                        <label className="form-label"  >Fecha de la Noticia</label>
                        <div className="text-center">
                          <DatePicker
                            onChange={(date)=>{ updateChange(date) }}
                            startDate={startDate}
                            locale="es"
                            inline
                          />
                        </div>


                        <div className="mb-3">
                            <label className="form-label" htmlFor="titularDeLaNoticia">Titular de la noticia</label>
                            <input className="form-control" id="titularDeLaNoticia" type="text" value={titulo} onChange={handleChangeTitulo} />
                        </div>


                        <div className="mb-3">
                            <label className="form-label" htmlFor="sintesisDeLaNoticia">Síntesis de la noticia</label>
                            <textarea className="form-control" id="sintesisDeLaNoticia" style={{'height': '10rem'}} value={sintesis} onChange={handleChangeDetalle} ></textarea>
                        </div>


                        <div className="row">
                          <div className="col-md-8">
                              <Button variant="success" style={{'width':'100%'}} onClick={crearNuevaNoticia}>CREAR NOTICIA</Button>
                          </div>
                          <div className="col-md-4">
                          </div>
                        </div>



                  </div>
                <div className="col-md-2">



                    <div className="mb-3">
                        <label className="form-label" htmlFor="titularDeLaNoticia">Etiquetas relacionadas</label>

                        <ReactTags
                          tags={tags}
                          suggestions={suggestions}
                          delimiters={delimiters}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          inputFieldPosition="top"
                          autocomplete
                        />

                    </div>



                </div>
                  <div className="col">

                    <label className="form-label">Notas Informativas relacionadas con esta Noticia</label>

                    {
                      noticias.map((option:any,i : number)=>

                            <div className="d-flex" key={'flexCheckDefault'+i}>
                              <img
                                src={ SERVER_ + 'medio/logo/square/'+option.idmedio.uuid}
                                alt="John Doe"
                                className="me-3 rounded-circle"
                                style={{'width': '60px', 'height': '60px'}}
                              />
                              <div>
                                <h6 className="fw-bold nm">{option.titulo}</h6>
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" id={'flexCheckDefault'+i} checked={option.selected?true:false} onChange={ (e) =>{ selectReplicas(!option.selected,i,e) } } />
                                  <label style={{"fontSize":'12px'}} className="form-check-label" htmlFor={'flexCheckDefault'+i}> {option.selected?'si':'no'} {option.detalle} </label>
                                </div>
                                
                              </div>
                            </div>

                      )


                    }

                  </div>
                </div>


            </div>



    </>

    )

}


export default NuevaNoticia